import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CpfCnpjValidator } from '@validator/cpf-cnpj.validator';

@Component({
  selector: 'ui-input-cpf-cnpj',
  templateUrl: './input-cpf-cnpj.component.html',
  styleUrls: ['./input-cpf-cnpj.component.scss']
})
export class InputCpfCnpjFormComponent implements OnInit {
  private validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'CPF ou CNPJ';
  @Input() mask: string = '000.000.000-00||00.000.000/0000-00';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Informe o documento';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Documento inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();
  @Output() onBlur: EventEmitter<VoidFunction> = new EventEmitter();

  dataBlur: any;

  CustomMask() {
    const val = this.control.value || '';

    if (val.length > 0) {
      const doc = val.replace(/[^\d]+/g, '');
      this.mask = doc.length === 14 ? '00.000.000/0000-00' : '000.000.000-009999';
    }
  }

  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.validators.push(CpfCnpjValidator);

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
      this.onNewValue.emit(newValue);
      this.CustomMask();
    });

    this.CustomMask();
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Blur(data: any) {
    this.onBlur.emit(data);
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }

    return this.control.hasError('validateCpfCnpj') ? this.invalidMessage : '';
  }
}

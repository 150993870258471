import { Router, NavigationEnd, NavigationStart, ActivatedRoute, NavigationCancel } from '@angular/router';
import { Component, Renderer2, NgZone, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '@service/loader.service';
import { GtmService } from '@service/gtm.service';
import { LocalStorageService } from '@service/localstorage.service';
import { NavigatorService } from '@service/navigator.service';
import { AuthService } from '@service/auth.service';
import { JwtService, USER_TYPE } from '@service/jwt.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Store } from '@ngrx/store';
import { version } from '../../package.json';
import * as Bowser from 'bowser';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
// import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { EnvironmentService, PlatformInfo } from '@service/environment.service';
import { AVLocalStorageService } from '@service/av-localstorage.service';
import { audienciaConfig } from '@config/audiencia.config';
import { IpService } from '@service/ip.service';
import { Subscription } from 'rxjs';
import { GoogleService } from '@service/google.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AegeaStore } from 'store/aegea.store';
import { ENV_NAME } from 'store/environment/environment.reducer';
import { SmoochService } from '@service/smooch.service';
import { UnidadeService } from '@service/unidade.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {

  title = 'Águas App';
  private env = 'production';
  private version = version;

  isLogged = false;
  noLogged = true;
  unit_fallback_subscribed = false;
  dataBusObservable: Subscription = new Subscription();

  ip = '';

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    public loaderService: LoaderService,
    private smoochService: SmoochService,
    private renderer: Renderer2,
    private router: Router,
    private localStorageService: LocalStorageService,
    private avLocalStorageService: AVLocalStorageService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToastrService,
    private authService: AuthService,
    private jwtService: JwtService,
    private navigatorService: NavigatorService,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    private ipService: IpService,
    private dataLayer: GtmService,
    private store: Store<AegeaStore>,
    private envService: EnvironmentService,
    private googleService: GoogleService,
    private dialogRef: MatDialog,
    private unidadeService: UnidadeService,
    private cdref: ChangeDetectorRef,
  ) {
    App.addListener('backButton', () => {
      let locationPath = this.router.url.substring(this.router.url.lastIndexOf('/'));
      this.dialogRef.closeAll();

      if (locationPath.indexOf('/entrar') >= 0 || locationPath === '/home' || locationPath === '/unidade') {
        App.exitApp();
      }
      else {
        this.navigatorService.goTo('home');
      }
    });

    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const resultadoId = data.url.split('pagamento-resultado/').pop();
        if (resultadoId) {
          this.router.navigateByUrl(`/pagamento/transacao/${resultadoId}`);
        }
      });
    });

    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });

    this.store.select('environment').subscribe((d) => {
      const browser = `${'is-' + this.browser.getBrowserName()?.toLowerCase()}`;
      this.renderer.addClass(document.body, browser);

      this.renderer.removeClass(document.body, 'is-offline');
      this.renderer.removeClass(document.body, 'is-production');
      this.renderer.removeClass(document.body, 'is-homologation');
      this.renderer.removeClass(document.body, 'is-development');
      this.renderer.removeClass(document.body, 'is-localhost');

      if (!this.isOnline) {
        this.renderer.addClass(document.body, 'is-offline');
      } else if (d.env === ENV_NAME.PROD) {
        this.renderer.addClass(document.body, 'is-production');
        this.env = 'production';
      } else if (d.env === ENV_NAME.HML) {
        this.renderer.addClass(document.body, 'is-homologation');
        this.env = 'homologation';
      } else if (d.env === ENV_NAME.DEV) {
        this.renderer.addClass(document.body, 'is-development');
        this.env = 'development';
      } else if (location.hostname == 'localhost' || location.hostname === '127.0.0.1') {
        this.renderer.addClass(document.body, 'is-localhost');
        this.env = 'localhost';
      }

      this.renderer.removeClass(document.body, 'is-mobile');
      this.renderer.removeClass(document.body, 'is-ios');
      this.renderer.removeClass(document.body, 'is-android');

      if (this.platformInfo.isMobile) {
        this.renderer.addClass(document.body, 'is-mobile');

        if (this.platformInfo.isIos) {
          this.renderer.addClass(document.body, 'is-ios');
        }

        if (this.platformInfo.isAndroid) {
          this.renderer.addClass(document.body, 'is-android');
        }
      }
    });

    var count = 0;
    this.dataBusObservable.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationCancel) {
          this.loaderService.setPageLoader(false);
        }

        if (event instanceof NavigationStart) {
          this.setUnitFallback(event);

          this.loaderService.setPageLoader(true);

          let url = this.navigatorService.cleanURL(event.url);

          if (url === '' || url === '/') {
            if (this.isLogged) {
              this.navigatorService.goTo('home');
            }

            if (this.noLogged) {
              const rootRouteNoLogged = this.getRootRouter();
              this.navigatorService.goTo(rootRouteNoLogged);
            }
          }

          this.isLogged = this.jwtService.isLoggedFromToken();
          this.noLogged = !this.jwtService.isLoggedFromToken();

          if (this.isLogged) {
            this.renderer.addClass(document.body, 'is-logged');
          } else {
            this.renderer.addClass(document.body, 'no-logged');
          }
        }

        if (event instanceof NavigationEnd) {
          if (this.platformInfo.isMobile) {
            this.deeplinks
              .route({
                '/:idPedido': 'pagamento/transacao',
                '/pagamento/transacao/:idPedido': ''
              })
              .subscribe(
                (match) => {
                  // match.$route - the route we matched, which is the matched entry from the arguments to route()
                  // match.$args - the args passed in the link
                  // match.$link - the full link data
                  const urlDeep = `${match.$link.host}${match.$link.path}`;
                  this.zone.run(() => {
                    if (count === 0) {
                      this.router.navigate([urlDeep]);
                      count++;
                      setTimeout(() => {
                        count = 0;
                      }, 3000);
                    }
                  });
                },
                (nomatch) => {
                  console.error("Got a deeplink that didn't match", nomatch);
                }
              );
          }

          this.dataLayer.init();
          this.googleService.init();
          this.loaderService.setPageLoader(false);
        }
      })
    );
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  private getRootRouter(): string {
    const userType = this.jwtService.getUserType();
    switch (userType) {
      case USER_TYPE.IMOBILIARIA:
        return '/imobiliaria/entrar';
      case USER_TYPE.AGENTE:
        return '/agente/entrar';
      case USER_TYPE.AUTOATENDIMENTO:
        return '/atendimento/entrar';
      default:
        return '/home';
    }
  }

  private setUnidade(unidade) {
    this.smoochService.destroy();
    this.localStorageService.set('unit', unidade);
    this.avLocalStorageService.invalidarUnidadeDetalhes();
    this.avLocalStorageService.invalidarUnidadeServicos();
  };

  private setUnitFallback(event: NavigationStart) {
    if (this.unit_fallback_subscribed == false) {
      this.unit_fallback_subscribed = true;
      this.dataBusObservable.add(
        this.activatedRoute.queryParams.subscribe((params) => {
          const route = this.navigatorService.cleanURL(event.url);
          const unidadeAtual = this.localStorageService.get('unit');
          const novaUnidade = this.unidadeService.getUnitFromParams(params);

          if (novaUnidade === 'UNIDADE_INVALIDA') {
            this.toasterService.error('Unidade informada na URL é inválida, selecione a unidade do seu município');
            this.setUnidade('');
            return this.navigatorService.goTo('unidade');
          }

          if (novaUnidade.length > 0 && unidadeAtual !== novaUnidade) {
            if (route !== '/token' && route !== '/login' && route !== '/logout') {
              this.authService.signOut();
            }

            this.setUnidade(novaUnidade);
            return this.navigatorService.goTo(route);
          }
        })
      );
    }
  }

  ngOnInit() {
    this.ipService.getIpAddress((d) => (this.ip = d.ip));

    Device.getInfo().then((info) => {
      this.platformInfo.isAndroid = info.platform === 'android';
      this.platformInfo.isDesktop = info.platform === 'web';
      this.platformInfo.isIos = info.platform === 'ios';
      this.platformInfo.isMobile = info.platform === 'android' || info.platform === 'ios';

      // if (this.platformInfo.isMobile) {
      //   FirebaseCrashlytics.setEnabled({
      //     enabled: true
      //   });
      // }
    });

    if (this.platformInfo.isMobile) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token) => {
        console.log('Push registration success, token: ' + token.value);
        // alert('Push registration success, token: ' + token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error) => {
        console.log('Error on registration: ' + JSON.stringify(error));
        // alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        // alert('Push received: ' + JSON.stringify(notification));
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        // alert('Push action performed: ' + JSON.stringify(notification));
      });
    }

    this.dataLayer.init();
    this.googleService.init();

    const decoded = this.jwtService.decodeToken();
    const plataforma = this.platformInfo.isMobile ? 'app' : 'web';
    const unidade = this.localStorageService.get('unit');
    const ip = this.getIp;

    this.dataLayer.set('Ambiente', {
      ambiente: this.env
    });

    this.dataLayer.set('Versão', {
      versao: this.version
    });

    this.dataLayer.set('Unidade', {
      unidade
    });

    this.dataLayer.set('Plataforma', {
      plataforma
    });

    this.dataLayer.set('IP', {
      ip
    });

    if (!!decoded) {
      const login = decoded.login;
      const matricula = decoded.matricula;
      const perfil = audienciaConfig.perfil(decoded?.audience);
      const cpf_cnpj = '';

      this.dataLayer.set('Perfil', {
        perfil: `${perfil}`.toLowerCase()
      });

      this.dataLayer.set('Login', {
        login
      });

      this.dataLayer.set('Matrícula', {
        matricula
      });

      this.dataLayer.set('CPF ou CNPJ', {
        cpf_cnpj
      });
    }
  }

  ngOnDestroy() {
    this.dataBusObservable?.unsubscribe();
  }

  get getIp(): string {
    return this.ip;
  }

  get isOnline(): boolean {
    return (navigator as any).onLine;
  }

  get browser() {
    return Bowser.getParser(window.navigator.userAgent);
  }
}

import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class IpService {
  constructor() {}

  getIpAddress(successCallbackAction) {
    successCallbackAction({ ip: '999.999.999.999' });
  }
}

import { Injectable } from '@angular/core';
import { apiConfig } from '@config/api.config';
import { HttpService } from '@service/http.service';
import { HttpHeaders } from '@angular/common/http';
import { EnvironmentService, PlatformInfo } from '@service/environment.service';
import { LocalStorageService } from './localstorage.service';
import { SafeResourceUrl } from '@angular/platform-browser';

export interface AnexoDocumentosRequest {
  unidade: string;
  tipoSolicitacao: CategoriaAnexo;
}

export enum CategoriaAnexo {
  ATUALIZACAO_CADASTRAL = 'ATUALIZACAO_CADASTRAL',
  AGENTE_ASSINATURA_PARCELAMENTO = 'AGENTE_ASSINATURA_PARCELAMENTO',
  TROCA_REGISTRO = 'TROCA_REGISTRO',
  PEDIDO_CORTE = 'PEDIDO_CORTE',
  MUDANCA_LOCAL_HIDROMETRO = 'MUDANCA_LOCAL_HIDROMETRO',
  LIGACAO_ESGOTO = 'LIGACAO_ESGOTO',
  FALTA_AGUA = 'FALTA_AGUA',
  ESGOTO_ENTUPIDO = 'ESGOTO_ENTUPIDO',
  TROCA_TITULARIDADE = 'troca_titularidade',
  LIGACAO_AGUA = 'ligacao_agua',
  LIGACAO_AGUA_ASSINATURA = 'ligacao_agua_assinatura',
  ALTERACAO_CADASTRAL = 'alteracao_cadastral',
  VAZAMENTO = 'vazamento',
  SERVICO_ESGOTO = 'servico_esgoto',
  PARCELAMENTO_CONTA = 'parcelamento_conta',
  TARIFA_SOCIAL = 'tarifa_social',
  RELIGACAO = 'religacao',
  DENUNCIA = 'denuncia',
  ENCERRAMENTO_CONTRATO = 'encerramento_contrato'
}

export interface IListaAnexos {
  order: number;
  title: string;
  description: string;
  filename: string;
  required: boolean;
  filesize?: number;
  filetype?: string;
  filetypes?: string[];
  source?: SafeResourceUrl;
  conditions?: string[];
  done?: boolean;
  error?: boolean;
}

export interface IEnvioAnexos {
  order: number;
  title: string;
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private envService: EnvironmentService
  ) {
    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });
  }

  convertFileToBase64(dataFile: File): Promise<string | ArrayBuffer> {
    if (!dataFile) {
      console.error('[UploadService.convertFileToBase64] Necessário parâmetro dataFile');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        console.error('convertFileToBase64', error)
        reject(error);
      };

      reader.readAsDataURL(dataFile);
    });
  }

  convertBase64ToBlob(dataFile: string): Blob {
    if (!dataFile) {
      console.error('[UploadService.convertBase64ToBlob] Necessário parâmetro dataFile');
    }

    var byteString = atob(dataFile.split(',')[1]);
    var mimeString = dataFile.split(',')[0].split(':')[1].split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  convertBlobToBase64(dataFile: Blob): Promise<string | ArrayBuffer> {
    if (!dataFile) {
      console.error('[UploadService.convertBlobToBase64] Necessário parâmetro dataFile');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(dataFile);
    });
  }

  convertBlobToFile(blobFile: Blob, fileName: string): File {
    if (!blobFile && !fileName) {
      console.error('[UploadService.convertBlobToFile] Necessário parâmetro blobFile e fileName');
    }

    return new File([blobFile], `${fileName}.${(blobFile.type || '').replace(/.*\//, '')}`, {
      type: blobFile.type,
      lastModified: new Date().getTime()
    });
  }

  extensionToMimeType(extension: string) {
    if (/PDF/.test(extension)) return 'application/pdf';
    if (/JPG/.test(extension)) return 'image/jpg';
    if (/JPEG/.test(extension)) return 'image/jpeg';
    if (/PNG/.test(extension)) return 'image/png';
    return '';
  }

  async detectCamera(callback: Function) {
    if (this.platformInfo.isDesktop) {
      const md = navigator.mediaDevices;

      if (!md || !md.enumerateDevices) {
        return callback(false);
      }

      const devices = await md.enumerateDevices();
      return callback(devices.some((device) => 'videoinput' === device.kind));
    }

    if (this.platformInfo.isMobile) {
      return callback(true);
    }
  }

  validFileCapacitorFormat(dataFile: any, validFormats: string[]): boolean {
    if (!dataFile && !validFormats) {
      console.error('[UploadService.validFileformat] Necessário parâmetro dataFile e validFormats');
    }

    const filetype = this.platformInfo.isDesktop ? dataFile.type : dataFile.type.type || '';
    return validFormats?.length && validFormats.indexOf(filetype) >= 0;
  }


  validFileformat(dataFile: File, validFormats: string[]): boolean {
    if (!dataFile && !validFormats) {
      console.error('[UploadService.validFileformat] Necessário parâmetro dataFile e validFormats');
    }

    const filetype = this.platformInfo.isDesktop ? dataFile.type : dataFile.type || '';
    return validFormats?.length && validFormats.indexOf(filetype) >= 0;
  }

  validFilesize(dataFile: File, maxSize: number): boolean {
    if (!dataFile && !maxSize) {
      console.error('[UploadService.validFilesize] Necessário parâmetro dataFile e maxSize');
    }

    const filesize = dataFile.size;
    return filesize < maxSize;
  }

  listaDocumentos(payload: AnexoDocumentosRequest, successCallbackAction: Function, errorCallbackAction: Function) {
    if (!payload.unidade && !payload.tipoSolicitacao) {
      console.error('[UploadService.listaDocumentos] Necessário parâmetro unidade e tipo de solicitação');
    }

    return this.httpService
      .sendGetRequest(apiConfig.arquivo.uploadDefinitions(payload.tipoSolicitacao))
      .subscribe(
        (succeedResponse) => successCallbackAction(succeedResponse.body),
        (errorResponse) => errorCallbackAction(errorResponse)
      );
  }

  tipoDocumentos(successCallbackAction: Function, errorCallbackAction: Function) {
    return this.httpService.sendGetRequest(apiConfig.arquivo.definitions).subscribe(
      (succeedResponse) => successCallbackAction(succeedResponse.body),
      (errorResponse) => errorCallbackAction(errorResponse)
    );
  }

  upload(arquivos: File[], categoria: CategoriaAnexo, successCallback, failureCallback) {
    if (!arquivos && !categoria) {
      console.error('[UploadService.upload] Necessário parâmetro arquivos e categoria');
    }

    const formData: FormData = new FormData();
    arquivos.forEach((file) => formData.append(`files`, file, file.name));

    const httpHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    const unidade = this.localStorageService.get('unit');

    return this.httpService
      .sendPostRequest(apiConfig.arquivo.upload(unidade, `${categoria}`), formData)
      .toPromise()
      .then((successResponse) => successCallback(successResponse))
      .catch((failureResponse) => failureCallback(failureResponse));
  }
}

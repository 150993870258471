import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { NavigatorService } from '@service/navigator.service';
import { AVLocalStorageService } from '@service/av-localstorage.service';
import { IAEServices } from '@model/unidade.resolver';
import { LocalStorageService } from '@service/localstorage.service';
import { AuthService } from '@service/auth.service';

import { GtmService } from '@service/gtm.service';
import { UnidadeService } from '@service/unidade.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceGuard implements CanActivate {
  private SERVICE_ROUTES: IAEServices<string[]> = {
    novaLigacaoEsgoto: ['/servico/ligacao-esgoto'],
    novaLigacaoAgua: ['/servico/ligacao-agua'],
    trocaTitularidade: ['/servico/trocar-titularidade'],
    consertoCavalete: ['/servico/conserto-cavalete'],
    listaOrdemServico: ['/ordem-servico/lista', '/ordem-servico/detalhe'],
    consultaOrdemServico: ['/ordem-servico/lista', '/ordem-servico/detalhe'],
    parcelamentoCartao: ['/sem-uso'],
    cartaoEloCaixa: ['/pagamento/cartao-virtual'],
    pagamentoQRCode: ['/pagamento/qrcode'],
    pagamentoQRCodePix: ['/pagamento/pix'],
    segundaViaConta: ['/fatura/segunda-via'],
    segundaViaRapida: ['/fatura/segunda-via-rapida'],
    contasPorEmail: ['/fatura/fatura-por-email'],
    debitoAutomatico: ['/pagamento/debito-automatico'],
    pagamentoCodigoBarra: ['/pagamento/codigo-barras'],
    pagamentoCartao: ['/pagamento/cartao-credito'],
    parcelamento: ['/parcelamento'],
    pagamentoCartaoDebito: ['/pagamento/cartao-debito'],
    cronogramaLeitura: ['/fatura/cronograma-leitura'],
    historicoContaConsumo: ['/fatura/historico-fatura', '/fatura/historico-consumo'],
    historicoIntegralConsumo: ['/fatura/historico-integral'],
    cortePedido: ['/servico/pedido-corte'],
    alterarCadastro: ['/perfil/alterar-cadastro'],
    certidaoNegativa: ['/fatura/certidao-negativa'],
    mudancaLocal: ['/servico/mudar-local-hidrometro'],
    religacao: ['/servico/religacao'],
    novaReligacao: ['/servico/religacao'],
    trocaHidrometro: ['/servico/trocar-registro'],
    esgotoEntupido: ['/servico/esgoto-entupido'],
    servicoEsgoto: ['/servico/esgoto'],
    faltaAgua: ['/servico/falta-agua'],
    vazamentoAgua: ['/servico/vazamento'],
    denuncia: ['/servico/denuncia'],
    acessoAgente: ['/agente/entrar', '/agente/imovel'],
    acessoImobiliaria: ['/imobiliaria/entrar', '/imobiliaria/primeiro-acesso', '/imobiliaria/imovel'],
    qualidadeAgua: ['/fatura/qualidade-agua']
  };

  constructor(
    private router: Router,
    private dataLayer: GtmService,
    private avLocalStorageService: AVLocalStorageService,
    private navigatorService: NavigatorService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private unidadeService: UnidadeService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const route = this.navigatorService.cleanURL(state.url);
    this.changeUnitHandler(route, next.queryParams);
    return this.isServiceAllowed(route, next.queryParams);
  }

  private changeUnitHandler(route, params) {
    const unidadeAtual = this.localStorageService.get('unit');
    const novaUnidade = this.unidadeService.getUnitFromParams(params);
    const novaUnidadeSelecionada = novaUnidade.length > 0 && unidadeAtual !== novaUnidade && novaUnidade !== 'UNIDADE_INVALIDA'
    const rotaValidaUnidade = route !== '/token' && route !== '/login' && route !== '/logout';

    if ( novaUnidadeSelecionada && rotaValidaUnidade ) {
      this.localStorageService.set('unit', novaUnidade);
      this.avLocalStorageService.invalidarUnidadeDetalhes();
      this.avLocalStorageService.invalidarUnidadeServicos();
      this.authService.signOutChangeUnitOnRoute();
    }
  }

  getServiceFromRoute(route: string): string {
    const result = Object.entries(this.SERVICE_ROUTES).find((d) => d[1]?.find((x) => x == route));
    return result ? result[0] : null;
  }

  async isServiceAllowed(route: string, params) {
    const services = await this.avLocalStorageService.getUnidadeServicos();
    const serviceName = this.getServiceFromRoute(route);
    const unidadeParam = this.unidadeService.getUnitFromParams(params);

    if (services && serviceName && !services[serviceName]) {
      this.dataLayer.set('Serviço indisponível', {
        route: route
      });

      this.router.navigate(['/servico-indisponivel']);
      return false;
    }

    const lastRoute = ![
      "/home", "/logout", "/atendimento/selecionar-matricula",
      '/token','/login','/atendimento/entrar','/atendimento/cadastrar','/unidade'
    ].includes(route) && !!unidadeParam;

    this.localStorageService.setItem('lastRoute', lastRoute ? route : undefined);

    return true;
  }
}

import { Component, ViewChild, Inject } from '@angular/core';

import { ModalLayout } from '@layout/modal/modal.layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-info-acesso',
  templateUrl: './info-acesso.html',
  styleUrls: ['./info-acesso.scss']
})
export class InfoAcessoModal {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  titulo = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: { titulo }, private dialogRef: MatDialogRef<InfoAcessoModal>) {
    this.titulo = data.titulo;
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

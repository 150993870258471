import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface OptionsRadio {
  label: string;
  datalayer?: string;
  tip?: string;
  value: string | boolean;
  hint?: string;
}

@Component({
  selector: 'ui-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioFormComponent implements OnInit {
  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Opções';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Selecione uma opção';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() options: OptionsRadio[];
  @Input() initialValue: string = '';
  @Input() orientation: string = 'inline';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();
  @Output() onChange: EventEmitter<VoidFunction> = new EventEmitter();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.elem) {
      const elem = this.elem.nativeElement?.querySelector('mat-radio-button');
      if (this.datalayer && elem) {
        this.renderer.setAttribute(elem, 'data-layer', this.datalayer);
      }
    }

    if (this.autoFocus && this.elem) {
      const elem = this.elem.nativeElement?.querySelector('mat-radio-button');
      if (elem) {
        elem.focus();
      }
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.control.valueChanges.subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  trackByFunction(index: number) {
    return index;
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Change() {
    this.onChange.emit();
  }
}

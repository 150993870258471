export const unidadeConfig = {
  ariquemes: 'ariqweb',
  barcarena: 'barcaweb',
  bgarcas: 'bgarweb',
  bombinhas: 'bombiweb',
  buritis: 'buritweb',
  camboriu: 'camboweb',
  campoverde: 'campoweb',
  carlinda: 'carliweb',
  carmem: 'carmeweb',
  crato: 'cratoweb',
  jaru: 'jaruweb',
  valadares: 'gvalaweb',
  claudia: 'claudweb',
  confresa: 'confreweb',
  diamantino: 'diamanweb',
  guaranta: 'grntaweb',
  guariroba: 'guariweb',
  holambra: 'holamweb',
  jangada: 'jangaweb',
  jauru: 'jauruweb',
  manaus: 'manauweb',
  marcelandia: 'marceweb',
  matao: 'mataoweb',
  matupa: 'matupweb',
  nortelandia: 'norteweb',
  novoprogresso: 'progweb',
  paranatinga: 'parngweb',
  pedrapreta: 'pedraweb',
  peixotoazevedo: 'peixoweb',
  penha: 'penhaweb',
  pimentabueno: 'pimenweb',
  pocone: 'pocoweb',
  portoesperidiao: 'portoweb',
  primavera: 'primaweb',
  prolagos: 'lagosweb',
  rolimmoura: 'rolimweb',
  rio: 'rioweb',
  saojose: 'sjrcweb',
  sfranciscosul: 'sfsulweb',
  sinop: 'sinopweb',
  sorriso: 'sorriweb',
  teresina: 'teresweb',
  timon: 'timonweb',
  uniaosul: 'uniaoweb',
  vera: 'veraweb'
};

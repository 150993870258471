<div #elem class="mat-form-field">
  <mat-checkbox
    color="primary"
    [required]="control.errors?.required"
    [inputDisabled]="disabled"
    [checked]="checked"
    [value]="checked"
    [attr.data-layer]="datalayer"
    (change)="Change($event)"
    (keydown.enter)="BlockEnterSubmit($event)"
  >
    <mat-label>
      <span [innerHTML]="label"></span>
      <sup *ngIf="tip">{{ tip }}</sup>
    </mat-label>
  </mat-checkbox>
  <mat-error>{{ errorMessage }}</mat-error>
  <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
</div>

import { Component, ViewChild } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-auto-denuncia',
  templateUrl: './auto-denuncia.html',
  styleUrls: ['./auto-denuncia.scss']
})
export class AutodenunciaModal {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  constructor(private dialogRef: MatDialogRef<AutodenunciaModal>) {}

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

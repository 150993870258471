<mat-card *ngIf="debito" class="section">
  <div class="row row-title">
    <div class="col-sm-12">
      <h2>Débito automático</h2>
      <p>
        Deseja cadastrar a fatura em débito automático?<br />
        Assim, traga mais facilidade na hora dos seus pagamentos e evita atrasos e multas na fatura
      </p>
    </div>
  </div>

  <div class="row row-submit-button">
    <div class="col-sm-12">
      <button
        data-layer="cadastro-debito-automatico"
        class="btn btn-outline-primary"
        routerLink="/pagamento/debito-automatico"
      >
        Fazer cadastro
      </button>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="email" class="section">
  <div class="row row-title">
    <div class="col-sm-12">
      <h2>Fatura por e-mail</h2>
      <p>
        Deseja receber a fatura por email?<br />
        Assim, traga mais facilidade na hora dos seus pagamentos e evita atrasos e multas na fatura
      </p>
    </div>
  </div>

  <div class="row row-submit-button">
    <div class="col-sm-12">
      <button class="btn btn-outline-primary" routerLink="/fatura/fatura-por-email">Fazer cadastro</button>
    </div>
  </div>
</mat-card>

<mat-form-field>
  <mat-label>
    {{ label }}
    <sup *ngIf="tip">{{ tip }}</sup>
  </mat-label>
  <input
    #elem
    matInput
    type="email"
    [name]="name"
    [placeholder]="placeholder"
    [formControl]="control"
    [required]="control.errors?.required"
    [inputDisabled]="disabled"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="none"
    [attr.data-layer]="datalayer"
    (keydown.enter)="BlockEnterSubmit($event)"
  />
  <mat-error>{{ errorMessage }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'installationMask'
})
export class InstallationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return this.installationMask(value);
    }
  }

  private installationMask(installation: string): string {
    if (installation) {
      if (typeof installation === 'number') {
        installation = JSON.stringify(installation);
      }
      installation = installation.replace(/[^\d]+/g, '');
      installation = installation.replace('-', '');
      if (installation.length > 1) {
        // FIXME: Exibiçâo de mascara de matricula.
        return installation.substring(0, installation.length - 1) + '-' + installation[installation.length - 1];
      }
    }
    return installation;
  }
}

import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@service/localstorage.service';
import { UnidadeDetalhes } from '@model/unidade.resolver';
import { UnidadeService } from '@service/unidade.service';
import { UnitServicesResponse } from '@model/unidade.resolver';

export interface Expirable<T> {
  expiry: Date;
  data: T;
}
@Injectable({
  providedIn: 'root'
})
export class AVLocalStorageService {
  constructor(private localStorageService: LocalStorageService, private unidadeService: UnidadeService) {}

  private async getExpirableData<T>(dataLoader, unitServicesPromise, key): Promise<T> {
    const dataLocalStorage: string = this.localStorageService.get(key);
    const data: Expirable<T> = dataLocalStorage ? JSON.parse(dataLocalStorage) : null;
    const now: Date = new Date();

    if (data && moment(data.expiry).isAfter(now) && data.data) {
      return data.data;
    } else {
      try {
        const detalhesUnidade = await dataLoader[unitServicesPromise]();
        const expirableData: Expirable<T> = {
          expiry: moment(now).add(5, 'minutes').toDate(),
          data: detalhesUnidade
        };
        this.localStorageService.set(key, JSON.stringify(expirableData));
        return detalhesUnidade;
      } catch (error) {
        console.warn(error.message);
        return null;
      }
    }
  }

  invalidarUnidadeDetalhes(): void {
    this.localStorageService.remove('detalhesUnidade');
  }

  invalidarUnidadeServicos(): void {
    this.localStorageService.remove('servicosUnidade');
  }

  async getUnidadeDetalhes(): Promise<UnidadeDetalhes> {
    return await this.getExpirableData<UnidadeDetalhes>(this.unidadeService, 'getUnitSetupPromisse', 'detalhesUnidade');
  }

  async getUnidadeServicos(): Promise<UnitServicesResponse> {
    return await this.getExpirableData<UnitServicesResponse>(
      this.unidadeService,
      'getUnitServicesPromisse',
      'servicosUnidade'
    );
  }
}

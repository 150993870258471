import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  Renderer2
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

export interface OptionsSelect {
  label: string;
  value: string;
}

@Component({
  selector: 'ui-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectFormComponent implements OnInit {
  protected validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Lista';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Selecione um item';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Nenhum item selecionado';
  @Input() initialValue: string = '';
  @Input() options: OptionsSelect[];
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();
  @Output() onChange: EventEmitter<VoidFunction> = new EventEmitter();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.initialValue) {
      this.control.setValue(this.initialValue);
    }

    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.control.setValidators(this.validators);
    this.control.valueChanges.subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  trackByFunction(index: number) {
    return index;
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  // Not working
  Change() {
    this.onChange.emit();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }
  }
}

export const serviceConfig = {
  acessoAgente: true,
  acessoImobiliaria: true,
  agendamentoPresencial: true,
  alterarCadastro: true,
  cartaoEloCaixa: true,
  certidaoNegativa: true,
  chatZendesk: true,
  consertoCavalete: false,
  consultaOrdemServico: true,
  contasPorEmail: true,
  cortePedido: true,
  cronogramaLeitura: true,
  debitoAutomatico: true,
  decretoMunicipal143832020: true,
  denuncia: true,
  encerrarContrato: true,
  esgotoEntupido: true,
  faltaAgua: true,
  historicoContaConsumo: true,
  integrarApple: true,
  integrarFacebook: true,
  integrarGoogle: true,
  listaOrdemServico: true,
  mudancaLocal: true,
  novaLigacaoAgua: true,
  novaLigacaoEsgoto: true,
  novaReligacao: true,
  ouvidoria: true,
  pagamentoCartao: true,
  pagamentoCartaoDebito: true,
  pagamentoCodigoBarra: true,
  pagamentoQRCode: false,
  pagamentoQRCodePix: true,
  parcelamento: true,
  parcelamentoCartao: true,
  politicaPrivacidade: true,
  qualidadeAgua: true,
  religacao: true,
  segundaViaConta: true,
  segundaViaRapida: true,
  servicoEsgoto: true,
  tarifaSocial: true,
  termoDeUso: true,
  trocaHidrometro: true,
  trocaTitularidade: true,
  validaPagamentoRecaptcha: true,
  vazamentoAgua: true
};

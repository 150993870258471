import { Directive, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: 'input[noNumbers]',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '(input)': 'onInputChange($event)'
  }
})
export class NoNumbersDirective {
  constructor(private element: ElementRef) {}

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.element.nativeElement.value;
    this.element.nativeElement.value = initialValue.replace(/[0-9]/, '');

    if (initialValue !== this.element.nativeElement.value) {
      event.stopPropagation();
      this.ngModelChange.emit(this.element.nativeElement.value);
    }
  }
}

import { Injectable, OnInit } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';

export enum PLATFORMS {
  IOS = 'ios',
  ANDROID = 'android',
  ELECTRON = 'electron',
  WEB = 'web'
}

export interface PlatformInfo {
  isIos: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  osVersion: number;
}

export function initializerProviderEnvironmentService(provider: EnvironmentService) {
  return () => {
    return provider.init()
  };
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private deviceInfo: DeviceInfo;

  async init() {
    this.deviceInfo = await Device.getInfo();
  }

  async platformInfo(): Promise<PlatformInfo> {
    return {
      isAndroid: this.deviceInfo.platform === PLATFORMS.ANDROID,
      isDesktop: this.deviceInfo.platform === PLATFORMS.WEB,
      isIos: this.deviceInfo.platform === PLATFORMS.IOS,
      isMobile:this.deviceInfo.platform === PLATFORMS.ANDROID || this.deviceInfo.platform === PLATFORMS.IOS,
      osVersion: parseInt(this.deviceInfo.osVersion)
    };
  }
  
  async isMobile(): Promise<boolean> {
    return (await this.platformInfo()).isMobile;
  }
  async isIos(): Promise<boolean> {
    return (await this.platformInfo()).isIos;
  }
  async isAndroid(): Promise<boolean> {
    return (await this.platformInfo()).isAndroid;
  }
  async isDesktop(): Promise<boolean> {
    return (await this.platformInfo()).isDesktop;
  }

  async osVersion(): Promise<number> {
    return (await this.platformInfo()).osVersion;
  }

}

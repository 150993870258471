export const envConfig = {
  localhost: {
    hostname: 'localhost' || '127.0.0.1',
    api: 'http://localhost:8080/api/v1'                                   // LOCALHOST
    // api: 'https://aegea-api-manager-01-dev.azure-api.net/external/agv/v1' // DEV
    // api: 'https://aegea-api-manager-01-hml.azure-api.net/external/agv/v1' // HML
    // api: 'https://api.aegea.com.br/external/agv/v1'                       // PRD
    // api: 'https://aegea-api-manager-01.azure-api.net/external/agv/v1'    // PRD
  },

  // [RODA EM CONTAINER]
  // O ARQUIVO [entrypoint.sh] troca o texto {https://aegea-api-manager-01.azure-api.net/external/agv/v1},
  // pelo valor da variavel de ambiente ${REST_API_URL}.
  // E o texto {} pelo valor da variavel de ambiente ${ENVIRONMENT}
  // *** ALTERAR ESSE COMPORTAMENTO ATRAPALHA A FLEXIBILIDADE DO DEPLOY ***
  development: {
    hostname: '',
    api: 'https://aegea-api-manager-01.azure-api.net/external/agv/v1'
  },

  // [RODA EM CONTAINER]
  // O ARQUIVO [entrypoint.sh] troca o texto {https://aegea-api-manager-01.azure-api.net/external/agv/v1},
  // pelo valor da variavel de ambiente ${REST_API_URL}.
  // E o texto {} pelo valor da variavel de ambiente ${ENVIRONMENT}
  // *** ALTERAR ESSE COMPORTAMENTO ATRAPALHA A FLEXIBILIDADE DO DEPLOY ***
  homologation: {
    hostname: '',
    api: 'https://aegea-api-manager-01.azure-api.net/external/agv/v1'
  },

  // [RODA EM CONTAINER]
  // O ARQUIVO [entrypoint.sh] troca o texto {https://aegea-api-manager-01.azure-api.net/external/agv/v1},
  // pelo valor da variavel de ambiente ${REST_API_URL}.
  // *** ALTERAR ESSE COMPORTAMENTO ATRAPALHA A FLEXIBILIDADE DO DEPLOY ***
  production: {
    hostname: 'aeservicosonline.com.br',
    api: 'https://aegea-api-manager-01.azure-api.net/external/agv/v1'
  },

  // [PODE DAR PROBLEMA]
  // CASO A CONFIGURAÇÃO DO PROXY NÃO ESTEJA CORRETA.
  // INDEPENDENTE DA URL CONFIGURADA AQUI, O *** PROXY *** QUE DECIDE PARA QUAL
  // BACKEND A REQUISICAO DEVE SEGUIR.
  mobile: {
    hostname: null,
    api: 'https://aeservicosonline.com.br/api/hml3'
  }
};

import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { LoaderService } from './loader.service';
import { PdfService } from './pdf.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share, ShareOptions } from '@capacitor/share';
import { EnvironmentService, PlatformInfo } from './environment.service';
@Injectable({
  providedIn: 'root'
})
export class PrintService {

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    private loaderService: LoaderService,
    private envService: EnvironmentService,
    private pdfService: PdfService
  ) {}

  ngOnInit() {
    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });
  }

  async image(title: string, text: string, filename: string, dialogTitle: string) {
    const that = this;
    that.loaderService.setPageLoader(true);

    let whereToWrite = Directory.Cache;

    if ((await this.envService.isAndroid()) && (await this.envService.osVersion()) < 10) {
      whereToWrite = Directory.Documents;
    }

    html2canvas(document.querySelector('.layout-print')).then(async function (canvas) {
      const base64 = canvas.toDataURL('image/png', 100);

      if ('share' in navigator) {
        navigator
          .share({ title, text, url: base64 })
          .then(() => console.log('Successful - navigator.share'))
          .catch((error) => console.error('Error - navigator.share', error));
      }
      else if ('share' in Share) {

        const writeFileResult = await Filesystem.writeFile({
          path: `${filename}.png`,
          data: base64,
          directory: whereToWrite,
          recursive: true
        });

        Share.share({
          title,
          text,
          url: writeFileResult.uri,
          dialogTitle
        } as ShareOptions);
      }

      canvas.toBlob(function (blob) {
        var img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = function () {
          var doc = new jsPDF();
          doc.addImage(img, 'PNG', 15, 15, 180, 180);
          if (that.platformInfo.isMobile) {
            that.pdfService.openPDF(new Blob([doc.output('blob')], { type: 'application/pdf' }), `${filename}.pdf`);
          }
          else {
            doc.save(`${filename}.pdf`);
          }
        };
      }, 'image/png');

      that.loaderService.setPageLoader(false);
    });
  }

  pdf(filename: string) {
    const that = this;
    that.loaderService.setPageLoader(true);

    html2canvas(document.querySelector('.layout-print')).then(function (canvas) {

      canvas.toBlob(function (blob) {
        var img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = function () {
          var doc = new jsPDF();
          doc.addImage(img, 'PNG', 15, 15, 180, 180);
          if (that.platformInfo.isMobile) {
            that.pdfService.openPDF(new Blob([doc.output('blob')], { type: 'application/pdf' }), `${filename}.pdf`);
          }
          else {
            doc.save(`${filename}.pdf`);
          }
        };
      }, 'image/png');

      that.loaderService.setPageLoader(false);
    });
  }

  botaoDownload(): boolean {
    /*if (this.platformInfo.isMobile) {
      return false;
    }*/
    return true;
  }

  botaoCompartilhar(): boolean {
    /*if (this.platformInfo.isMobile) {
      return false;
    }*/
    return true;
  }
}

<layout-modal #specificContent>
  <div class="modal-head">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">Dúvida</h2>
        <p class="title">De acordo com {{ lei.descricao || 'a lei' }}</p>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="row row-title mb-3">
      <div *ngIf="lei.beneficios.length > 0" class="col-sm-12">
        <p>
          <strong>Benefícios:</strong>
        </p>
        <ul>
          <li *ngFor="let beneficio of lei.beneficios">{{ beneficio }}</li>
        </ul>
      </div>

      <div *ngIf="lei.criterios.length > 0" class="col-sm-12">
        <p>
          <strong>Critérios:</strong>
        </p>
        <ul>
          <li *ngFor="let criterio of lei.criterios">{{ criterio }}</li>
        </ul>
      </div>

      <div *ngIf="lei.requisitos.length > 0" class="col-sm-12">
        <p>
          <strong>Requisitos:</strong>
        </p>
        <ul>
          <li *ngFor="let requisito of lei.requisitos">{{ requisito }}</li>
        </ul>
      </div>

      <div *ngIf="lei.link" class="col-sm-12">
        <p><a [href]="lei.link" target="_blank">Obter mais detalhes</a></p>
      </div>
    </div>
  </div>
</layout-modal>

import { createAction, props } from '@ngrx/store';
import { AegeaStore } from 'store/aegea.store';

export const selecionaMatricula = createAction('[Matriculas] Set Selecionada', props<{ matricula_selecionada: string, unidade_referencia: string | undefined }>());
export const logoutAction = createAction('[Matriculas] Logout');

export const hydrate = createAction("[Matriculas] Hydrate");
export const hydrateSuccess = createAction("[Matriculas] Success", props<{state: AegeaStore}>() );
export const hydrateFailure = createAction("[Matriculas] Failure");



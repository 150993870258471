import { AbstractControl } from '@angular/forms';

export function PhoneValidator(control: AbstractControl) {
  const value: string = control.value?.replace(/[^\d]+/g, '');
  const ddd: string[] = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '21',
    '22',
    '24',
    '27',
    '28',
    '31',
    '32',
    '33',
    '34',
    '35',
    '37',
    '38',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '51',
    '53',
    '54',
    '55',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '71',
    '73',
    '74',
    '75',
    '77',
    '79',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99'
  ];

  if (value && value.length > 0 && value.length < 10) {
    return { validatePhone: true };
  }

  if (value && ddd.indexOf(value.substr(0, 2)) < 0) {
    return { validatePhone: true };
  }

  if (value && value.length === 11 && value.substr(2, 1) !== '9') {
    return { validatePhone: true };
  }

  return null;
}

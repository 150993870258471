<div class="layout-print">
  <header>
    <img class="print-unidade" [src]="'/assets/unidades/colorido/' + unidade + '.svg'" />
    <img class="print-logo" src="/assets/unidades/cinza/aegea.svg" />
  </header>

  <main>
    <ng-content></ng-content>
  </main>

  <footer>
    <p>{{ dataImpressao }}</p>
  </footer>
</div>

import { serviceConfig } from '@config/service.config';

export interface UnitMessagesRequest {
  unit: string;
}

export class UnitMessagesRequestMaker {
  static create(result: UnitMessagesViewModel): UnitMessagesRequest {
    return {
      unit: result.unit
    };
  }
}

export interface UnitServicesRequest {
  unit?: string;
}

export class UnitServicesRequestMaker {
  static create(result: UnitServicesViewModel): UnitServicesRequest {
    return {
      unit: result.unit
    };
  }
}

export interface UnitSetupRequest {
  unit?: string;
}

export class UnitSetupRequestMaker {
  static create(result: UnitSetupViewModel): UnitSetupRequest {
    return {
      unit: result.unit
    };
  }
}

export interface UnitMessagesResponse {
  service?: string;
  message?: string;
}

export class UnitMessagesResponseMaker {
  static create(result: UnitMessagesResponse) {
    return {
      service: result.service,
      message: result.message
    };
  }
}

export interface UnitResponse {
  id?: string;
  nome?: string;
}

export interface UnitServicesWaitingImplement<T> {
  encerrarContrato?: T;
  alterarCadastro?: T;
  integrarApple?: T;
  integrarGoogle?: T;
  integrarFacebook?: T;
  acessoAgente?: T;
  acessoImobiliaria?: T;
  novaLigacaoEsgoto?: T;
  novaLigacaoAgua?: T;
  trocaTitularidade?: T;
  consertoCavalete?: T;
  tarifaSocial?: T;
  listaOrdemServico?: T;
  consultaOrdemServico?: T;
  parcelamentoCartao?: T;
  cartaoEloCaixa?: T;
  pagamentoQRCode?: T;
  pagamentoQRCodePix?: T;
  termoDeUso?: T;
  politicaPrivacidade?: T;
  decretoMunicipal143832020?: T;
  chatZendesk?: T;
  agendamentoPresencial?: T;
  ouvidoria?: T;
  historicoIntegralConsumo?: T;
  validaPagamentoRecaptcha?: T;
}

export interface IAEServices<T> extends UnitServicesWaitingImplement<T> {
  segundaViaConta: T;
  segundaViaRapida: T;
  contasPorEmail: T;
  debitoAutomatico: T;
  pagamentoCodigoBarra: T;
  pagamentoCartao: T;
  parcelamento: T;
  pagamentoCartaoDebito: T;
  cronogramaLeitura: T;
  historicoContaConsumo: T;
  cortePedido: T;
  certidaoNegativa: T;
  mudancaLocal: T;
  religacao: T;
  novaReligacao: T;
  trocaHidrometro: T;
  esgotoEntupido: T;
  servicoEsgoto: T;
  faltaAgua: T;
  vazamentoAgua: T;
  denuncia: T;
  qualidadeAgua: T;
}

export interface UnitServicesResponse extends IAEServices<boolean> {
  id: number;
  responsavel: string;
  data: any;
}

export class UnitServicesResponseMaker {
  static create(result: UnitServicesResponse): UnitServicesResponse {
    const bool = (item: string) => {
      return result[item] === null || result[item] === undefined ? serviceConfig[item] : result[item];
    };

    return {
      id: result.id,
      responsavel: result.responsavel,
      data: result.data,
      segundaViaConta: bool('segundaViaConta'),
      segundaViaRapida: bool('segundaViaRapida'),
      contasPorEmail: bool('contasPorEmail'),
      debitoAutomatico: bool('debitoAutomatico'),
      pagamentoCodigoBarra: bool('pagamentoCodigoBarra'),
      pagamentoCartao: bool('pagamentoCartao'),
      parcelamento: bool('parcelamento'),
      pagamentoCartaoDebito: bool('pagamentoCartaoDebito'),
      cronogramaLeitura: bool('cronogramaLeitura'),
      historicoContaConsumo: bool('historicoContaConsumo'),
      cortePedido: bool('cortePedido'),
      certidaoNegativa: bool('certidaoNegativa'),
      mudancaLocal: bool('mudancaLocal'),
      religacao: bool('religacao'),
      novaReligacao: bool('novaReligacao'),
      trocaHidrometro: bool('trocaHidrometro'),
      esgotoEntupido: bool('esgotoEntupido'),
      servicoEsgoto: bool('servicoEsgoto'),
      faltaAgua: bool('faltaAgua'),
      vazamentoAgua: bool('vazamentoAgua'),
      denuncia: bool('denuncia'),
      parcelamentoCartao: bool('parcelamentoCartao'),
      cartaoEloCaixa: bool('cartaoEloCaixa'),
      pagamentoQRCode: bool('pagamentoQRCode'),
      pagamentoQRCodePix: bool('pagamentoQRCodePix'),
      termoDeUso: bool('termoDeUso'),
      politicaPrivacidade: bool('politicaPrivacidade'),
      decretoMunicipal143832020: bool('decretoMunicipal143832020'),
      encerrarContrato: bool('encerrarContrato'),
      alterarCadastro: bool('alterarCadastro'),
      integrarApple: bool('integrarApple'),
      integrarGoogle: bool('integrarGoogle'),
      integrarFacebook: bool('integrarFacebook'),
      listaOrdemServico: bool('listaOrdemServico'),
      consultaOrdemServico: bool('consultaOrdemServico'),
      novaLigacaoEsgoto: bool('novaLigacaoEsgoto'),
      novaLigacaoAgua: bool('novaLigacaoAgua'),
      trocaTitularidade: bool('trocaTitularidade'),
      consertoCavalete: bool('consertoCavalete'),
      acessoAgente: bool('acessoAgente'),
      acessoImobiliaria: bool('acessoImobiliaria'),
      qualidadeAgua: bool('qualidadeAgua'),
      tarifaSocial: bool('tarifaSocial'),
      chatZendesk: bool('chatZendesk'),
      agendamentoPresencial: bool('agendamentoPresencial'),
      ouvidoria: bool('ouvidoria'),
      historicoIntegralConsumo: bool('historicoIntegralConsumo'),
      validaPagamentoRecaptcha: bool('validaPagamentoRecaptcha'),
    };
  }
}
export interface UnitSetupResponse {
  agencia_MODO: string;
  banner_URL: string;
  contrato_IMOB: string;
  data: string;
  financeiro_CODE: string;
  footer_LINK_CALL_CENTER: string;
  footer_LINK_CLOUD: string;
  footer_LINK_FACEBOOK: string;
  footer_LINK_INSTITUCIONAL: string;
  footer_LINK_LINKED_IN: string;
  footer_LINK_OUVIDORIA: string;
  footer_LINK_SUSTENTABILIDADE: string;
  footer_LINK_WHATSAPP: string;
  footer_LINK_YOU_TUBE: string;
  gss_PATH: string;
  id: string;
  link_CHAT_ONLINE: string;
  link_LOJAS: string;
  logo: string;
  nome: string;
  pagamento_API_TOKEN: string;
  pagamento_MERCHANT_ID: string;
  responsavel: string;
  servicos_CONF_ID: string;
  texto_DEB_AUTOMATICO: string;
  email_CADASTRAR_IMOBILIARIA: string;
  id_CHAT_ZENDESK: string;
}

export interface UnidadeDetalhes {
  urlContratoImobiliaria: string;
  linkCallCenter: string;
  linkCloud: string;
  linkFacebook: string;
  linkInstitucional: string;
  linkLinkedin: string;
  linkOuvidoria: string;
  linkSustentabilidade: string;
  linkWhatsapp: string;
  linkYoutube: string;
  gssPath: string;
  linkChatOnline: string;
  linkLojas: string;
  urlBanner: string;
  urlLogo: string;
  unidade: string;
  txtDebito: string;
  emailImobiliaria: string;
  zendeskIntegrationID: string;
}

export class UnitSetupResponseMaker {
  static create(result: UnitSetupResponse): UnidadeDetalhes {
    return {
      urlContratoImobiliaria: result.contrato_IMOB || '',
      linkCallCenter: result.footer_LINK_CALL_CENTER || '',
      linkCloud: result.footer_LINK_CLOUD || '',
      linkFacebook: result.footer_LINK_FACEBOOK || '',
      linkInstitucional: result.footer_LINK_INSTITUCIONAL || '',
      linkLinkedin: result.footer_LINK_LINKED_IN || '',
      linkOuvidoria: result.footer_LINK_OUVIDORIA || '',
      linkSustentabilidade: result.footer_LINK_SUSTENTABILIDADE || '',
      linkWhatsapp: result.footer_LINK_WHATSAPP || '',
      linkYoutube: result.footer_LINK_YOU_TUBE || '',
      gssPath: result.gss_PATH || '',
      linkChatOnline: result.link_CHAT_ONLINE || '',
      linkLojas: result.link_LOJAS || '',
      urlBanner: result.banner_URL || '',
      urlLogo: result.logo || '',
      unidade: result.nome || '',
      txtDebito: result.texto_DEB_AUTOMATICO || '',
      emailImobiliaria: result.email_CADASTRAR_IMOBILIARIA || '',
      zendeskIntegrationID: result.id_CHAT_ZENDESK || ''
    };
  }
}

export interface UnitMessagesViewModel {
  unit?: string;
}

export class UnitMessagesViewModelMaker {
  static create(result: UnitMessagesResponse) {
    return {
      service: result.service,
      message: result.message
    };
  }
}

export interface UnitServicesViewModel {
  unit?: string;
}

export class UnitServicesViewModelMaker {
  static create(result: UnitServicesResponse) {
    return {
      id: result.id,
      segundaViaConta: result.segundaViaConta,
      segundaViaRapida: result.segundaViaRapida,
      contasPorEmail: result.contasPorEmail,
      debitoAutomatico: result.debitoAutomatico,
      pagamentoCodigoBarra: result.pagamentoCodigoBarra,
      pagamentoCartao: result.pagamentoCartao,
      cronogramaLeitura: result.cronogramaLeitura,
      historicoContaConsumo: result.historicoContaConsumo,
      certidaoNegativa: result.certidaoNegativa,
      mudancaLocal: result.mudancaLocal,
      religacao: result.religacao,
      novaReligacao: result.novaReligacao,
      trocaHidrometro: result.trocaHidrometro,
      esgotoEntupido: result.esgotoEntupido,
      servicoEsgoto: result.servicoEsgoto,
      faltaAgua: result.faltaAgua,
      vazamentoAgua: result.vazamentoAgua,
      denuncia: result.denuncia,
      agendamentoPresencial: result.agendamentoPresencial,
      ouvidoria: result.ouvidoria,
      responsavel: result.responsavel,
      data: result.data
    };
  }
}

export interface UnitSetupViewModel {
  unit?: string;
}

export class UnitSetupViewModelMaker {
  static create(result: UnitSetupResponse) {
    return {
      urlContratoImobiliaria: result.contrato_IMOB || '',
      linkCallCenter: result.footer_LINK_CALL_CENTER || '',
      linkCloud: result.footer_LINK_CLOUD || '',
      linkFacebook: result.footer_LINK_FACEBOOK || '',
      linkInstitucional: result.footer_LINK_INSTITUCIONAL || '',
      linkLinkedin: result.footer_LINK_LINKED_IN || '',
      linkOuvidoria: result.footer_LINK_OUVIDORIA || '',
      linkSustentabilidade: result.footer_LINK_SUSTENTABILIDADE || '',
      linkWhatsapp: result.footer_LINK_WHATSAPP || '',
      linkYoutube: result.footer_LINK_YOU_TUBE || '',
      gssPath: result.gss_PATH || '',
      linkChatOnline: result.link_CHAT_ONLINE || '',
      linkLojas: result.link_CHAT_ONLINE || '',
      urlBanner: result.banner_URL || '',
      urlLogo: result.logo || '',
      unidade: result.nome || '',
      txtDebito: result.texto_DEB_AUTOMATICO || ''
    };
  }
}

export interface UnitViewModel {
  id?: string;
  name?: string;
}

export class UnitViewModelMaker {
  static create(unit: UnitResponse) {
    return {
      id: unit.id,
      name: unit.nome
    };
  }
}

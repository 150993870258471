import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'layout-modal',
  templateUrl: './modal.layout.html',
  styleUrls: ['./modal.layout.scss']
})
export class ModalLayout implements OnInit {
  @ViewChild('content', { static: true }) content: any;

  constructor(private dialogRef: MatDialogRef<ModalLayout>) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

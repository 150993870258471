import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device } from '@capacitor/device';

import {
  DataBusTypeSafeService,
  IDataBusFeedbackPage,
  ICONS_FEEDBACK,
  COLORS_FEEDBACK
} from '@service/databus.service';
import { PLATFORMS } from '@service/environment.service';
import { GtmService } from '@service/gtm.service';
import { JwtService } from '@service/jwt.service';
import { LoaderService } from '@service/loader.service';
import { LocalStorageService } from '@service/localstorage.service';
import { NavigatorService } from '@service/navigator.service';
import { PrintService } from '@service/print.service';
import { Subscription } from 'rxjs';

enum EFeedbackCustom {
  EMAIL_2VIA_SEM_DEBITOS = 'email-2via-sem-debitos'
}

@Component({
  selector: 'feedback',
  templateUrl: './feedback.html',
  styleUrls: ['./feedback.scss']
})
export class FeedbackComponent implements OnInit {
  private subscribers: Subscription[] = [];

  isLogged = false;

  dados = ''

  botaoDownload = true;
  botaoCompartilhar = true;

  feedback: IDataBusFeedbackPage = {
    title: 'Redirecionando',
    message: 'Aguarde em instantes será direcionado a página inicial.',
    icon: ICONS_FEEDBACK.TORNEIRA_INFO,
    color: COLORS_FEEDBACK.INFO
  };

  pageError: number;
  redirect = true;
  unit = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataBus: DataBusTypeSafeService<IDataBusFeedbackPage>,
    private dataLayer: GtmService,
    private navigatorService: NavigatorService,
    private localStorageService: LocalStorageService,
    private loaderService: LoaderService,
    private printService: PrintService,
    private jwtService: JwtService,
  ) {}

  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    Device.getInfo().then((d) => {
      this.botaoDownload = this.botaoCompartilhar = d.platform == PLATFORMS.WEB;
    })

    this.loaderService.setServiceLoader(false);
    this.unit = this.localStorageService.get('unit');
    this.isLogged = this.jwtService.isLoggedFromToken();

    this.activatedRoute.data.subscribe((data) => {
      if (data?.error) {
        this.redirect = false;
        this.byPageError(data.error);
      }
    });

    this.subscribers.push(
      this.dataBus.eventToSubscribe.subscribe((data) => {
        if (data?.title) {
          this.redirect = false;
          this.byDataBus(data);
        }
      })
    );

    this.activatedRoute.queryParams.subscribe((param) => {
      if (param?.message) {
        this.redirect = false;
        this.byQueryParam(param);
      }
    });

    setTimeout(() => {
      if (this.redirect) {
        this.dataLayer.set('Página de redirecionamento', {
          feedback: 'Redirecionando'
        });

        if (this.isLogged) {
          this.navigatorService.goTo('home');
        } else {
          this.navigatorService.goTo('atendimento/entrar');
        }
      }
    }, 5000);
  }

  private byPageError(error: number) {
    if (error === 404) {
      this.dataLayer.set('Página não encontrada', {
        feedback: 'Erro 404'
      });

      this.feedback = {
        title: 'Página não encontrada',
        message: 'Tentando acessar uma página que não existe.',
        icon: ICONS_FEEDBACK.TORNEIRA_DANGER,
        color: COLORS_FEEDBACK.DANGER,
        includeAtendimento: false,
        includeServicos: false,
        includeReclamacoes: false
      };
    }
  }

  private byDataBus(data: IDataBusFeedbackPage) {
    this.dataLayer.set('Página de resultado', {
      feedback: data.title
    });

    this.feedback = data;
  }

  private byQueryParam(param) {
    if (param.message === EFeedbackCustom.EMAIL_2VIA_SEM_DEBITOS) {
      this.dataLayer.set('Segunda via enviada por e-mail sem débitos', {
        feedback: param.message
      });

      this.feedback = {
        title: 'Fatura sem débitos',
        message: 'Fatura não se encontra em débito para emissão',
        icon: ICONS_FEEDBACK.AGUA_INFO,
        color: COLORS_FEEDBACK.INFO,
        includeAtendimento: false,
        includeServicos: false,
        includeReclamacoes: false
      };
    }
  }

  share(title: string, text: string, filename: string, dialogTitle: string) {
    this.printService.image(title, text, filename, dialogTitle);
  }

  download() {
    this.printService.pdf('feedback');
  }
}

import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[inputDisabled]'
})
export class InputDisabledDirective {
  @Input() set inputDisabled(condition: boolean) {
    if (condition) {
      this.ngControl.control?.disable();
    } else {
      this.ngControl.control?.enable();
    }
  }

  constructor(private ngControl: NgControl) {}
}

import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app.routing.module';
import { ToastrModule, OverlayContainer } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { File } from '@ionic-native/file/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { StoreModule } from '@ngrx/store';
import { baseReducer } from '../store/environment/environment.reducer';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlPTBR } from '@core/MatPaginatorIntlPTBR';
import { initializerProvider, LocalStorageService } from '@service/localstorage.service';
import { AegeaStore, metaReducers } from 'store/aegea.store';
import { matriculasReducer } from 'store/matriculas/matriculas.reducer';
import { MatriculasEffects } from 'store/matriculas/matriculas.effect';
import { initializerProviderSmoochService, SmoochService } from '@service/smooch.service';
import { EnvironmentService, initializerProviderEnvironmentService } from '@service/environment.service';
import { RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ progressBar: true }),
    EffectsModule.forRoot([MatriculasEffects]),
    StoreModule.forRoot<AegeaStore>({ environment: baseReducer, matriculas: matriculasReducer }, { metaReducers: metaReducers }),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    FormsModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializerProvider, deps: [LocalStorageService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializerProviderEnvironmentService, deps: [EnvironmentService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializerProviderSmoochService, deps: [SmoochService], multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: RECAPTCHA_SETTINGS, useValue: environment.recaptchaSettings },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPTBR },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    File,
    Device,
    FileOpener,
    Camera,
    Deeplinks,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

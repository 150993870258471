<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">{{ documento.title }}</h2>
        <p>{{ documento.description }}</p>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div *ngIf="platformInfo.isMobile" class="row">
      <div class="col-sm-12">
        <img [src]="photo" />
      </div>
    </div>

    <div *ngIf="platformInfo.isDesktop" class="row row-alert">
      <div class="col-sm-12">
        <div class="camera" [class.fotoTirada]="fotoTirada">
          <video #video width="640" height="480" autoplay>
            <div class="alert alert-danger">
              <h4 class="alert-heading">Importante!</h4>
              <p>Câmera não detectada, autorize o uso da câmera.</p>
            </div>
          </video>

          <canvas #canvas width="640" height="480"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row row-button">
      <div class="col-sm-12">
        <button
          id="camera-foto-cancelar"
          matTooltip="Cancelar envio"
          matTooltipPosition="above"
          data-layer="camera-foto-cancelada"
          *ngIf="fotoTirada"
          class="btn btn-danger"
          (click)="resetFoto()"
        >
          <ui-icon icon="close"></ui-icon>
        </button>

        <button
          id="camera-foto-enviar"
          data-layer="camera-foto-enviada"
          *ngIf="fotoTirada"
          matTooltip="Enviar"
          matTooltipPosition="above"
          class="btn btn-success"
          (click)="enviarFoto()"
          data-layer="tirada-foto"
        >
          <ui-icon icon="done"></ui-icon>
        </button>

        <button
          id="camera-foto-capturar"
          data-layer="camera-foto-tirada"
          *ngIf="!fotoTirada"
          class="btn btn-info"
          (click)="tirarFoto()"
         >
          <ui-icon icon="camera_alt"></ui-icon>
        </button>
      </div>
    </div>
  </div>
</layout-modal>

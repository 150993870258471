import { Component, ViewChild } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-aceitar-privacidade',
  templateUrl: './aceitar-privacidade.html',
  styleUrls: ['./aceitar-privacidade.scss']
})
export class AceitarPrivacidadeModal {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  constructor(private dialogRef: MatDialogRef<AceitarPrivacidadeModal>) {}

  confirmar() {
    this.dialogRef.close(true);
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

import { CategoriaAnexo } from '@service/upload.service';

export const apiConfig = {
  login: '/login',
  leitura: '/leitura',
  changelog: '/changelog',
  arquivo: {
    definitions: '/arquivo/definitions',
    upload: (unidade: string, categoria: string) => `/arquivo/${unidade}/${categoria}/upload`,
    uploadDefinitions: (categoriaAnexo: CategoriaAnexo) => `/arquivo/upload-definitions/${categoriaAnexo}`.toLowerCase(),
  },

  home: {
    geraGuia: '/home/gera-guia-pagamento',
    imobiliaria: '/home/pre-index-imobiliaria',
    matriculas: '/home/load_matriculas',
    matricula: (instalacao: string) => `/home/matricula/${instalacao}`
  },

  usuario: {
    url: '/user',
    cadastrar: '/user',
    recuperarSenha: '/user/recuperar-senha',
    alterarSenha: '/user/alterar-senha',
    alterarCadastro: '/user/alterar-cadastro',
    criarNovaSenha: '/user/recuperar-senha/criar-nova-senha',
    verificaDisponibilidadeIdentificador: '/user/valida-disponibilidade',
    verificaSituacaoCadastral: '/user/valida-situacao-cadastral',
    reenviarConfirmacaoEmail: '/user/reenvia-ativacao-token',

    vincularAcesso: (social: string) => `/user/${social}`,
    removerCadastro:  `/user`,

    matriculas: '/user/matriculas',
    acessarMatricula: (matricula: string, unidade: string) => `/user/matriculas/${matricula}/unidade/${unidade}/entrar`,
    incluirMatricula: (matricula: string) => `/user/matriculas/${matricula}/vincular`,

    atual: (instalacao: string) => `/user/${instalacao}`,
    reenviaSenha: (unidade: string, login: string) => `/user/reenvia-senha/${unidade}/${login}`,
    validarDocumento: (unidade: string, documento: string) => `/user/validar-documento/${unidade}/${documento}/`,
    validarToken: (token: string) => `/user/verificar-token/${token}`,
    reenviarSenha: (unidade: string, email: string) => `/user/reenvia-senha/${unidade}/${email}/`
  },

  validacao: {
    pergunta: '/validacao/pergunta',
    obterMatricula: '/validacao/obter-matricula-com-pergunta-resposta',
    resposta: '/validacao/resposta'
  },

  unidades: {
    url: '/unidades',
    setup: (unidade: string) => `/unidades/${unidade}/setup`,
    servicos: (unidade: string) => `/unidades/${unidade}/servicos`,
    gss: (nome: string) => `/unidades/gss/${nome}`,

    mensagem: {
      url: (unidade: string) => `/unidades/${unidade}/messages`,
      servico: (unidade: string, servico: string) => `/unidades/${unidade}/messages/${servico}`
    }
  },

  conta: {
    url: '/conta',
    urlMensais: '/conta/mensais',
    entenda: '/conta/entenda',
    listaDebitos: '/conta/lista-debitos',
    demonstrativoDebito: '/conta/demonstrativo-debito',
    debitoAutomatico: '/conta/debito-automatico',
    pix: '/conta/pix',
    pixPorCodigoDeBarras: '/conta/pix_codigo_barras',
    geraGuiaPagamento: '/conta/gera-guia-pagamento',
    consultaDebitos: (unidade: string, documento: string) => `/conta/consulta-debito/${unidade}/${documento}`,
    qualidadeAgua: '/conta/qualidade-agua',
    bancosConveniados: (unidade: string) => `/conta/bancos-conveniados/${unidade}`,

    segundaVia: {
      url: '/conta/segunda-via',
      guiaPagamento: '/conta/segunda-via-guia-pagamento',
    },

    segundaViaRapida: {
      consulta: (unidade: string, matricula: string, documento: string) => `/conta/segunda-via-rapida/consulta/${unidade}/${matricula}/${documento}`,
      downloadFatura: '/conta/segunda-via-rapida/download',
      downloadGuia: '/conta/segunda-via-rapida/guia-pagamento'
    },

    certidaoNegativa: {
      url: (matricula: string) => `/conta/certidao-negativa/${matricula}`,
      pdf: '/conta/certidao-negativa'
    },

    historicoConsumoIntegral: {
      download: (matricula: string) => `/conta/historico-consumo-integral/${matricula}`,
    },
  },

  pagamento: {
    url: '/pagamento',
    detalhes: '/pagamento/detalhes',
    depositoIdentificado: '/pagamento/deposito-identificado',
    bancosDebitoAutomatico: (unidade: string) => `/debito-automatico/bancos-conveniados/${unidade}`,
    debitoAutomatico: '/solicitacao/debito-automatico',
    comprovanteEmail: '/pagamento/comprovante-email',
    comprovantePdf: '/pagamento/comprovante-pdf',
    protocoloEmail: '/pagamento/protocolo-email',
    protocoloPdf: '/pagamento/protocolo-pdf',
    status: (idPedido: string) => `/pagamento/status/${idPedido}`,
    bandeiraCartao: (numeroCartao: string) => `/pagamento/bandeira-cartao/${numeroCartao}`,

    cartaoCredito: {
      qtdeParcelas: '/pagamento/cartao-credito/qtde-parcelas'
    }
  },

  parcelamento: {
    limites: '/parcelamento/limites',
    simular: '/parcelamento/simular',
    termo: '/parcelamento/termo',
    enviarTermo: '/parcelamento/termo-email',
    efetivar: '/parcelamento/efetivar_parcelamento',
    parcelaveis: (matricula: string) => `/parcelamento/${matricula}`,

    agente: {
      limites: '/parcelamento/agente/limites',
      simular: '/parcelamento/agente/simular',
      efetivar: '/parcelamento/agente/efetivar_parcelamento'
    }
  },

  solicitacao: {
    consultaOrdem: (unidade: string, documento: string, numPedido: string, ano: string) => `/solicitacao/consulta/${unidade}/${documento}/${numPedido}/${ano}`,
    listaOrdem: (matricula: string) => `/solicitacao/${matricula}`,
    religacao: '/solicitacao/religacao',
    religacaoUra: '/solicitacao/religacao-ura',
    tarifaSocial: '/solicitacao/tarifa-social',
    ligacaoEsgoto: '/solicitacao/ligacao-esgoto',
    ligacaoAgua: '/solicitacao/ligacao-agua',
    ligacaoAguaAnonimo: '/solicitacao/ligacao-agua-anonimo',
    religacaoOrcamento: '/solicitacao/religacao-orcamento',
    faltaAgua: '/solicitacao/falta-agua',
    trocaRegistro: '/solicitacao/troca-registro',
    denuncia: '/solicitacao/denuncia',
    pedidoCorte: '/solicitacao/corte-pedido',
    denunciaAnonima: '/solicitacao/denuncia-anonima',
    esgotoEntupido: '/solicitacao/esgoto-entupido',
    esgotoEntupidoAnonimo: '/solicitacao/esgoto-entupido-anonimo',
    servicoEsgoto: '/solicitacao/esgoto',
    servicoEsgotoAnonimo: '/solicitacao/esgoto-anonimo',
    mudancaLocal: '/solicitacao/mudanca-local',
    encerrarContrato: '/solicitacao/encerramento-contrato',
    informarVazamento: '/solicitacao/informar-vazamento',
    informarVazamentoAnonimo: '/solicitacao/informar-vazamento-anonimo',
    alterarCadastro: '/solicitacao/alteracao-cadastral',
    trocarTitularidade: '/solicitacao/troca-titularidade',
    trocarTitularidadeAnonimo: '/solicitacao/troca-titularidade-anonimo',

    decreto: (unidade: string) => `/solicitacao/tarifa-social/definitions/${unidade}`,
  },

  atualizacaoCadastral: {
    cadastrarSolicitacao: '/atualizacao-cadastral/solicitacao',
    consultarEnderecoPorCEP: (cep: string) => `/atualizacao-cadastral/consulta/cep/${cep}`,
    anexarArquivosSolicitacao: (idSolicitacao: string) => `/atualizacao-cadastral/arquivo/${idSolicitacao}`,
  },

  imovel: {
    url: '/imovel',
    contrato: '/imovel/contrato',
    atualizarEmail: '/imovel/atualizar-email-recebimento-conta',
    instalacao: (instalacao: string) => `/imovel/instalacao/${instalacao}`,
    endereco: (unidade: string, logradouro: string) => `/imovel/endereco/${unidade}/${logradouro}`,
    pesquisar: (unidade: string, logradouro: string) => `/imovel/pesquisa/endereco/${unidade}/${logradouro}`
  },

  agente: {
    foto: '/agente/foto',
    pesquisarImovel: '/agente/pesquisar-imovel',
    trocarMatricula: (matricula: string) => `/agente/trocar-matricula/${matricula}`,
    reenviaSenha: (unidade: string, login: string) => `/agente/reenviar-senha/${unidade}/${login}`
  },

  externa: {
    cep: (cep: string) => `https://viacep.com.br/ws/${cep}/json/`,
    endereco: (estado: string, cidade: string, logradouro: string) => `https://viacep.com.br/ws/${estado}/${cidade}/${logradouro}/json`
  },
  chat: `/chat`
};

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@service/auth.service';
import { NavigatorService } from '@service/navigator.service';
import { Store } from '@ngrx/store';
import { AegeaStore } from 'store/aegea.store';
import { MatriculasAegeaStore } from 'store/matriculas/matriculas.reducer';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  private dadosReferenciais: MatriculasAegeaStore;

  constructor(
    store: Store<AegeaStore>,
    private authService: AuthService,
    private navigatorService: NavigatorService,
  ) {
    store.select('matriculas').subscribe((result) => {
      this.dadosReferenciais = result;
    })
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const route = this.navigatorService.cleanURL(state.url);

    if (!this.authService.isCorporateUser()) {
      const installation = this.dadosReferenciais.matricula_selecionada;
      if (typeof installation === 'undefined' || installation === '' || !installation) {
        this.authService.signOut();
        return false;
      }
    }

    return true;
  }
}

export interface HomeGerarGuiaRequest {
  matricula: number;
  zona: number;
}

export interface IGuiaGerada {
  anoExtrato: number;
  codBarras: string;
  dataVencimento: string; //  anoExtrato: 2020
  linhaDigitavel: string;
  numExtrato: number;
  tributo: string; // "GUIA"
  valorTotal: number;
}

export interface HomeResponse {
  quantidadeDebitos: number;
  valorTotalDebitos: number;
  situacaoInstalacao: string;
  temDebitoVencido: boolean;
  fatura?: FaturaResponse;
}

export interface FaturaResponse {
  dataVencimento: string;
  numAviso: number;
  numEmissao: number;
  tributo: string;
  periodoMedicaoFim: string;
  periodoMedicaoInicio: string;
  situacaoPagamento: string;
  situacaoPagamentoDesc: string;
  valor: number;
  anoLancamentoConta: number;
  mesLancamentoConta: number;
}

export class HomeResponseMaker {
  static create(home: HomeResponse) {
    if (home) {
      return {
        fatura: home.fatura,
        situacaoInstalacao: home.situacaoInstalacao,
        quantidadeDebitos: home.quantidadeDebitos,
        temDebitoVencido: home.temDebitoVencido,
        valorTotalDebitos: home.valorTotalDebitos
      };
    }

    return {};
  }
}

export interface HomeViewModel {
  debitCount?: number;
  valorTotalDebitos?: number;
  status?: string;
  dataVencimento?: string;
  fromPeriod?: string;
  toPeriod?: string;
  lastBillDueDate?: string;
  lastBillValue?: number;
  paymentSituation?: string;
  paymentSituationDescription?: string;
  tribute?: string;
  launchBillYear?: number;
  launchBillMonth?: number;
  numAviso?: number;
  numEmissao?: number;
  hasBills?: boolean;
  hasOverdueBills?: boolean;
}

export class HomeViewModelMaker {
  static create(home: HomeResponse): HomeViewModel {
    if (home.fatura) {
      return {
        hasBills: home.fatura.tributo == 'MENSAL',
        debitCount: home.quantidadeDebitos,
        valorTotalDebitos: home.valorTotalDebitos,
        hasOverdueBills: home.temDebitoVencido,
        status: home.situacaoInstalacao,
        fromPeriod: home.fatura.periodoMedicaoInicio,
        toPeriod: home.fatura.periodoMedicaoFim,
        lastBillDueDate: home.fatura.dataVencimento,
        lastBillValue: home.fatura.valor,
        paymentSituation: home.fatura.situacaoPagamento,
        paymentSituationDescription: home.fatura.situacaoPagamentoDesc,
        tribute: home.fatura.tributo,
        launchBillYear: home.fatura.anoLancamentoConta,
        launchBillMonth: home.fatura.mesLancamentoConta,
        numAviso: home.fatura.numAviso,
        numEmissao: home.fatura.numEmissao
      };
    }

    return {
      hasBills: home.quantidadeDebitos > 0,
      debitCount: home.quantidadeDebitos,
      valorTotalDebitos: home.valorTotalDebitos,
      hasOverdueBills: home.temDebitoVencido,
      status: home.situacaoInstalacao
    };
  }
}

import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { audienciaConfig } from '@config/audiencia.config';
import { LocalStorageService } from '@service/localstorage.service';

export enum USER_TYPE {
  AGENTE,
  IMOBILIARIA,
  AUTOATENDIMENTO
}

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  constructor(private localStorageService: LocalStorageService) {}

  getUserType(): USER_TYPE {
    const audience = this.decodeToken() ? this.decodeToken().audience : '';
    return audienciaConfig.perfil(audience);
  }

  decodeToken(jwtEncodedToken: string = '') {
    const helper = new JwtHelperService();
    if (!jwtEncodedToken || jwtEncodedToken === '') {
      jwtEncodedToken = this.localStorageService.get('token');
    }

    if (typeof jwtEncodedToken !== 'undefined') {
      const decodedToken = helper.decodeToken(jwtEncodedToken);
      return decodedToken;
    }
  }

  getInstallationFromToken() {
    return this.decodeToken().matricula;
  }

  getInstallationDVFromToken() {
    const decodedToken = this.decodeToken();

    return decodedToken['digito-verificador'] ? JSON.parse(decodedToken['digito-verificador']) : '';
  }

  getContractActiveFromToken(): boolean {
    const decodedToken = this.decodeToken();

    if (typeof decodedToken !== 'undefined' && !this.isCorporateUser()) {
      return JSON.parse(decodedToken['user-contract-active']);
    }
    return true;
  }

  isLoggedFromToken() {
    const decodedToken = this.decodeToken();
    return typeof decodedToken !== 'undefined';
  }

  private isCorporateUser(): boolean {
    return this.localStorageService.get('isCorporate') === 'true';
  }
}

<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">{{ titulo }}</h2>
        <p>Neste momento o acesso é feito apenas através de CPF ou CNPJ</p>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row row-alert">
      <div class="col-sm-12">
        <div class="alert alert-info" role="alert">
          <h4 class="alert-heading">Informação</h4>
          <p>
            Orientamos a buscar maiores informações de como
            <a
              href="https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-tributaria/cadastros/cpf"
              target="_blank"
              >cadastrar um novo CPF</a
            >
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>Em caso de dúvidas, reclamações ou sugestões, consulte os canais de atendimento:</p>
        <inc-atendimento></inc-atendimento>
      </div>
    </div>
  </div>
</layout-modal>

<mat-form-field>
  <mat-label>
    {{ label }}
    <sup *ngIf="tip">{{ tip }}</sup>
  </mat-label>
  <textarea
    #elem
    matInput
    [name]="name"
    type="text"
    [rows]="rows"
    [required]="control.errors?.required"
    [inputDisabled]="disabled"
    [attr.minlength]="minlength"
    [attr.maxlength]="maxlength"
    [formControl]="control"
    autocomplete="off"
    [placeholder]="placeholder"
    [attr.data-layer]="datalayer"
    (keydown.enter)="BlockEnterSubmit($event)"
  >
  </textarea>
  <mat-error>{{ errorMessage }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">Encontre a matrícula</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          <img src="/assets/documentos/onde-esta-matricula.jpg" />
        </p>
        <p>
          Para encontrar a matrícula, localize a sequência de números que se encontra no canto superior direito da
          fatura.
        </p>
      </div>
    </div>
  </div>
</layout-modal>

import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
@Directive({
  selector: 'input[cpfCnpjValidator]'
})
export class CpfCnpjDirective {
  @Input() ngModel: string;
  @Output() cpfcnpjEventValidation = new EventEmitter();

  private readonly cpfSize = 11;
  private readonly cnpjSize = 14;

  @HostListener('keyup') onkeyup() {
    this.emittAnswer();
  }

  private emittAnswer() {
    if (this.ngModel) {
      this.cpfcnpjEventValidation.emit(this.validateCpfOrCnpj(this.ngModel.replace(/(\.|\/|\-)/g, '')));
    }
  }

  private validateCpfOrCnpj(cpfOrCnpj: string): boolean {
    if (!cpfOrCnpj) {
      return false;
    }

    cpfOrCnpj = cpfOrCnpj.replace(/[^\d]+/g, '');

    if (cpfOrCnpj.length === this.cpfSize) {
      return this.validateCpf(cpfOrCnpj);
    }

    if (cpfOrCnpj.length === this.cnpjSize) {
      return this.validateCnpj(cpfOrCnpj);
    }

    return false;
  }

  private invalidCpfCollection(): Array<string> {
    return [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999'
    ];
  }

  private invalidCnpjCollection(): Array<string> {
    return [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999'
    ];
  }

  private isNullOrEmpty(cpf: string) {
    return cpf === '' || cpf === undefined;
  }

  private validateCpf(cpf: string): boolean {
    if (this.isNullOrEmpty(cpf)) {
      return false;
    }

    if (this.invalidCpfCollection().find((invalidCpfCollection) => invalidCpfCollection.includes(cpf))) {
      return false;
    }

    let sum = 0;
    for (let index = 0; index < this.cpfSize - 2; index++) {
      // tslint:disable-next-line:radix
      sum += parseInt(cpf.charAt(index)) * (this.cpfSize - 1 - index);
    }

    let rest = this.cpfSize - (sum % this.cpfSize);
    if (rest === this.cpfSize - 1 || rest === this.cpfSize) {
      rest = 0;
    }

    // tslint:disable-next-line:radix
    if (rest !== parseInt(cpf.charAt(this.cpfSize - 2))) {
      return false;
    }

    sum = 0;
    for (let index = 0; index < this.cpfSize - 1; index++) {
      // tslint:disable-next-line:radix
      sum += parseInt(cpf.charAt(index)) * (this.cpfSize - index);
    }

    rest = this.cpfSize - (sum % this.cpfSize);
    if (rest === this.cpfSize - 1 || rest === this.cpfSize) {
      rest = 0;
    }

    // tslint:disable-next-line:radix
    if (rest !== parseInt(cpf.charAt(this.cpfSize - 1))) {
      return false;
    }

    return true;
  }

  private validateCnpj(cnpj: string): boolean {
    if (this.isNullOrEmpty(cnpj)) {
      return false;
    }

    if (this.invalidCnpjCollection().find((invalidCnpjCollection) => invalidCnpjCollection.includes(cnpj))) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let index = tamanho; index >= 1; index--) {
      soma += parseInt(numeros.charAt(tamanho - index), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // tslint:disable-next-line:triple-equals
    if (resultado != parseInt(digitos.charAt(0), 10)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let index = tamanho; index >= 1; index--) {
      soma += parseInt(numeros.charAt(tamanho - index), 10) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // tslint:disable-next-line:triple-equals
    if (resultado != parseInt(digitos.charAt(1), 10)) {
      return false; // LINHA 49
    }

    return true; // LINHA 51
  }
}

import {
  Component,
  Input,
  ElementRef,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NeedBeChecked } from '@validator/need-be-checked';
import { STRING_BOOLEAN } from '@model/string-boolean.resolver';

@Component({
  selector: 'ui-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxFormComponent implements OnInit {
  protected validators = [];
  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Sim';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = '';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(new NeedBeChecked());
    }

    this.checked = STRING_BOOLEAN.checkTrue(this.control.value);

    this.control.setValidators(this.validators);
    this.control.valueChanges.subscribe((newValue) => {
      this.checked = STRING_BOOLEAN.checkTrue(newValue);
      this.onNewValue.emit(newValue);
    });
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Change(event) {
    this.checked = event.checked;
    this.control.setValue(this.checked);
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.invalidMessage;
    }
  }
}

import { version } from '../../../package.json';
import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { UserViewModel, UserViewModelMaker } from '@model/usuario.resolver';

import { USER_TYPE, JwtService } from '@service/jwt.service';
import { GtmService } from '@service/gtm.service';
import { UsuarioService } from '@service/usuario.service';
import { LocalStorageService } from '@service/localstorage.service';
import { AVLocalStorageService } from '@service/av-localstorage.service';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LIGACAO_STATUS, LigacaoService } from '@service/ligacao.service';
import { ImovelService } from '@service/imovel.service';
import { SmoochService } from '@service/smooch.service';
import { RealtyResponse } from '@model/imovel.resolver';
import { EnvironmentService, PlatformInfo } from '@service/environment.service';
import { NavigatorService } from '@service/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { ChatModal } from '@modal/modal-chat/chat.modal';
import { keyConfig } from '@config/keys.config';
import { AegeaStore } from 'store/aegea.store';
import { EnvironmentAegeaStore, ENV_NAME } from 'store/environment/environment.reducer';

interface BaseNav {
  icon: string;
  title: string;
  show: boolean;
  url?: string;
  action?: VoidFunction;
  menu?: BaseMenu[];
  datalayer?: string;
}

interface BaseMenu {
  link: string;
  url: string;
  disabled?: boolean;
  datalayer?: string;
  show: boolean;
}

interface FooterNav {
  icon: string;
  title: string;
  info?: FooterInfo[];
  menu?: FooterMenu[];
}

interface FooterInfo {
  text: string;
}

interface FooterMenu {
  link: string;
  url?: string;
  external?: string;
  show: boolean;
  datalayer?: string;
}

interface PerfilNav {
  tipo?: string;
  titular: string;
  icon: string;
  nome: string;
  home: string;
  hasPassword: boolean;
  isAgente?: boolean;
  isImobiliaria?: boolean;
}

@Component({
  selector: 'layout-base',
  templateUrl: './base.layout.html',
  styleUrls: ['./base.layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseLayout implements OnInit {
  @ViewChild('chatModal', { static: true }) chatModal: ChatModal;

  @Input() pageTitle: string;
  @Input() forceNoLogged: boolean;

  private base$: Observable<EnvironmentAegeaStore>;

  backendEnv: string = '';
  version: string = version;
  ano: number = new Date().getFullYear();

  url: string;
  idUnidade: string;

  service: any = {};
  setup: any = {};

  menu = false;

  isLogged = false;
  noLogged = true;

  get isLocalhost(): boolean {
    return this.backendEnv !== ENV_NAME.DEV && this.backendEnv !== ENV_NAME.HML && this.backendEnv !== ENV_NAME.PROD;
  }

  get isDevelopment(): boolean {
    return this.backendEnv === ENV_NAME.DEV;
  }

  get isHomologation(): boolean {
    return this.backendEnv === ENV_NAME.HML;
  }

  get isProduction(): boolean {
    return this.backendEnv === ENV_NAME.PROD;
  }

  get avatar(): string {
    //return this.localStorageService.get('avatar');
    return '';
  }

  baseUrl = this.extractHostname(environment.baseUrl);

  isContratoAtivo = false;
  isLigacaoAtiva = false;
  isPageUnidade = false;
  isPageHome = false;
  isPageLogin = false;
  isPageHomeAgente = false;
  isPageHomeImobiliaria = false;
  isPageHomeAtendimento = false;

  userType: USER_TYPE;

  userViewModel: UserViewModel = {};
  addressViewModel: any = {};
  getLigacaoStatus: LIGACAO_STATUS = LIGACAO_STATUS.A;
  baseNav: BaseNav[];
  footerNav: FooterNav[];
  ipAddress: string;

  perfilNav: PerfilNav = {
    titular: '',
    icon: 'account_circle',
    nome: 'Perfil',
    home: '/home',
    hasPassword: true // TODO: Aguardando integracao sprint #23
  };

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    private titleService: Title,
    private jwtService: JwtService,
    private navigatorService: NavigatorService,
    private localStorageService: LocalStorageService,
    private avLocalStorageService: AVLocalStorageService,
    private usuarioService: UsuarioService,
    private ligacaoService: LigacaoService,
    private router: Router,
    private dialog: MatDialog,
    private dataLayer: GtmService,
    private smoochService: SmoochService,
    private store: Store<AegeaStore>,
    private imovelService: ImovelService,
    private envService: EnvironmentService
  ) {
    this.base$ = this.store.select('environment');
  }

  ngOnInit() {
    this.base$.subscribe((d) => {
      this.backendEnv = d.env;
    });

    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });

    this.url = this.router.url;
    this.idUnidade = this.localStorageService.get('unit');

    this.avLocalStorageService.getUnidadeDetalhes().then((unidadeSetup) => {
      if (this.pageTitle.length > 33) {
        console.warn('[pageTitle] Existe um limite de 33 caracteres para o título');
      }

      if (unidadeSetup?.unidade) {
        this.titleService.setTitle(`${this.pageTitle} • ${unidadeSetup.unidade}`);
      } else {
        this.titleService.setTitle(this.pageTitle);
      }

      this.dataLayer.set('virtualPageview', {
        virtualPageURL: this.router.url,
        virtualPageTitle: this.titleService.getTitle()
      });
    });

    if (this.jwtService.isLoggedFromToken()) {
      this.isLogged = true;
      this.noLogged = false;
    } else {
      this.isLogged = false;
      this.noLogged = true;
    }

    if (this.forceNoLogged) {
      this.isLogged = false;
      this.noLogged = true;
    }

    this.isContratoAtivo = this.ligacaoService.isContratoAtivo();

    this.ligacaoService.isLigacaoAtiva((isLigacaoAtiva: boolean) => {
      this.isLigacaoAtiva = isLigacaoAtiva;
    });

    this.ligacaoService.getLigacaoStatus((getLigacaoStatus: LIGACAO_STATUS) => {
      this.getLigacaoStatus = getLigacaoStatus;
    });

    this.isPageUnidade = this.isPage('unidade');
    this.isPageHome = this.isPage('home');
    this.isPageLogin = this.isPage('login');
    this.isPageHomeAgente = this.isPage('agente/imovel');
    this.isPageHomeImobiliaria = this.isPage('imobiliaria/imovel');
    this.isPageHomeAtendimento = this.isPage('atendimento/trocar-matricula');

    this.avLocalStorageService.getUnidadeServicos().then((unidadeServices) => {
      this.avLocalStorageService.getUnidadeDetalhes().then((unidadeSetup) => {
        if (unidadeServices) {
          this.service = unidadeServices;
          this.menuBox();
          this.menuHeader();
          this.chat(unidadeSetup.zendeskIntegrationID);
          this.datalayerUnidadeServices(this.service);
        }
        if (unidadeSetup) {
          this.setup = unidadeSetup;
          this.setup.linkInstagram = 'https://www.instagram.com/aegea.saneamento/';
          this.menuBox();
          this.menuFooter();
          this.datalayerUnidadeSetup(this.setup);
        }
      });
    });

    if (this.isLogged) {
      this.usuarioService.getUser((succeedResponse) => {
        if (succeedResponse) {
          this.userViewModel = UserViewModelMaker.create(succeedResponse);
          this.userViewModel.name = this.onlyFirstNameInCamelCase(this.userViewModel.name);
          this.menuBox();
          this.datalayerUsuario(this.userViewModel);

          this.imovelService.getCurrentInstallation((succeedResponse: RealtyResponse) => {
            if (succeedResponse) {
              this.addressViewModel = succeedResponse.logradouro;
            }
          });
        }
      });
    }
  }

  ligacaoStatus(status: LIGACAO_STATUS) {
    return status;
  }

  ligacaoStatusColor() {
    switch (this.getLigacaoStatus) {
      case LIGACAO_STATUS.O:
        return 'info';
      case LIGACAO_STATUS.K:
        return 'warning';
      case LIGACAO_STATUS.I:
        return 'danger';
      case LIGACAO_STATUS.D:
        return 'muted';
      case LIGACAO_STATUS.V:
        return 'muted';
      case LIGACAO_STATUS.A:
        return 'success';
      case LIGACAO_STATUS.C:
        return 'warning';
      default:
        return 'success';
    }
  }

  private extractHostname(url: string) {
    let hostname: string;

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
  }

  private successCallbackIpAddressAction(succeedResponse: any) {
    this.ipAddress = succeedResponse.ip;

    this.dataLayer.set('IP', {
      ip: this.ipAddress
    });
  }

  get linkPhone() {
    let telefone = this.setup.linkCallCenter
      .replace(' (fixo)', '')
      .replace(' (celular)', '')
      .replace(' (Celular)', '')
      .split(/ou|;/);

    if (telefone.length === 1) {
      return (
        '<a data-layer="footer-menu-telefone"="true" href="tel:' +
        telefone[0].replace(/[^\d]+/g, '') +
        '">' +
        telefone[0].trim() +
        '</a>'
      );
    }

    if (telefone.length > 1) {
      return telefone
        .map(
          (d) =>
            '<a data-layer="footer-menu-telefone"="true" href="tel:' +
            d.replace(/[^\d]+/g, '') +
            '">' +
            d.trim() +
            '</a>'
        )
        .join(' ou ');
    }

    return 'Disque: <a href="tel:115">115</a>';
  }

  get linkWhatsapp() {
    let link = this.setup?.linkWhatsapp || '',
      telefone = link.replace(/[^\d]+/g, ''),
      mensagem = 'Oi';

    var splited = telefone.split('');
    if (splited[0] == 5 && splited[1] == 5) {
      return 'https://api.whatsapp.com/send?phone=' + telefone + '&text=' + mensagem;
    } else {
      return 'https://api.whatsapp.com/send?phone=55' + telefone + '&text=' + mensagem;
    }

    return '';
  }

  private menuBox() {
    if (this.isLogged && !(this.isPageHomeAgente || this.isPageHomeImobiliaria)) {
      let titular = this.localStorageService.get('nomeTitular');
      this.userType = this.jwtService.getUserType();

      switch (this.userType) {
        case USER_TYPE.AGENTE: {
          this.perfilNav = {
            tipo: 'Agente',
            nome: 'Agente',
            icon: 'supervised_user_circle',
            titular: titular?.replace(/\s.*\s/, ' '),
            home: '/agente/imovel',
            isAgente: true,
            hasPassword: false
          };
          break;
        }

        case USER_TYPE.IMOBILIARIA: {
          this.perfilNav = {
            tipo: 'Imobiliária',
            nome: 'Imobiliária',
            icon: 'apartment',
            titular: titular?.replace(/\s.*\s/, ' '),
            home: '/imobiliaria/imovel',
            isImobiliaria: true,
            hasPassword: true
          };
          break;
        }

        case USER_TYPE.AUTOATENDIMENTO: {
          this.perfilNav = {
            nome: 'Perfil',
            icon: 'account_circle',
            titular: this.userViewModel.pessoa?.nome?.replace(/\s.*\s/, ' '),
            home: '/atendimento/trocar-matricula',
            hasPassword: true
          };
          break;
        }
      }
    }
  }

  private menuHeader() {
    const menuFatura = [
      {
        datalayer: 'menu-pagar',
        link: 'Pagar fatura',
        url: '/fatura/pagar-fatura',
        show: this.isLogged && this.service?.historicoContaConsumo
      },
      {
        datalayer: 'menu-2via',
        link: 'Segunda via',
        url: '/fatura/segunda-via',
        show: this.isLogged && this.service?.segundaViaRapida
      },
      {
        datalayer: 'menu-parcelar-fatura',
        link: 'Parcelar fatura',
        url: '/parcelamento',
        show: this.isLogged && this.service?.parcelamento && !(this.perfilNav.isImobiliaria)
      },
      {
        datalayer: 'menu-debito-automatico',
        link: 'Débito automático',
        url: '/pagamento/debito-automatico',
        show: this.isLogged && this.service?.debitoAutomatico
      },
      {
        datalayer: 'menu-historico-contas',
        link: 'Histórico de faturas',
        url: '/fatura/historico-fatura',
        show: this.isLogged && this.service?.historicoContaConsumo
      },
      {
        datalayer: 'menu-historico-consumo',
        link: 'Histórico de consumo',
        url: '/fatura/historico-consumo',
        show: this.isLogged && this.service?.historicoContaConsumo
      },
      {
        datalayer: 'menu-historico-integral-consumo',
        link: 'Histórico integral',
        url: '/fatura/historico-integral',
        show: this.isLogged && this.service?.historicoIntegralConsumo
      },
      {
        datalayer: 'menu-conta-email',
        link: 'Fatura por e-mail',
        url: '/fatura/fatura-por-email',
        show: this.isLogged && this.service?.contasPorEmail && !(this.perfilNav.isImobiliaria || this.perfilNav.isAgente)
      },
      {
        datalayer: 'menu-cronograma-leitura',
        link: 'Cronograma de leitura',
        url: '/fatura/cronograma-leitura',
        show: this.isLogged && this.service?.cronogramaLeitura
      },
      {
        datalayer: 'menu-certidao-negativa',
        link: 'Certidão negativa',
        url: '/fatura/certidao-negativa',
        show: this.isLogged && this.service?.certidaoNegativa
      },
      {
        datalayer: 'menu-qualidade-agua',
        link: 'Qualidade da água',
        url: '/fatura/qualidade-agua',
        show: this.isLogged && this.service?.qualidadeAgua
      }
    ];

    const menuServico = [
      {
        datalayer: 'menu-ligacao-agua',
        link: 'Ligação de água',
        url: '/servico/ligacao-agua',
        show: !this.isLogged && this.service?.novaLigacaoAgua
      },
      {
        datalayer: 'menu-ligacao-esgoto',
        link: 'Ligação de esgoto',
        url: '/servico/ligacao-esgoto',
        show: this.service?.novaLigacaoEsgoto
      },
      {
        datalayer: 'menu-trocar-titularidade',
        link: 'Trocar titularidade',
        url: '/servico/trocar-titularidade',
        show: !this.isLogged && this.service?.trocaTitularidade
      },
      {
        datalayer: 'menu-alterar-cadastro',
        link: 'Alterar cadastro',
        url: '/perfil/alterar-cadastro',
        show: this.isLogged && this.service?.alterarCadastro
      },
      {
        datalayer: 'menu-denuncia',
        link: 'Denúncia',
        url: '/servico/denuncia',
        show: this.service?.denuncia
      },
      {
        datalayer: 'menu-falta-agua',
        link: "Falta d'água",
        url: '/servico/falta-agua',
        show: this.isLogged && this.service?.faltaAgua
      },
      {
        datalayer: 'menu-vazamento',
        link: 'Vazamento de água',
        url: '/servico/vazamento',
        show: this.service?.vazamentoAgua
      },
      {
        datalayer: 'menu-esgoto-entupido',
        link: 'Esgoto entupido',
        url: '/servico/esgoto-entupido',
        show: this.service?.esgotoEntupido
      },
      {
        datalayer: 'menu-esgoto',
        link: 'Serviço de esgoto',
        url: '/servico/esgoto',
        show: this.service?.servicoEsgoto
      },
      {
        datalayer: 'menu-pedido-corte',
        link: 'Pedido de corte',
        url: '/servico/pedido-corte',
        show: this.isLogged && this.service?.cortePedido
      },
      {
        datalayer: 'menu-mudanca-local',
        link: 'Mudança de local',
        url: '/servico/mudar-local-hidrometro',
        show: this.isLogged && this.service?.mudancaLocal
      },
      {
        datalayer: 'menu-religacao',
        link: 'Religação',
        url: '/servico/religacao',
        show: this.isLogged && (this.service?.religacao || this.service?.novaReligacao)
      },
      {
        datalayer: 'menu-troca-registro',
        link: 'Troca de registro',
        url: '/servico/trocar-registro',
        show: this.isLogged && this.service?.trocaHidrometro
      },
      {
        datalayer: 'menu-conserto-cavalete',
        link: 'Conserto de cavalete',
        url: '/servico/conserto-cavalete',
        show: this.isLogged && this.service?.consertoCavalete
      },
      {
        datalayer: 'menu-tarifa-social',
        link: 'Tarifa social',
        url: '/servico/tarifa-social',
        show: this.isLogged && this.service?.tarifaSocial
      },
      {
        datalayer: 'menu-encerrar-contrato',
        link: 'Encerrar contrato',
        url: '/servico/encerrar-contrato',
        show: this.isLogged && this.service?.encerrarContrato
      }
    ];

    const menuPerfil = [
      {
        datalayer: 'menu-meus-dados',
        link: 'Meu perfil',
        url: '/perfil',
        show: this.isLogged
      },
      {
        datalayer: 'menu-alterar-cadastro',
        link: 'Alterar cadastro',
        url: '/perfil/alterar-cadastro',
        show: this.isLogged && this.service?.alterarCadastro
      },
      {
        datalayer: 'menu-alterar-contatos',
        link: 'Alterar contatos',
        url: '/perfil/alterar-contatos',
        show: this.isLogged
      },
      {
        datalayer: 'menu-alterar-acessos',
        link: 'Alterar acessos',
        url: '/perfil/alterar-acessos',
        show: this.isLogged && this.perfilNav.hasPassword && !(this.perfilNav.isImobiliaria || this.perfilNav.isAgente)
      },
      {
        datalayer: 'menu-alterar-senha',
        link: 'Alterar senha',
        url: '/perfil/alterar-senha',
        show: this.isLogged && this.perfilNav.hasPassword && !this.perfilNav.isAgente
      },
      {
        datalayer: 'menu-imovel',
        link: 'Trocar matrícula',
        url: this.perfilNav.home,
        show: this.isLogged
      }
    ];

    // TODO: Set datalayer attribute in HTML
    this.baseNav = [
      {
        icon: 'home',
        datalayer: 'menu-home',
        title: 'Página inicial',
        url: '/home',
        show: !(
          this.isPageHome ||
          this.isPageLogin ||
          this.isPageHomeAgente ||
          this.isPageHomeImobiliaria ||
          this.isPageHomeAtendimento
        )
      },

      {
        icon: 'request_page',
        datalayer: 'menu-contas',
        title: 'Faturas',
        show: 0 < menuFatura.filter((d) => d.show).length && this.isLogged,
        menu: menuFatura
      },

      {
        icon: 'plumbing',
        datalayer: 'menu-servicos',
        title: 'Serviços',
        show:
          0 < menuServico.filter((d) => d.show).length &&
          this.isContratoAtivo &&
          !(this.isPageHomeAgente || this.isPageHomeImobiliaria || this.isPageHomeAtendimento),
        menu: menuServico
      },

      {
        icon: this.perfilNav.icon,
        datalayer: 'menu-perfil',
        title: this.perfilNav.nome,
        show: 0 < menuPerfil.filter((d) => d.show).length && this.isLogged,
        menu: menuPerfil
      },

      {
        datalayer: 'menu-sair',
        icon: 'fas fa-sign-out',
        title: 'Sair',
        show: this.isLogged || this.forceNoLogged,
        action: () => {
          this.logout();
        }
      }
    ];
  }

  private menuFooter() {

    const menuSobre = () => {
      if (this.platformInfo.isMobile) return {
        datalayer: 'footer-menu-sobre',
        link: 'Sobre este aplicativo',
        external: 'https://aeservicosonline.com.br/agencia/sobre',
        show: true
      }
      else return {
        datalayer: 'footer-menu-sobre',
        link: 'Sobre este website',
        url: '/agencia/sobre',
        show: true
      }
    }


    // TODO: Set datalayer attribute in HTML
    this.footerNav = [
      {
        icon: 'home',
        title: this.setup?.unidade ? this.setup.unidade : 'Sobre a unidade',
        menu: [
          {
            datalayer: 'footer-menu-lojas',
            link: 'Lojas conveniadas',
            url: '/agencia/lojas',
            show: true
          },
          {
            datalayer: 'footer-menu-institucional',
            link: 'Institucional',
            external: this.setup?.linkInstitucional,
            show: this.setup?.linkInstitucional
          },
          {
            datalayer: 'footer-menu-sustentabilidade',
            link: 'Sustentabilidade',
            external: this.setup?.linkSustentabilidade,
            show: this.setup?.linkSustentabilidade
          },
          {
            datalayer: 'footer-menu-ouvidoria',
            link: 'Ouvidoria',
            external: this.setup?.linkOuvidoria,
            show: this.setup?.linkOuvidoria
          },
          {
            datalayer: 'footer-menu-politica-privacidade',
            link: 'Política de privacidade',
            external: 'https://www.aegea.com.br/politica-de-privacidade-e-protecao-de-dados-da-aegea/',
            show: this.service?.politicaPrivacidade
          },
          {
            datalayer: 'footer-menu-termo-uso',
            link: 'Termo de uso',
            external: 'https://www.aegea.com.br/termos-gerais-de-uso-de-sites-e-aplicativos-da-aegea/',
            show: this.service?.termoDeUso
          }
        ]
      },

      {
        icon: 'phone',
        title: 'Fale conosco',
        info: [
          {
            text: this.linkPhone
          }
        ],
        menu: [
          {
            datalayer: 'footer-menu-whatsapp',
            link: 'Enviar Whatsapp',
            external: this.linkWhatsapp,
            show: this.setup?.linkWhatsapp
          }
        ]
      },

      {
        icon: 'devices',
        title: 'Agência virtual',
        menu: [
          menuSobre(),
          {
            datalayer: 'footer-menu-google-play',
            link: 'Baixe no Google Play',
            external: 'https://play.google.com/store/apps/details?id=br.com.aegea.services',
            show: this.platformInfo.isDesktop || this.platformInfo.isAndroid
          },
          {
            datalayer: 'footer-menu-apple-store',
            link: 'Baixe na App Store',
            external: 'https://apps.apple.com/br/app/%C3%A1guas-app/id1448120446',
            show: this.platformInfo.isDesktop || this.platformInfo.isIos
          }
        ]
      }
    ];
  }

  private chat(integrationId: string) {
    if (this.service.chatZendesk && integrationId) {
      this.smoochService.connect(
        integrationId,
        succeedResponse => {},
        failureResponse => {}
      )
    }
  }

  // TODO: Sprint #6: Coletar métricas do usuário
  private datalayerUsuario(user) {
    const decoded = this.jwtService.decodeToken();
    const userId = decoded && decoded['user-id'] ? this.localStorageService.get('unit') + '_' + decoded['user-id'] : '';

    this.dataLayer.set('Identificador único', {
      identificador_unico: userId
    });
  }

  private datalayerUnidadeServices(service) {
    //
  }

  private datalayerUnidadeSetup(setup) {
    this.dataLayer.set('Unidade', {
      unidade: setup?.unidade
    });
  }

  private isPage(page: string): boolean {
    return this.url.indexOf(page) >= 0;
  }

  private onlyFirstNameInCamelCase(name: string): string {
    if (name) {
      return name.split(' ')[0][0].toUpperCase() + name.split(' ')[0].substring(1, this.userViewModel.name.length);
    }
    return name;
  }

  abrirChat() {
    if (!this.service.chatZendesk) {
      this.dialog.open(ChatModal, {
        minWidth: 700,
        maxHeight: 475
      });
    }
  }

  logout() {
    this.menu = false;
    this.navigatorService.goTo('logout');
  }
}

import { Validator, AbstractControl, ValidationErrors } from '@angular/forms';

export class NeedBeChecked implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.value != true && control.value != 'true') {
      return { ['checkedNeed']: 'Necessário checar o item' };
    }
    return null;
  }
  registerOnValidatorChange?(fn: () => void) {
    console.warn('Ocorrencia registerOnValidatorChange');
  }
}

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';

// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from '@core/header.interceptor';
import { ErrorHandlerInterceptor } from '@core/error-handler.interceptor';

// Material Angular
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Ngx
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxPaginationModule } from 'ngx-pagination';

// Layouts
import { BaseLayout } from '@layout/base/base.layout';
import { NoticeLayout } from '@layout/notice/notice.layout';
import { PrintLayout } from '@layout/print/print.layout';
import { ModalLayout } from '@layout/modal/modal.layout';

import { PrintComponent } from '@component/ui-print/print.component';
import { CardPreviewComponent } from '@component/card-preview/card-preview.component';
import { FullBannerComponent } from '@component/full-banner/full-banner.component';
import { BarcodeComponent } from '@component/barcode/barcode.component';

// Includes
import { AvisoCadastroInclude } from '@include/inc-cadastro/aviso-cadastro.include';
import { CanaisAtendimentoInclude } from '@include/inc-atendimento/canais-atendimento.include';
import { ShortcutsInclude } from '@include/inc-atalhos/atalhos.include';

// Modals
import { EsqueciMatriculaModal } from '@modal/modal-esqueci-matricula/esqueci-matricula.modal';
import { EsqueciSenhaModal } from '@modal/modal-esqueci-senha/esqueci-senha.modal';
import { EconomiasModal } from '@modal/modal-economias/economias.modal';
import { InfoAcessoModal } from '@modal/modal-info-acesso/info-acesso.modal';
import { InfoEstrangeiroModal } from '@modal/modal-info-estrangeiro/info-estrangeiro.modal';
import { ConsultaEnderecoModal } from '@modal/modal-consulta-endereco/consulta-endereco.modal';
import { AutodenunciaModal } from '@modal/modal-auto-denuncia/auto-denuncia.modal';
import { UploadModal } from '@modal/modal-upload/upload.modal';
import { CameraModal } from '@modal/modal-camera/camera.modal';
import { ChatModal } from '@modal/modal-chat/chat.modal';
import { LeiDuvidaModal } from '@modal/modal-lei-duvida/lei-duvida.modal';
import { DocumentoDuvidaModal } from '@modal/modal-documento-duvida/documento-duvida.modal';
import { AceitarPrivacidadeModal } from '@modal/modal-aceitar-privacidade/aceitar-privacidade.modal';
import { CameraAgenteModal } from '@modal/modal-camera-agente/camera-agente.modal';

// Components
import { InputIdFormComponent } from '@component/ui-input-id/input-id.component';
import { InputCpfCnpjFormComponent } from '@component/ui-input-cpf-cnpj/input-cpf-cnpj.component';
import { InputNumberFormComponent } from '@component/ui-input-number/input-number.component';
import { InputCepFormComponent } from '@component/ui-input-cep/input-cep.component';
import { InputRneCieFormComponent } from '@component/ui-input-rne-cie/input-rne-cie.component';
import { InputEmailFormComponent } from '@component/ui-input-email/input-email.component';
import { InputPhoneFormComponent } from '@component/ui-input-phone/input-phone.component';
import { InputAlphanumericFormComponent } from '@component/ui-input-alphanumeric/input-alphanumeric.component';
import { InputCardNumberFormComponent } from '@component/ui-input-card-number/input-card-number.component';
import { InputDateFormComponent } from '@component/ui-input-date/input-date.component';
import { InputCurrencyFormComponent } from '@component/ui-input-currency/input-currency.component';
import { InputPasswordFormComponent } from '@component/ui-input-password/input-password.component';
import { InputSelectFormComponent } from '@component/ui-input-select/input-select.component';
import { InputCheckboxFormComponent } from '@component/ui-input-checkbox/input-checkbox.component';
import { InputRadioFormComponent } from '@component/ui-input-radio/input-radio.component';
import { InputTextareaFormComponent } from '@component/ui-input-textarea/input-textarea.component';
import { InputFileFormComponent } from '@component/ui-input-file/input-file.component';
import { IconComponent } from '@component/ui-icon/ui-icon.component';
import { ShortchutComponent } from '@component/ui-shortcut/ui-shortcut.component';
import { BadgeComponent } from '@component/ui-bagde/ui-badge.component';
import { UploadComponent } from '@component/ui-upload/ui-upload.component';
import { InputAutocompleteFormComponent } from '@component/ui-input-autocomplete/input-autocomplete.component';
import { InputAddressAutocompleteFormComponent } from '@component/ui-input-address/input-address.component';

// Pipes
import { FilterPipeModule } from 'ngx-filter-pipe';
import { InstallationPipe } from '@pipe/installation.pipe';
import { TextCropperPipe } from '@pipe/text-cropper.pipe';
import { SafePipe } from '@pipe/safe.pipe';

// Directives
import { InputDisabledDirective } from '@directive/input-disabled.directive';
import { BlockCopyPasteDirective } from '@directive/block-copy-paste.directive';
import { CpfCnpjDirective } from '@directive/cpf-cnpj.directive';
import { DueDateDirective } from '@directive/due-date.directive';
import { NoLettersDirective } from '@directive/no-letters.directive';
import { NoNumbersDirective } from '@directive/no-numbers.directive';
import { NoSpecialCharsDirective } from '@directive/no-special-chars.directive';
import { ReplaceSpecialCharsDirective } from '@directive/replace-special-chars.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FilterPipeModule,

    // Ngx
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,

    // Material
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatStepperModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatTooltipModule,
    MatAutocompleteModule
  ],

  exports: [
    // Ngx
    NgxMaskModule,
    NgxCurrencyModule,
    NgxPaginationModule,

    // Material
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatStepperModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatTooltipModule,
    MatAutocompleteModule,

    // Layouts
    BaseLayout,
    NoticeLayout,
    PrintLayout,
    ModalLayout,

    // Includes
    AvisoCadastroInclude,
    CanaisAtendimentoInclude,
    ShortcutsInclude,

    // Modals
    EsqueciMatriculaModal,
    EsqueciSenhaModal,
    EconomiasModal,
    InfoAcessoModal,
    InfoEstrangeiroModal,
    ConsultaEnderecoModal,
    UploadModal,
    CameraModal,
    AutodenunciaModal,
    ChatModal,
    LeiDuvidaModal,
    DocumentoDuvidaModal,
    AceitarPrivacidadeModal,
    CameraAgenteModal,

    // Components
    CardPreviewComponent,
    FullBannerComponent,
    BarcodeComponent,
    PrintComponent,
    InputIdFormComponent,
    InputCpfCnpjFormComponent,
    InputNumberFormComponent,
    InputCepFormComponent,
    InputRneCieFormComponent,
    InputEmailFormComponent,
    InputPhoneFormComponent,
    InputAlphanumericFormComponent,
    InputCardNumberFormComponent,
    InputDateFormComponent,
    InputCurrencyFormComponent,
    InputPasswordFormComponent,
    InputSelectFormComponent,
    InputCheckboxFormComponent,
    InputRadioFormComponent,
    InputTextareaFormComponent,
    InputFileFormComponent,
    IconComponent,
    ShortchutComponent,
    BadgeComponent,
    UploadComponent,
    InputAutocompleteFormComponent,
    InputAddressAutocompleteFormComponent,

    // Directives
    InputDisabledDirective,
    BlockCopyPasteDirective,
    CpfCnpjDirective,
    DueDateDirective,
    NoLettersDirective,
    NoNumbersDirective,
    NoSpecialCharsDirective,
    ReplaceSpecialCharsDirective,

    // Pipes
    InstallationPipe,
    TextCropperPipe,
    SafePipe
  ],

  declarations: [
    //Layouts
    BaseLayout,
    NoticeLayout,
    PrintLayout,
    ModalLayout,

    // Includes
    AvisoCadastroInclude,
    CanaisAtendimentoInclude,
    ShortcutsInclude,

    // Modals
    EsqueciMatriculaModal,
    EsqueciSenhaModal,
    EconomiasModal,
    InfoAcessoModal,
    InfoEstrangeiroModal,
    ConsultaEnderecoModal,
    UploadModal,
    CameraModal,
    AutodenunciaModal,
    ChatModal,
    LeiDuvidaModal,
    DocumentoDuvidaModal,
    AceitarPrivacidadeModal,
    CameraAgenteModal,

    // Components
    PrintComponent,
    CardPreviewComponent,
    FullBannerComponent,
    BarcodeComponent,
    InputIdFormComponent,
    InputCpfCnpjFormComponent,
    InputNumberFormComponent,
    InputCepFormComponent,
    InputRneCieFormComponent,
    InputEmailFormComponent,
    InputPhoneFormComponent,
    InputAlphanumericFormComponent,
    InputCardNumberFormComponent,
    InputDateFormComponent,
    InputCurrencyFormComponent,
    InputPasswordFormComponent,
    InputSelectFormComponent,
    InputCheckboxFormComponent,
    InputRadioFormComponent,
    InputTextareaFormComponent,
    InputFileFormComponent,
    IconComponent,
    ShortchutComponent,
    BadgeComponent,
    UploadComponent,
    InputAutocompleteFormComponent,
    InputAddressAutocompleteFormComponent,

    // Directives
    InputDisabledDirective,
    BlockCopyPasteDirective,
    CpfCnpjDirective,
    DueDateDirective,
    NoLettersDirective,
    NoNumbersDirective,
    NoSpecialCharsDirective,
    ReplaceSpecialCharsDirective,

    // Pipes
    InstallationPipe,
    TextCropperPipe,
    SafePipe
  ],

  entryComponents: [
    EsqueciMatriculaModal,
    InfoAcessoModal,
    InfoEstrangeiroModal,
    AceitarPrivacidadeModal,
    ChatModal
  ],

  providers: [
    FormBuilderTypeSafe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class CoreModule { }

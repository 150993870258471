<span
  class="badge"
  [class.badge-primary]="color === 'primary'"
  [class.badge-secondary]="color === 'secondary'"
  [class.badge-success]="color === 'success'"
  [class.badge-warning]="color === 'warning'"
  [class.badge-danger]="color === 'danger'"
  [class.badge-info]="color === 'info'"
  [class.badge-muted]="color === 'muted'"
>
  {{ label }}
</span>

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  buffer = 0;
  isLoaderActive = false;
  isLocalLoaderActive = false;
  renderer: Renderer2;

  constructor(renderer: RendererFactory2) {
    this.renderer = renderer.createRenderer(null, null);
  }

  setPageLoader(status: boolean) {
    window.scrollTo(0, 0);

    if (status) {
      this.buffer = 0;
      this.renderer.addClass(document.body, 'is-blocked');
    } else {
      this.renderer.removeClass(document.body, 'is-blocked');
    }

    this.isLoaderActive = status;
  }

  getLoaderStatus() {
    return this.isLoaderActive;
  }

  setServiceLoader(status: boolean) {
    if (status) {
      this.buffer++;
      this.isLoaderActive = true;
    } else {
      this.buffer--;
    }

    this.buffer = Math.max(this.buffer, 0);

    if (this.buffer === 0) {
      this.isLoaderActive = false;
      this.renderer.removeClass(document.body, 'is-blocked');
    }
  }

  setLocalLoader(status: boolean) {
    this.isLoaderActive = status;
  }
}

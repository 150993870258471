import { Component, ViewChild } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-economias',
  templateUrl: './economias.html',
  styleUrls: ['./economias.scss']
})
export class EconomiasModal {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  constructor(private dialogRef: MatDialogRef<EconomiasModal>) {}

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

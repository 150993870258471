import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AVLocalStorageService } from '@service/av-localstorage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigatorService } from '@service/navigator.service';
import { ChatModal } from '@modal/modal-chat/chat.modal';

@Component({
  selector: 'inc-atendimento',
  templateUrl: './canais-atendimento.html',
  styleUrls: ['./canais-atendimento.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CanaisAtendimentoInclude implements OnInit {
  @ViewChild('chatModal', { static: true }) chatModal: ChatModal;

  setup: any = {};

  constructor(
    private avLocalStorageService: AVLocalStorageService,
    private navigatorService: NavigatorService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CanaisAtendimentoInclude>
  ) {}

  ngOnInit() {
    this.avLocalStorageService.getUnidadeDetalhes().then((unidadeDetalhes) => {
      if (unidadeDetalhes) {
        this.setup = unidadeDetalhes;
      }
    });
  }

  get linkPhone() {
    let telefone = this.setup?.linkCallCenter
      ? this.setup.linkCallCenter
          .replace(' (fixo)', '')
          .replace(' (celular)', '')
          .replace(' (Celular)', '')
          .split(/ou|;/)
      : [''];

    if (telefone.length === 1) {
      return (
        '<a data-layer="footer-menu-telefone"="true" href="tel:' +
        telefone[0].replace(/[^\d]+/g, '') +
        '">' +
        telefone[0].trim() +
        '</a>'
      );
    }

    if (telefone.length > 1) {
      return telefone
        .map(
          (d) =>
            '<a data-layer="footer-menu-telefone"="true" href="tel:' +
            d.replace(/[^\d]+/g, '') +
            '">' +
            d.trim() +
            '</a>'
        )
        .join(' ou ');
    }

    return 'Disque: <a href="tel:115">115</a>';
  }

  get linkWhatsapp() {
    let link = this.setup?.linkWhatsapp || '',
      telefone = link.replace(/[^\d]+/g, ''),
      mensagem = 'Oi';

    if (link) {
      var splited = telefone.split('');
      if (splited[0] == 5 && splited[1] == 5) {
        return 'https://api.whatsapp.com/send?phone=' + telefone + '&text=' + mensagem;
      } else {
        return 'https://api.whatsapp.com/send?phone=55' + telefone + '&text=' + mensagem;
      }
    }

    return '';
  }

  get linkChatOnline() {
    return this.setup?.linkChatOnline ? true : false;
  }

  private closeModal() {
    if (this.dialogRef?.close) {
      setTimeout(() => { this.dialogRef.close(); }, 0);
    }
  }

  goToChat() {
    this.dialog.open(ChatModal, {
      minWidth: 700,
      maxHeight: 475
    });
    this.closeModal();
  }

  goToWhatsapp() {
    (window as any).open(this.linkWhatsapp, '_blank');
    this.closeModal();
  }

  goToLojas() {
    this.navigatorService.goTo('agencia/lojas');
    this.closeModal();
  }
}

<div class="ui-button-file">
  <button class="btn btn-primary" [disabled]="disabled" (click)="Upload()">
    {{ label }}
    <sup *ngIf="tip">{{ tip }}</sup>
  </button>
  <input
    #elem
    [name]="name"
    type="file"
    [required]="control.errors?.required"
    [inputDisabled]="disabled"
    [formControl]="control"
    [attr.data-layer]="datalayer"
    (keydown.enter)="BlockEnterSubmit($event)"
  />
  <p class="ui-hint" *ngIf="hint">{{ hint }}</p>
  <p class="ui-error" *ngIf="errorMessage">{{ errorMessage }}</p>
</div>
<!-- TODO: Verificar se precisa deste item com o componente de upload novo -->

import { Component, OnInit, ElementRef, ViewChild, Renderer2, Input, OnChanges } from '@angular/core';
import * as jsbarcode from 'jsbarcode';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit, OnChanges {
  @ViewChild('bcElement', { static: true } as any) bcElement: ElementRef;
  @Input('codigo-barras') codigo = '';

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  ngOnChanges() {
    this.createBarcode();
  }

  createBarcode() {
    if (!this.codigo) {
      return;
    }
    let element: Element;
    element = this.renderer.createElement('svg', 'svg');
    jsbarcode(element, this.codigo, { format: 'ITF', displayValue: false });
    for (const node of this.bcElement.nativeElement.childNodes) {
      this.renderer.removeChild(this.bcElement.nativeElement, node);
    }
    this.renderer.appendChild(this.bcElement.nativeElement, element);
  }
}

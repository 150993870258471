import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

import { IDatalayer } from '@model/gtm.resolver';
import { LocalStorageService } from '@service/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private gtmId = environment.gtmId;
  private gtmAuth = environment.gtmAuth;
  private gtmPreview = environment.gtmPreview;

  constructor(private localStorageService: LocalStorageService) {}

  init() {
    console.log('GtmService::init');
    const script = (document as any).createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}&l=dataLayer&gtm_auth=${this.gtmAuth}&gtm_cookies_win=x`;
    (document.head as any).prepend(script);

    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(event: string, param: any) {
      (window as any).dataLayer.push(arguments);
    }

    gtag('js', new Date());
  }

  set(name: string, datalayer: IDatalayer) {
    if ((window as any).dataLayer?.filter((item: any) => item.event == name).length == 0) {
      this.add(name, datalayer);
    }
  }

  add(name: string, datalayer: IDatalayer) {
    (window as any).dataLayer.push({ event: name, datalayer });
  }

  timer(name: string, start: boolean) {
    if (start) {
      const firstTimer = `${Date.now()}`;
      this.localStorageService.set(name, firstTimer);
      return firstTimer;
    } else {
      const lastTimer = this.localStorageService.get(name) * 1;
      return `${Date.now() - lastTimer}`;
    }
  }
}

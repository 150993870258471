<div
  [class]="'cardPlaceholder ' + bank"
  [ngStyle]="{ transform: 'translateX(-50%) scale(' + miniCard + ')' }"
  [class.creditCardMastercard]="type == 'CREDITO' && flagCard('mastercard')"
  [class.creditCardVisa]="type == 'CREDITO' && flagCard('visa')"
  [class.creditCardElo]="type == 'CREDITO' && flagCard('elo')"
  [class.creditCardHipercard]="type == 'CREDITO' && flagCard('hipercard')"
  [class.debitCardMaestro]="type == 'DEBITO' && flagCard('mastercard')"
  [class.debitCardVisaElectron]="type == 'DEBITO' && flagCard('visa')"
  [class.debitCardElo]="type == 'DEBITO' && flagCard('elo')"
>
  <img class="cardAegea" src="/assets/cards/aegea.svg" />
  <img *ngIf="bank === 'caixa-tem'" class="cardEloCaixa" src="/assets/cards/elo-caixa.svg" />

  <img class="cardMastercard" src="/assets/cards/mastercard.svg" />
  <img class="cardVisa" src="/assets/cards/visa.svg" />
  <img class="cardEloCredito" src="/assets/cards/elo-credito.svg" />
  <img class="cardHipercard" src="/assets/cards/hipercard.svg" />

  <img class="cardMaestro" src="/assets/cards/maestro.svg" />
  <img class="cardVisaElectron" src="/assets/cards/visaelectron.svg" />

  <span *ngIf="displayNumber" class="cardPaymentDataCardNumber">
    {{ showNumber }}
  </span>

  <span *ngIf="!displayNumber" class="cardPaymentDataCardNumber">
    {{ maskedNumber }}
  </span>

  <span
    class="cardPaymentDataCardName"
    [class.holderNameLarger]="holderNameCard().length <= 16"
    [class.holderNameMedium]="holderNameCard().length > 16 && holderNameCard().length <= 20"
    [class.holderNameSmall]="holderNameCard().length > 20"
  >
    {{ holderNameCard() }}
  </span>

  <span *ngIf="securityCodeCard()" class="cardPaymentDataCardSecurityCode">
    {{ securityCodeCard() }}
  </span>

  <span *ngIf="dueDate" class="cardPaymentDataCardDueDate">
    {{ dueDate | mask: '00/00' }}
  </span>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { RealtyResponse } from '@model/imovel.resolver';
import { Store } from '@ngrx/store';
import { AegeaStore } from 'store/aegea.store';
import { MatriculasAegeaStore } from 'store/matriculas/matriculas.reducer';

@Injectable({
  providedIn: 'root'
})
export class ImovelService {

  private dadosReferenciais: MatriculasAegeaStore;

  constructor(store: Store<AegeaStore>, private httpService: HttpService) {
    store.select('matriculas').subscribe((result) => this.dadosReferenciais = result)
  }

  async getInstalacaoLogada(): Promise<RealtyResponse> {
    let installation = this.dadosReferenciais.matricula_selecionada;

    if (installation) {
      return this.httpService
        .sendGetRequest(apiConfig.imovel.instalacao(installation))
        .toPromise()
        .then(({ body }) => body);
    }
  }

  getCurrentInstallation(successCallbackAction): RealtyResponse {
    let installation = this.dadosReferenciais.matricula_selecionada;
    if (installation) {
      return this.httpService
        .sendGetRequest(apiConfig.imovel.instalacao(installation))
        .pipe(
          map((response) => {
            const responseBody = (<any>response).body;
            const realtyResponse = <RealtyResponse>responseBody;
            return realtyResponse;
          })
        )
        .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
    }
  }

  getRealtyInstallations(successCallbackAction): Array<RealtyResponse> {
    return this.httpService
      .sendGetRequest(apiConfig.home.imobiliaria)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getUserInstalations(successCallbackAction): Array<RealtyResponse> {
    return this.httpService
      .sendGetRequest(apiConfig.home.matriculas)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
  }

  async getUserInstalationPromise() {
    return this.httpService.sendGetRequest(apiConfig.home.matriculas).toPromise();
  }
}

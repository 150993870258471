import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService, PlatformInfo } from '@service/environment.service';
import { IListaAnexos, UploadService } from '@service/upload.service';
import { LoaderService } from '@service/loader.service';
import { ToastrService } from 'ngx-toastr';
import Compressor from 'compressorjs';

@Component({
  selector: 'modal-camera',
  templateUrl: './camera.html',
  styleUrls: ['./camera.scss']
})
export class CameraModal {
  @ViewChild('specificContent', { static: true }) cameraModal: ModalLayout;
  @ViewChild('video') video: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  documento: IListaAnexos;
  options: CameraOptions;
  defaultPhoto: string = '/assets/placeholders/camera.png';
  soundPhoto: string = '/assets/sounds/camera.mp3';
  file: any = null;
  photo: any = null;
  photoBlob: any = null;
  fotoTirada: boolean = false;
  stream: MediaStream;

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { item },
    private camera: Camera,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private dialogRef: MatDialogRef<CameraModal>,
    private envService: EnvironmentService
  ) {
    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });

    this.documento = data.item;

    this.options = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      saveToPhotoAlbum: false,
      allowEdit: false,
      targetWidth: 1024,
      targetHeight: 1280
    };
  }

  ngOnInit() {
    this.resetFoto();

    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;

      if (this.platformInfo.isMobile) {
        this.tirarFoto();
      }
    });
  }

  async ngAfterViewInit() {
    this.envService.platformInfo().then(async (d) => {
      this.platformInfo = d;

      if (this.platformInfo.isDesktop && 'mediaDevices' in navigator) {
        await navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          this.stream = stream;
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
        });
      }
    });
  }

  async tirarFoto() {
    if (this.platformInfo.isMobile) {
      await this.camera.getPicture(this.options).then(imageData => {
          this.photo = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imageData);
          this.photoBlob = this.uploadService.convertBase64ToBlob('data:image/jpg;base64,' + imageData);
          this.fotoTirada = true;
        },
        (err) => console.error(err)
      );
    }

    if (this.platformInfo.isDesktop) {
      this.loaderService.setPageLoader(true);

      let audio = new Audio();
      audio.src = this.soundPhoto;
      audio.load();
      audio.play();

      this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 640, 480);
      this.photo = this.canvas.nativeElement.toDataURL('image/jpeg');
      this.photoBlob = this.uploadService.convertBase64ToBlob(this.photo);

      new Promise((resolve, reject) => {
        new Compressor(this.photoBlob, {
          quality: 0.8,
          mimeType: 'image/jpg',
          convertTypes: ['image/jpeg', 'image/png'],
          convertSize: 2_000_000,
          success: resolve,
          error: reject
        });
      })
      .then((result: Blob) => {
        this.photoBlob = result;
        this.fotoTirada = true;
        this.loaderService.setPageLoader(false);
      })
      .catch((err) => {
        this.loaderService.setPageLoader(false);
        console.error(`[Compressor error]: ${err.message}`);
      });
    }
  }

  resetFoto() {
    this.photo = this.defaultPhoto;

    if (this.platformInfo.isMobile) {
      this.photoBlob = null;
      this.fotoTirada = false;
    } else {
      this.fotoTirada = false;
    }
  }

  async enviarFoto() {
    if (this.platformInfo.isDesktop && this.stream?.getTracks) {
      this.stream.getTracks()[0].stop();
    }

    let filetype = 'image/jpg';

    if (this.platformInfo.isDesktop && this.photoBlob && this.file?.type) {
      await this.uploadService.convertBlobToBase64(this.photoBlob).then((base64) => {
        const type = this.file.type?.type || this.file.type;
        filetype = type ? type.replace(/.*\//, '') : '';
        this.photo = this.sanitizer.bypassSecurityTrustResourceUrl(`${base64}`);
      });
    }

    if (this.platformInfo.isDesktop) {
      this.file = this.uploadService.convertBlobToFile(this.photoBlob, this.documento.filename);

      if (this.file && this.documento?.filetypes) {
        const invalidFormat = !this.uploadService.validFileformat(this.file, this.documento.filetypes);
        if (invalidFormat === true) {
          const formats = this.documento.filetypes.join(', ')?.replace(/,(?=[^,]*$)/, ' ou');
          this.toasterService.error(`Tente enviar arquivos do tipo ${formats.replace(/.*\//, '')}`);
          setTimeout(() => { this.dialogRef.close(); }, 0);
        }
      }
    }

    if (this.file && this.documento?.filesize) {
      const invalidSize = !this.uploadService.validFilesize(this.file, this.documento.filesize);

      if (invalidSize === true) {
        const maxMB = Math.round(this.documento.filesize / 1024 / 1024);
        this.toasterService.error(`Tente enviar arquivos com até ${maxMB}MB`);
        setTimeout(() => { this.dialogRef.close(); }, 0);
      }
    }

    this.documento.error = false;
    this.documento.done = true;
    this.documento.source = this.photo;
    this.documento.filetype = filetype;

    this.dialogRef.close({
      arquivo: this.blobToFile(this.photoBlob),
      documento: this.documento
    });
  }

  private blobToFile(blob: Blob): File {
    var blobFile: any = blob;
    blobFile.lastModifiedDate = new Date();
    blobFile.name = `anexo-${Date.now()}.JPG`;
    return <File>blob;
  }

  close() {
    if (this.platformInfo.isDesktop && this.stream?.getTracks) {
      this.stream.getTracks()[0].stop();
    }

    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

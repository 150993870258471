import { Component, ViewChild, Inject } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeiTarifaSocial } from '@model/forms/servico-tarifa-social.form';

@Component({
  selector: 'modal-lei-duvida',
  templateUrl: './lei-duvida.html',
  styleUrls: ['./lei-duvida.scss']
})
export class LeiDuvidaModal {
  @ViewChild('specificContent', { static: true }) leiDuvida: ModalLayout;

  lei: LeiTarifaSocial;

  constructor(@Inject(MAT_DIALOG_DATA) data: { item },
    private dialogRef: MatDialogRef<LeiDuvidaModal>
  ) {
    this.lei = data.item;
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

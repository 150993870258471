import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MatDialogRef } from '@angular/material/dialog';
import { AVLocalStorageService } from '@service/av-localstorage.service';
import { NavigatorService } from '@service/navigator.service';

@Component({
  selector: 'modal-chat',
  templateUrl: './chat.html',
  styleUrls: ['./chat.scss']
})
export class ChatModal implements OnInit {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  chatLink: string;

  constructor(
    private navigatorService: NavigatorService,
    private avLocalStorageService: AVLocalStorageService,
    private dialogRef: MatDialogRef<ChatModal>
  ) {}

  ngOnInit() {
    this.avLocalStorageService.getUnidadeDetalhes().then((unidadeDetalhes) => {
      this.chatLink = unidadeDetalhes.linkChatOnline;
    });
  }

  confirmar() {
    this.dialogRef.close(true);
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IListaAnexos, UploadService } from '@service/upload.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '@service/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import Compressor from 'compressorjs';
import { EnvironmentService } from '@service/environment.service';

@Component({
  selector: 'modal-upload',
  templateUrl: './upload.html',
  styleUrls: ['./upload.scss']
})
export class UploadModal implements OnInit {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  arquivo: File;
  documento: IListaAnexos;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { file: File; item: IListaAnexos },
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<UploadModal>,
    private uploadService: UploadService,
    private envService: EnvironmentService
  ) {
    this.arquivo = data.file;
    this.documento = data.item;
    this.loaderService.setPageLoader(true);

    if (this.arquivo.type?.includes('image')) {
      this.envService.isMobile().then(isMobile => {
        if (isMobile === true) {
          this.dialogRef.close({ arquivo: this.arquivo, documento: this.documento });
          return;
        } else {
          new Promise((resolve, reject) => {
            new Compressor(this.arquivo, {
              quality: 0.8,
              mimeType: 'image/jpg',
              convertTypes: ['image/jpeg', 'image/png'],
              convertSize: 2_000_000,
              success: resolve,
              error: reject
            });
          })
          .then((result: Blob) => {
            this.uploadService.convertBlobToBase64(result).then((base64) => {
              this.documento.filetype = this.arquivo?.type || '';
              this.documento.source = this.sanitizer.bypassSecurityTrustResourceUrl(`${base64}`);
              this.loaderService.setPageLoader(false);
            });
          })
          .catch((err) => {
            console.error(`[Compressor error]: ${err.message}`);
          });
        }
      });
    }
    else {

      this.uploadService.convertFileToBase64(this.arquivo).then((base64) => {
        this.documento.filetype = this.arquivo?.type || '';
        this.documento.source = this.sanitizer.bypassSecurityTrustResourceUrl(`${base64}`);
        this.loaderService.setPageLoader(false);
      })
    }
  }

  ngOnInit() {
    const invalidFormat = !this.uploadService.validFileformat(this.arquivo, this.documento.filetypes);
    const invalidSize = !this.uploadService.validFilesize(this.arquivo, this.documento.filesize);

    if (invalidFormat) {
      const formats = this.documento.filetypes?.join(', ')?.replace(/,(?=[^,]*$)/, ' ou');
      this.toasterService.error(`Tente enviar arquivos do tipo ${formats.replace(/.*\//, '')}`);
      this.dialogRef.close();
    }

    if (invalidSize) {
      const maxMB = Math.round(this.documento.filesize / 1024 / 1024);
      this.toasterService.error(`Tente enviar arquivos com até ${maxMB}MB`);
      this.dialogRef.close();
    }
  }

  enviarFoto() {
    this.documento.error = false;
    this.documento.done = true;
    this.dialogRef.close({ arquivo: this.arquivo, documento: this.documento });
  }

  close() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { AVLocalStorageService } from '@service/av-localstorage.service';

@Component({
  selector: 'ui-full-banner',
  templateUrl: './full-banner.component.html',
  styleUrls: ['./full-banner.component.scss']
})
export class FullBannerComponent implements OnInit {
  @Input() hasLink: any;
  bannerUrl: string;

  constructor(private avLocalStorageService: AVLocalStorageService) {}

  ngOnInit() {
    this.avLocalStorageService.getUnidadeDetalhes().then((unidadeDetalhes) => {
      this.bannerUrl = unidadeDetalhes?.urlBanner || '';
    });
  }
}

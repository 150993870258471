<layout-modal #specificContent>
  <div class="modal-head">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">{{ documento.title }}</h2>
        <p class="title">{{ documento.description }}</p>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="documento.filename === 'rne_cie_frente'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rne-frente.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo da frente do RNE/CIE</h6>
          <p>
            Enviar em anexo a frente do documento para aprovar de forma legível a assinatura comprovando a autenticidade
            da assinatura nos demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-disclaimer">
        <div class="col-sm-12">
          <dl>
            <dt>Considerações</dt>
            <dd>
              <ul>
                <li>
                  Serão aceitos anexos de <strong>documentos digitais de RG e CNH</strong> emitidos pelos aplicativos dos orgãos
                  emissores.
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'comprovante_pagamento'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/comprovante-pagamento.jpeg" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo de comprovante de pagamento</h6>
          <p>Enviar em anexo o comprovante de pagamento para aprovar a autenticidade da transação.</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'rne_cie_verso'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rne-verso.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo do verso do RNE/CIE</h6>
          <p>
            Enviar em anexo o verso do documento com data de expedição máxima de 10 anos com número de RG e/ou CPF
            legíveis.
          </p>
        </div>
      </div>

      <div class="row row-disclaimer">
        <div class="col-sm-12">
          <dl>
            <dt>Considerações</dt>
            <dd>
              <ul>
                <li>
                  Serão aceitos anexos de <strong>documentos digitais de RG e CNH</strong> emitidos pelos aplicativos dos orgãos
                  emissores.
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'rg_cnh_frente'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rg-frente.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo da frente do RG</h6>
          <p>
            Enviar em anexo a frente do documento para aprovar de forma legível a assinatura comprovando a autenticidade
            da assinatura nos demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rg-novo-frente.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo da frente do novo RG</h6>
          <p>
            A frente do novo documento também tem a assinatura, enviar como imagem com a assinatura legível para
            avaliação com demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/cnh-frente.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo da frente da CNH</h6>
          <p>
            Enviar em anexo a frente do documento dentro da data de validade para aprovar número de CPF a ser comprovado
            nos demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-disclaimer">
        <div class="col-sm-12">
          <dl>
            <dt>Considerações</dt>
            <dd>
              <ul>
                <li>
                  Serão aceitos anexos de <strong>documentos digitais de RG e CNH</strong> emitidos pelos aplicativos dos orgãos
                  emissores.
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'rg_cnh_verso'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rg-verso.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo do verso do RG</h6>
          <p>
            Enviar em anexo o verso do documento com data de expedição máxima de 10 anos com número de RG e/ou CPF
            legíveis.
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/rg-novo-verso.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo do verso do novo RG</h6>
          <p>
            O verso do novo documento também possui a data de expedição e número do RG e/ou CPF legíveis para avaliação
            com demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/cnh-verso.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo do verso da CNH</h6>
          <p>
            Enviar em anexo o verso do documento para aprovar de forma legível a assinatura comprovando a autenticidade
            da assinatura nos demais documentos anexados.
          </p>
        </div>
      </div>

      <div class="row row-disclaimer">
        <div class="col-sm-12">
          <dl>
            <dt>Considerações</dt>
            <dd>
              <ul>
                <li>
                  Serão aceitos anexos de <strong>documentos digitais de RG e CNH</strong> emitidos pelos aplicativos dos orgãos
                  emissores.
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'cnpj_frente'">
      <div class="row row-title mb-3">
        <div class="col-sm-12">
          <img src="/assets/documentos/inscricao-cnpj.png" />
        </div>

        <div class="col-sm-12">
          <h6>Exemplo do comprovante de CNPJ</h6>
          <p>
            Enviar anexo do comprovante de inscrição e situação cadastral do CNPJ para comprovação nos demais documentos
            anexados.
          </p>
        </div>
      </div>

      <div class="row row-disclaimer">
        <div class="col-sm-12">
          <dl>
            <dt>Considerações</dt>
            <dd>
              <ul>
                <li>Este comprovante pode ser emitido através do site da <strong>Receita Federal</strong></li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'contrato_compra_venda_frente'">
      <div class="row row-title mb-3">
        <div class="col-sm-12">
          <img src="/assets/documentos/contrato-compra-venda.png" />
        </div>

        <div class="col-sm-12">
          <h6>Exemplo da frente do contrato</h6>
          <p>
            Enviar anexo do comprovante de compra e venda do imóvel, com os <strong>dados legíveis do comprador</strong> para
            comprovar com os demais documentos anexados.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'contrato_locacao_frente'">
      <div class="row row-title mb-3">
        <div class="col-sm-12">
          <img src="/assets/documentos/contrato-locacao.png" />
        </div>

        <div class="col-sm-12">
          <h6>Exemplo da frente do contrato</h6>
          <p>
            Enviar anexo do contrato de locação do imóvel, com os <strong>dados legíveis do locador e locatário</strong> para
            comprovar com os demais documentos anexados.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="documento.filename === 'contrato_compra_venda_verso' || documento.filename === 'contrato_locacao_verso'"
    >
      <div class="row row-title mb-3">
        <div class="col-sm-12">
          <img src="/assets/documentos/contrato-assinatura.png" />
        </div>

        <div class="col-sm-12">
          <h6>Exemplo do verso do contrato</h6>
          <p>
            Enviar anexo do verso do contrato, com as <strong>assinaturas legíveis</strong>, autenticadas ou não, para comprovar
            com os demais documentos anexados.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'comprovante_iptu_frente'">
      <div class="row row-title mb-3">
        <div class="col-sm-12">
          <img src="/assets/documentos/iptu.png" />
        </div>

        <div class="col-sm-12">
          <h6>Exemplo do IPTU</h6>
          <p>
            Enviar anexo do último IPTU do ano vigente, com o <strong>endereço</strong> para avaliação dos demais documentos
            anexados.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'cartao_beneficiario_frente'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/cartao-bolsa-familia.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Exemplo do cartão do benefício</h6>
          <p>
            Enviar anexo o cartão do programa Bolsa Família, com o <strong>nome legível</strong> para avaliação dos demais
            documentos anexados.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'foto_vazamento'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/vazamento-bom.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto boa</h6>
          <p>
            Enquadrando o problema do vazamento, mostrando o entorno na foto, para fácil identificação do local exato do
            vazamento.
          </p>
          <p>
            <small>Fonte: aegea.blog.br</small>
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/vazamento-ruim.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto ruim</h6>
          <p>
            Enquadrando o vazamento sem mostrar o entorno, não facilita a identificação do local exato do vazamento.
          </p>
          <p>
            <small>Fonte: aegea.blog.br</small>
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'denuncia'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/denuncia-bom.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto boa</h6>
          <p>
            Enquadrando o local denunciado, mostrando o entorno na foto, para fácil identificação do local exato da
            denúncia.
          </p>
          <p>
            <small>Fonte: prolagos.com.br</small>
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/denuncia-ruim.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto ruim</h6>
          <p>Enquadrando o local denunciado, mas sem mostrar o entorno na foto, não facilita a identificação.</p>
          <p>
            <small>Fonte: aguasdeteresina.com.br</small>
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="documento.filename === 'servico_esgoto'">
      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/tampa-bom.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto boa</h6>
          <p>
            Enquadrando a tampa, mostrando o entorno na foto, para fácil identificação do local exato do esgoto com
            problema.
          </p>
          <p>
            <small>Fonte: aegea.blog.br</small>
          </p>
        </div>
      </div>

      <div class="row row-title mb-3">
        <div class="col-md-7 col-sm-12">
          <img src="/assets/documentos/tampa-ruim.png" />
        </div>

        <div class="col-md-5 col-sm-12">
          <h6>Foto ruim</h6>
          <p>
            Enquadrando a tampa sem mostrar o entorno, não facilita a identificação do local exato em que se encontra o
            esgoto com problema.
          </p>
          <p>
            <small>Fonte: miranteppp.com.br</small>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</layout-modal>

<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">{{documento.title}}</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p class="text-center">
          <img *ngIf="documento.filetype?.includes('image')" [src]="documento.source" />
          <iframe *ngIf="documento.filetype?.includes('pdf')" [src]="documento.source"></iframe>
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row row-float-button">
      <div class="col-sm-12">
        <button
          matTooltip="Cancelar envio"
          matTooltipPosition="above"
          data-layer="upload-foto-cancelada"
          class="btn-danger"
          (click)="close()"
        >
          <ui-icon icon="close"></ui-icon>
        </button>

        <button
          matTooltip="Enviar"
          matTooltipPosition="above"
          data-layer="upload-foto-confirmada"
          class="btn btn-success"
          (click)="enviarFoto()"
        >
          <ui-icon icon="done"></ui-icon>
        </button>
      </div>
    </div>
  </div>
</layout-modal>

export const gtmConfig = {
  id: 'GTM-5RZR3KB',

  localhost: {
    auth: 'JX-p4ZyRGAgBYG1T-_lw5w',
    preview: 'env-5'
  },

  development: {
    auth: 'AVlVTdfjbhwavfqJusNq5g',
    preview: 'env-3'
  },

  homologation: {
    auth: '_vjrQyDdXwET-Kc5a-gHfw',
    preview: 'env-4'
  },

  production: {
    auth: 'VT1sn9qZ9NzaXfgl7joOpA',
    preview: 'env-1'
  }
};

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { CepValidator } from '@validator/cep.validator';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ui-input-cep',
  templateUrl: './input-cep.component.html',
  styleUrls: ['./input-cep.component.scss']
})
export class InputCepFormComponent implements OnInit {
  private validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'CEP';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = '00000-000';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'CEP inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.validators.push(Validators.minLength(9));
    this.validators.push(Validators.maxLength(9));
    this.validators.push(CepValidator);

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return 'Informe o CEP';
    }

    if (this.control.hasError('minlength') || this.control.hasError('maxlength')) {
      return this.invalidMessage;
    }

    return this.control.hasError('validateCep') ? this.invalidMessage : '';
  }
}

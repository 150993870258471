import { createReducer, on, Action } from '@ngrx/store';
import * as EnvironmentActions from './environment.actions';

export enum ENV_NAME {
  DEV = 'dev',
  HML = 'hml',
  PROD = 'prod',
  OUTRO = 'outro'
}

export interface EnvironmentAegeaStore {
  env: string;
}

const _baseReducer = createReducer<EnvironmentAegeaStore>(
  { env: ENV_NAME.PROD },
  on(EnvironmentActions.SetEnvironment, (state, prop) => {
    let env: ENV_NAME;

    switch (prop.data) {
      case ENV_NAME.DEV:
        env = ENV_NAME.DEV;
        break;

      case ENV_NAME.HML:
        env = ENV_NAME.HML;
        break;

      case ENV_NAME.PROD:
        env = ENV_NAME.PROD;
        break;

      default:
        env = ENV_NAME.OUTRO;
    }
    return { ...state, env };
  })
);

export function baseReducer(state: EnvironmentAegeaStore | undefined, action: Action) {
  return _baseReducer(state, action);
}

import { Injectable, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(
    private router: Router,
    public location: Location,
    private zone: NgZone
  ) { }

  goTo(page: string, extra: NavigationExtras = {}) {
    this.zone.run(() => {
      this.router.navigate([page], extra);
    });
  }

  cleanURL(route: string) {
    return route.split('?')[0];
  }
}

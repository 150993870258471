import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss']
})
export class CardPreviewComponent implements OnInit {
  @Input() type: string;
  @Input() bank: string;
  @Input() flag: string;
  @Input() number: string;
  @Input() holderName: string = '';
  @Input() securityCode: string;
  @Input() dueDate: string;

  displayNumber: boolean = true;
  maskedNumber: string;
  miniCard: number;

  constructor() {}

  ngOnInit() {
    this.numberCard();

    const miniCard = () => {
      this.miniCard = Math.min(1, (window as any).innerWidth / 425);
    };

    miniCard();
    (window as any).onresize = miniCard;
  }

  flagCard(flag: string) {
    return this.flag ? this.flag.toLowerCase() === flag : this.flag;
  }

  get showNumber() {
    return this.number
      ? this.number
          .replace(/(\d{4}).{2}/, '$1 •')
          .replace(/(\d{4}).{3}/, '$1 ••')
          .replace(/(\d{4}).{4}/, '$1 •••')
          .replace(/(\d{4}).{5}/, '$1 ••••')
          .replace(/(\d{4}).{7}/, '$1 •••• •')
          .replace(/(\d{4}).{8}/, '$1 •••• ••')
          .replace(/(\d{4}).{9}/, '$1 •••• •••')
          .replace(/(\d{4}).{10}/, '$1 •••• ••••')
      : this.number;
  }

  numberCard() {
    if (this.number && this.number.indexOf('*') >= 0) {
      this.displayNumber = false;
      this.maskedNumber = this.number.replace(/\*/g, '•');
    }
  }

  holderNameCard() {
    if (this.holderName) {
      return this.holderName
        .replace(/[àáäâã]/g, 'a')
        .replace(/[ÀÁÄÂÃ]/g, 'A')
        .replace(/[èéëê]/g, 'e')
        .replace(/[ÈÉËÊ]/g, 'E')
        .replace(/[ìíïî]/g, 'i')
        .replace(/[ÌÍÏÎ]/g, 'I')
        .replace(/[òóöõô]/g, 'o')
        .replace(/[ÒÓÖÕÔ]/g, 'O')
        .replace(/[ùúüû]/g, 'u')
        .replace(/[ÙÚÜÛ]/g, 'U')
        .replace(/[ç]/g, 'c')
        .replace(/[Ç]/g, 'C')
        .replace(/[ñ]/g, 'n')
        .replace(/[Ñ]/g, 'N')
        .slice(0, 26);
    }

    return '';
  }

  securityCodeCard(): string {
    return this.securityCode ? this.securityCode : '•••';
  }
}

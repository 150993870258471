import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { UploadModal } from '@modal/modal-upload/upload.modal';
import { CameraModal } from '@modal/modal-camera/camera.modal';
import { DocumentoDuvidaModal } from '@modal/modal-documento-duvida/documento-duvida.modal';
import { EnvironmentService, PlatformInfo } from '@service/environment.service';
import { CategoriaAnexo, IEnvioAnexos, IListaAnexos, UploadService } from '@service/upload.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-upload',
  templateUrl: './ui-upload.component.html',
  styleUrls: ['./ui-upload.component.scss']
})
export class UploadComponent implements OnInit {
  private subscribers: Subscription[] = [];

  @ViewChild('stepper') public stepper: MatStepper;

  @Input() dataLayer: string;
  @Input() listaAnexos: IListaAnexos[] = [];
  @Input() categoriaAnexo: CategoriaAnexo;

  @Output() completedUpload: EventEmitter<IEnvioAnexos[]> = new EventEmitter();
  @Output() removedUpload: EventEmitter<IListaAnexos> = new EventEmitter();

  cameras: boolean[] = [];
  uploads: IEnvioAnexos[] = [];

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(
    private dialog: MatDialog,
    private uploadService: UploadService,
    private envService: EnvironmentService,
    private toasterService: ToastrService
  ) {}

  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe());
  }

  async ngOnInit() {
    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;

      if (this.platformInfo.isDesktop) {
        navigator?.mediaDevices?.enumerateDevices().then(devices => {
          devices.some(device => {
            this.cameras.push('videoinput' === device.kind)
          })
        });
      }
      else {
        this.cameras.push(true);
      }
    });
  }

  slugify(string: string) {
    return string.replace(/_/g, '-');
  }

  duvidas(index: number) {
    const item: IListaAnexos = this.listaAnexos[index];

    this.dialog.open(DocumentoDuvidaModal, {
      maxWidth: 700,
      data: { item }
    });
  }

  imagem(event: Event, index: number) {
    const file = (event.target as HTMLInputElement).files[0];
    const item: IListaAnexos = this.listaAnexos[index];

    const dialogUpload = this.dialog.open(UploadModal, {
      minWidth: 350,
      maxWidth: 700,
      data: { file, item }
    });

    this.subscribers.push(
      dialogUpload.afterClosed().subscribe((result) => {
        this.enviar(result);
      })
    );
  }

  foto(index: number) {
    const item: IListaAnexos = this.listaAnexos[index];

    const dialogFoto = this.dialog.open(CameraModal, {
      minWidth: 700,
      data: { item }
    });

    this.subscribers.push(
      dialogFoto.afterClosed().subscribe((result) => {
        this.enviar(result);
      })
    );
  }

  resetFoto(index: number) {
    const item: IListaAnexos = this.listaAnexos[index];
    item.done = false;
    item.error = false;
    item.source = '';
    this.removedUpload.emit(item);
  }

  typeImage(filetype: string) {
    return filetype.indexOf('jpg') >= 0 || filetype.indexOf('jpeg') >= 0 || filetype.indexOf('png') >= 0;
  }

  typePdf(filetype: string) {
    return filetype.indexOf('pdf') >= 0;
  }

  private enviar(item) {
    if (item) {
      this.uploadService.upload(
        [item.arquivo],
        this.categoriaAnexo,
        (successResponse) => {
          item.documento.done = true;
          item.documento.error = false;
          this.step++;

          this.uploads.push({
            order: item.documento.order,
            title: item.documento.title,
            path: successResponse.body[0]
          })

          this.uploads = this.uploads
          .sort((a, b) => a.order - b.order)

          this.completedUpload.emit(this.uploads);
        },
        () => {
          item.documento.done = false;
          item.documento.error = true;
          this.toasterService.error('Não foi possível enviar o comprovante');
        }
      );
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

/** @title Form field with error messages */
@Component({
  selector: 'ui-input-email',
  templateUrl: 'input-email.component.html',
  styleUrls: ['input-email.component.scss']
})
export class InputEmailFormComponent implements OnInit {
  protected validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'E-mail';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Informe o e-mail';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() blockCopyPaste: boolean = false;
  @Input() invalidMessage: string = 'E-mail inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.blockCopyPaste && this.elem) {
      this.renderer.setAttribute(this.elem.nativeElement, 'blockCopyPaste', 'true');
    }

    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.validators.push(Validators.email);

    this.control.setValidators(this.validators);

    this.control.valueChanges
      .pipe(debounceTime(300))
      .subscribe((newValue) => this.onNewValue.emit(newValue?.toLowerCase()));
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }

    return this.control.hasError('email') ? this.invalidMessage : '';
  }
}

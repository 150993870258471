<div #elem>
  <mat-form-field>
    <mat-label>
      {{ label }}
      <sup *ngIf="tip">{{ tip }}</sup>
    </mat-label>
    <mat-select
      [required]="control.errors?.required"
      [inputDisabled]="disabled"
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.data-layer]="datalayer"
      (change)="Change()"
      (keydown.enter)="BlockEnterSubmit($event)"
    >
      <mat-option *ngFor="let item of options; trackBy: trackByFunction" [value]="item.value">
        {{ item.label }}
      </mat-option>
    </mat-select>
    <mat-error>{{ errorMessage }}</mat-error>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>

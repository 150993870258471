import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  Renderer2
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

export interface OptionsSelect {
  label: string;
  value: string;
}

@Component({
  selector: 'ui-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteFormComponent implements OnInit {
  protected validators = [];

  @Optional()
  @Output() onNewValue = new EventEmitter<string>();
  @Output() onChange: EventEmitter<VoidFunction> = new EventEmitter();
  @Output() onClear: EventEmitter<VoidFunction> = new EventEmitter();

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Lista';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Selecione um item';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Nenhum item selecionado';
  @Input() initialValue: string = '';
  @Input() options: Observable<OptionsSelect[]>;
  @Input() autoFocus: boolean = false;

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.initialValue) {
      this.control.setValue(this.initialValue);
    }

    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.control.setValidators(this.validators);
    this.control.valueChanges.subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  trackByFunction(index: number) {
    return index;
  }

  blockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  change() {
    this.onChange.emit();
  }

  clear(event: InputEvent) {
    if (this.elem) {
      event.stopPropagation();
      event.preventDefault()
      this.control.setValue(undefined)
    }
    this.onClear.emit();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ui-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyFormComponent implements OnInit {
  private validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Valor';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() placeholder: string = 'Informe o valor';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Valor inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();
  @Output() onBlur = new EventEmitter();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    if (this.maxValue != null) {
      this.validators.push(Validators.max(this.maxValue));
    }

    if (this.minValue != null) {
      this.validators.push(Validators.min(this.minValue));
    }

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
      this.onNewValue.emit(newValue);
    });
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Blur() {
    this.onBlur.emit(this.control.value);
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }
  }
}

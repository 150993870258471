import { IShortcuts } from '@include/inc-atalhos/atalhos.include';

export const shortcutsConfig: IShortcuts[] = [
  {
    datalayer: 'atalho-pagar-conta',
    icon: 'payment',
    label: 'Pagar fatura',
    color: null,
    show: null,
    link: '/fatura/pagar-fatura'
  },
  {
    datalayer: 'atalho-2-via',
    icon: 'file_copy',
    label: 'Segunda via',
    color: null,
    show: null,
    link: '/fatura/pagar-fatura'
  },
  {
    datalayer: 'atalho-parcelar-fatura',
    icon: 'pie_chart',
    label: 'Parcelar fatura',
    color: null,
    show: null,
    link: '/parcelamento'
  },
  {
    datalayer: 'atalho-debito-automatico',
    icon: 'account_balance_wallet',
    label: 'Débito automático',
    color: null,
    show: null,
    link: '/pagamento/debito-automatico'
  },
  {
    datalayer: 'atalho-conta-por-email',
    icon: 'email',
    label: 'Fatura por e-mail',
    color: null,
    show: null,
    link: '/fatura/fatura-por-email'
  },
  {
    datalayer: 'atalho-certidao-negativa',
    icon: 'verified',
    label: 'Certidão negativa',
    color: null,
    show: null,
    link: '/fatura/certidao-negativa'
  },
  {
    datalayer: 'menu-qualidade-agua',
    icon: 'local_drink',
    label: 'Qualidade da água',
    color: null,
    show: null,
    link: '/fatura/qualidade-agua'
  },
  {
    datalayer: 'atalho-denuncia',
    icon: 'campaign',
    label: 'Denúncia',
    color: null,
    show: null,
    link: '/servico/denuncia'
  },
  {
    datalayer: 'atalho-falta-agua',
    icon: 'format_color_reset',
    label: "Falta d'água",
    color: null,
    show: null,
    link: '/servico/falta-agua'
  },
  {
    datalayer: 'atalho-esgoto-entupido',
    icon: 'plumbing',
    label: 'Esgoto entupido',
    color: null,
    show: null,
    link: '/servico/esgoto-entupido'
  },
  {
    datalayer: 'atalho-vazamento',
    icon: 'water_damage',
    label: 'Vazamento',
    color: null,
    show: null,
    link: '/servico/vazamento'
  },
  {
    datalayer: 'atalho-ordem-servico',
    icon: 'find_in_page',
    label: 'Ordem de serviço',
    color: null,
    show: null,
    link: '/ordem-servico/lista'
  },
  {
    datalayer: 'atalho-ligacao-esgoto',
    icon: 'houseboat',
    label: 'Ligação de esgoto',
    color: null,
    show: null,
    link: '/servico/ligacao-esgoto'
  },
  {
    datalayer: 'atalho-esgoto',
    icon: 'opacity',
    label: 'Serviço de esgoto',
    color: null,
    show: null,
    link: '/servico/esgoto'
  },
  {
    datalayer: 'atalho-ligacao-agua',
    icon: 'water_drop',
    label: 'Ligação de água',
    color: null,
    show: null,
    link: '/servico/ligacao-agua'
  },
  {
    datalayer: 'atalho-trocar-titularidade',
    icon: 'how_to_reg',
    label: 'Trocar titularidade',
    color: null,
    show: null,
    link: '/servico/trocar-titularidade'
  },
  {
    datalayer: 'atalho-alterar-cadastro',
    icon: 'edit_note',
    label: 'Alterar cadastro',
    color: null,
    show: null,
    link: '/perfil/alterar-cadastro'
  },
  {
    datalayer: 'atalho-pedido-corte',
    icon: 'content_cut',
    label: 'Pedido de corte',
    color: null,
    show: null,
    link: '/servico/pedido-corte'
  },
  {
    datalayer: 'atalho-mudanca-local',
    icon: 'restore',
    label: 'Mudança de local',
    color: null,
    show: null,
    link: '/servico/mudar-local-hidrometro'
  },
  {
    datalayer: 'atalho-religacao',
    icon: 'update',
    label: 'Religação',
    color: null,
    show: null,
    link: '/servico/religacao'
  },
  {
    datalayer: 'atalho-troca-registro',
    icon: 'published_with_changes',
    label: 'Troca de registro',
    color: null,
    show: null,
    link: '/servico/trocar-registro'
  },
  {
    datalayer: 'atalho-conserto-cavalete',
    icon: 'build',
    label: 'Conserto de cavalete',
    color: null,
    show: null,
    link: '/servico/conserto-cavalete'
  },
  {
    datalayer: 'atalho-tarifa-social',
    icon: 'loyalty',
    label: 'Tarifa social',
    color: null,
    show: null,
    link: '/servico/tarifa-social'
  },
  {
    datalayer: 'atalho-encerrar-contrato',
    icon: 'playlist_remove',
    label: 'Encerrar contrato',
    color: null,
    show: null,
    link: '/servico/encerrar-contrato'
  },
  {
    datalayer: 'atalho-agendamento-presencial',
    icon: 'event_available',
    label: 'Agendamento',
    color: null,
    show: null,
    link: 'https://aegea.agendamento.ai/'
  },
  {
    datalayer: 'atalho-ouvidoria',
    icon: 'support_agent',
    label: 'Ouvidoria',
    color: null,
    show: null,
    link: '/agencia/ouvidoria'
  }
];

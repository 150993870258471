import * as moment from 'moment';
moment.locale('pt-BR');

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'layout-print',
  templateUrl: './print.layout.html',
  styleUrls: ['./print.layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintLayout implements OnInit {
  @Input() unidade: string;
  dataImpressao: string = `Emitido em ${moment(new Date()).format('DD [de] MMMM [de] YYYY [às] HH:mm')}`;

  constructor() {}

  ngOnInit() {}
}

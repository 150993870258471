<layout-print [unidade]="unidade">
  <h1 *ngIf="titulo" [innerHTML]="titulo"></h1>
  <p *ngIf="texto" [innerHTML]="texto"></p>

  <ng-container *ngIf="lista?.length > 0">
    <h2 *ngIf="tituloLista">{{ tituloLista }}</h2>

    <dl *ngFor="let item of lista">
      <dt>{{ item.label || 'Não informado' }}</dt>
      <dd>{{ item.value || 'Não informado' }}</dd>
    </dl>
  </ng-container>

  <ng-container *ngIf="tabela?.length > 0">
    <table>
      <tr *ngFor="let item of tabela">
        <td *ngFor="let cell of item">
          <p *ngIf="cell.title">
            <strong [innerHTML]="cell.text"></strong>
          </p>
          <p *ngIf="!cell.title" [innerHTML]="cell.text"></p>
        </td>
      </tr>
    </table>
  </ng-container>
</layout-print>

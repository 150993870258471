import { Component, ViewChild } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-esqueci-matricula',
  templateUrl: './esqueci-matricula.html',
  styleUrls: ['./esqueci-matricula.scss']
})
export class EsqueciMatriculaModal {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  constructor(private dialogRef: MatDialogRef<EsqueciMatriculaModal>) {}

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

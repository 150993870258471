import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() icon: string = 'home';
  @Input() size: string = '24px';

  fontawesome: boolean;
  material: boolean;

  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.icon.indexOf(' fa-') >= 0) {
      this.fontawesome = true;
    } else {
      this.material = true;
    }
  }
}

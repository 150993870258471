<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">{{ titulo }}</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          Neste momento o acesso é feito apenas <strong>através de CPF ou CNPJ que tenha ao menos uma matrícula</strong>.<br />
          Sem este acesso é possível consultar faturas pela <strong>segunda via rápida</strong> ou
          <strong>abrir e consultar algumas ordens de serviço</strong>.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>Em caso de dúvidas, reclamações ou sugestões, consulte os canais de atendimento:</p>
        <inc-atendimento></inc-atendimento>
      </div>
    </div>
  </div>
</layout-modal>

import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import * as MatriculasActions from './matriculas.actions';
import {AegeaStore} from '../aegea.store';

import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { MatriculasAegeaStore } from "./matriculas.reducer";

@Injectable()
export class MatriculasEffects implements OnInitEffects  {

  constructor(private action$: Actions, private store: Store<MatriculasAegeaStore>) {}

  hydrate$ = createEffect(() =>
  this.action$.pipe(
    ofType(MatriculasActions.hydrate),
    map(() => {
      const storageValue = localStorage.getItem("state");
      if (storageValue) {
        try {
          const state = <AegeaStore>JSON.parse(storageValue);
          return MatriculasActions.hydrateSuccess({state});
        } catch {
          localStorage.removeItem("state");
        }
      }
      return MatriculasActions.hydrateFailure();
      })
    )
  );

  serialize$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(MatriculasActions.hydrateSuccess, MatriculasActions.hydrateFailure),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap((state) => {
          return localStorage.setItem("state", JSON.stringify(state))
        })
      ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return MatriculasActions.hydrate()
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCropper'
})
export class TextCropperPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const maxLengthAllowed = args;
      if (value.length > maxLengthAllowed) {
        return value.substring(0, maxLengthAllowed) + '...';
      }
      return value;
    }
  }
}

import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { NavigatorService } from '@service/navigator.service';

@Component({
  selector: 'inc-cadastro',
  templateUrl: './aviso-cadastro.html'
})
export class AvisoCadastroInclude implements OnInit {
  @Input() bannerType: string;

  debito: any;
  email: any;
  renderer: Renderer2;

  constructor(renderer: Renderer2, private navigatorService: NavigatorService) {
    this.renderer = renderer;
  }

  clickMobileMenu(page: string) {
    // TODO: Remove blocked CSS
    this.renderer.removeClass(document.body, 'blocked');
    this.navigatorService.goTo(page);
  }

  ngOnInit() {
    if (this.bannerType == 'debito') {
      this.debito = true;
      this.email = false;
    } else {
      this.debito = false;
      this.email = true;
    }
  }
}

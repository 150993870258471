import { Injectable } from '@angular/core';

import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { UnitResponse } from '@model/unidade.resolver';
import { UnitSetupResponse, UnitSetupResponseMaker, UnidadeDetalhes } from '@model/unidade.resolver';
import { UnitServicesResponse, UnitServicesResponseMaker } from '@model/unidade.resolver';
import { UnitMessagesResponse } from '@model/unidade.resolver';
import { LocalStorageService } from '@service/localstorage.service';
import { unidadeConfig } from '@config/unidade.config';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class UnidadeService {
  unitsMap = unidadeConfig;

  constructor(private httpService: HttpService, private localStorageService: LocalStorageService) {}

  getAllUnits(successCallbackAction): Array<UnitResponse> {
    return this.httpService
      .sendGetRequest(apiConfig.unidades.url)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getUnitSetup(successCallbackAction): Array<UnitSetupResponse> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    if (unit) {
      return this.httpService
        .sendGetRequest(apiConfig.unidades.setup(unit))
        .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
    }
    return null;
  }

  getUnitSetupPromisse(): Promise<UnidadeDetalhes> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    if (unit) {
      return this.httpService
        .sendGetRequest(apiConfig.unidades.setup(unit))
        .toPromise()
        .then(({ body }) => UnitSetupResponseMaker.create(body));
    } else {
      throw Error('Não é possível obter detalhes de uma unidade não selecionada.');
    }
  }

  getUnitServices(successCallbackAction): Array<UnitServicesResponse> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    if (unit) {
      return this.httpService
        .sendGetRequest(apiConfig.unidades.servicos(unit))
        .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
    }
    return null;
  }

  getUnitServicesPromisse(): Promise<UnitServicesResponse> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    if (unit) {
      return this.httpService
        .sendGetRequest(apiConfig.unidades.servicos(unit))
        .toPromise()
        .then(({ body }) => UnitServicesResponseMaker.create(body))
        .then(async (dado) => dado);
    } else {
      throw Error('Não é possível obter detalhes de uma unidade não selecionada.');
    }
  }

  getUnitMessages(successCallbackAction): Array<UnitMessagesResponse> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    return this.httpService
      .sendGetRequest(apiConfig.unidades.mensagem.url(unit))
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getUnitMessage(service, successCallbackAction): Array<UnitMessagesResponse> {
    const unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';
    return this.httpService
      .sendGetRequest(apiConfig.unidades.mensagem.servico(unit, service))
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse.body));
  }

  getUnitGssName(name, successCallbackAction): any {
    const options = { responseType: 'text' as 'text' };
    return this.httpService
      .sendGetRequestWithOptions(apiConfig.unidades.gss(name), options)
      .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
  }

  getUnitID(name: string) {
    return this.unitsMap[name];
  }

  getUnitFromParams(params): string {
    let novaUnidade = '';

    if (typeof params.b === 'string') {
      novaUnidade = this.getUnit(params.b);
    }

    if (typeof params.u === 'string') {
      novaUnidade = this.getUnit(params.u);
    }

    return novaUnidade;
  }

  getUnit(unidade: string): string {
    // WORKAROUND: Convertendo rio01web ou rio02web para rioweb
    unidade = `${unidade}`.replace(/\d+/g, '');

    if (Object.values(unidadeConfig).includes(unidade)) {
      return unidade;
    }

    if (Object.keys(unidadeConfig).includes(unidade)) {
      return unidadeConfig[unidade];
    }

    return 'UNIDADE_INVALIDA';
  }
}

import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
@Directive({
  selector: 'input[dueDateValidator]'
})
export class DueDateDirective {
  @Input() ngModel: string;
  @Output() dueDateEventValidation = new EventEmitter();

  @HostListener('keyup') onkeyup() {
    this.emittAnswer();
  }

  private emittAnswer() {
    if (this.ngModel) {
      this.dueDateEventValidation.emit(this.validateDueDate(this.ngModel.replace(/\//g, '')));
    }
  }

  private validateDueDate(date: string): boolean {
    if (!date) {
      return false;
    }

    if (date && date.length == 4) {
      let mes = Number.parseInt(date.substr(0, 2));
      let ano = Number.parseInt(date.substr(2, 4));

      if (mes < 1 || mes > 12) {
        return false;
      }

      if (new Date().getFullYear() > 2000 + ano) {
        return false;
      }
    }

    return true;
  }
}

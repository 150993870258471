import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ui-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileFormComponent implements OnInit {
  protected validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Arquivo';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Arquivo inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Upload() {
    this.elem.nativeElement.click();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.invalidMessage;
    }
  }
}

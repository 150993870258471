import {
  Component,
  AfterViewInit,
  Input,
  Output,
  ElementRef,
  Renderer2,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'ui-shortcut',
  templateUrl: './ui-shortcut.component.html',
  styleUrls: ['./ui-shortcut.component.scss']
})
export class ShortchutComponent implements AfterViewInit {
  @ViewChild('elem') elem: ElementRef;

  @Input() datalayer: string;
  @Input() icon: string = 'home';
  @Input() label: string = 'Home';
  @Input() height: string = '150px';
  @Input() color: string;
  @Input() show: boolean = false;
  @Input() disabled: boolean = false;
  @Input() active: boolean = false;
  @Input() link: string;

  @Output() action: EventEmitter<VoidFunction> = new EventEmitter();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  clicked() {
    this.action.emit();
  }

  get isExternal() {
    return this.link.includes('http')
  }
}

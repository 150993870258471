import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SolicitationViewModel } from '@model/solicitacao.resolver';
import { ParcelamentoContasDetailViewModel, ParcelamentoEfetivarResponse } from '@model/parcelamento.resolver';

export enum ICONS_FEEDBACK {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  MUTED = 'muted',

  SUCCESS_LIGHT = 'success',
  WARNING_LIGHT = 'warning',
  DANGER_LIGHT = 'danger',
  INFO_LIGHT = 'info',
  MUTED_LIGHT = 'muted',

  AGUA_SUCCESS = 'agua-success',
  AGUA_WARNING = 'agua-warning',
  AGUA_DANGER = 'agua-danger',
  AGUA_INFO = 'agua-info',
  AGUA_MUTED = 'agua-muted',
  AGUA_LIGHT = 'agua-light',

  AMPULHETA_SUCCESS = 'ampulheta-success',
  AMPULHETA_WARNING = 'ampulheta-warning',
  AMPULHETA_DANGER = 'ampulheta-danger',
  AMPULHETA_INFO = 'ampulheta-info',
  AMPULHETA_MUTED = 'ampulheta-muted',
  AMPULHETA_LIGHT = 'ampulheta-light',

  SIFAO_SUCCESS = 'sifao-success',
  SIFAO_WARNING = 'sifao-warning',
  SIFAO_DANGER = 'sifao-danger',
  SIFAO_INFO = 'sifao-info',
  SIFAO_MUTED = 'sifao-muted',
  SIFAO_LIGHT = 'sifao-light',

  CONSERTO_SUCCESS = 'conserto-success',
  CONSERTO_WARNING = 'conserto-warning',
  CONSERTO_DANGER = 'conserto-danger',
  CONSERTO_INFO = 'conserto-info',
  CONSERTO_MUTED = 'conserto-muted',
  CONSERTO_LIGHT = 'conserto-light',

  IDENTIDADE_SUCCESS = 'identidade-success',
  IDENTIDADE_WARNING = 'identidade-warning',
  IDENTIDADE_DANGER = 'identidade-danger',
  IDENTIDADE_INFO = 'identidade-info',
  IDENTIDADE_MUTED = 'identidade-muted',
  IDENTIDADE_LIGHT = 'identidade-light',

  IMPRESSORA_SUCCESS = 'impressora-success',
  IMPRESSORA_WARNING = 'impressora-warning',
  IMPRESSORA_DANGER = 'impressora-danger',
  IMPRESSORA_INFO = 'impressora-info',
  IMPRESSORA_MUTED = 'impressora-muted',
  IMPRESSORA_LIGHT = 'impressora-light',

  LOJA_SUCCESS = 'loja-success',
  LOJA_WARNING = 'loja-warning',
  LOJA_DANGER = 'loja-danger',
  LOJA_INFO = 'loja-info',
  LOJA_MUTED = 'loja-muted',
  LOJA_LIGHT = 'loja-light',

  MINERAL_SUCCESS = 'mineral-success',
  MINERAL_WARNING = 'mineral-warning',
  MINERAL_DANGER = 'mineral-danger',
  MINERAL_INFO = 'mineral-info',
  MINERAL_MUTED = 'mineral-muted',
  MINERAL_LIGHT = 'mineral-light',

  TORNEIRA_SUCCESS = 'torneira-success',
  TORNEIRA_WARNING = 'torneira-warning',
  TORNEIRA_DANGER = 'torneira-danger',
  TORNEIRA_INFO = 'torneira-info',
  TORNEIRA_MUTED = 'torneira-muted',
  TORNEIRA_LIGHT = 'torneira-light',

  VAZAMENTO_SUCCESS = 'vazamento-success',
  VAZAMENTO_WARNING = 'vazamento-warning',
  VAZAMENTO_DANGER = 'vazamento-danger',
  VAZAMENTO_INFO = 'vazamento-info',
  VAZAMENTO_MUTED = 'vazamento-muted',
  VAZAMENTO_LIGHT = 'vazamento-light'
}

export enum COLORS_FEEDBACK {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  MUTED = 'muted',
  LIGHT = 'light'
}

export interface IDataBusFeedbackPage {
  title: string;
  message: string;
  icon: ICONS_FEEDBACK;
  color: COLORS_FEEDBACK;
  buttonLink?: string;
  buttonLabel?: string;
  buttonShare?: boolean;
  buttonDownload?: boolean;
  includeAtendimento?: boolean;
  includeServicos?: boolean;
  includeReclamacoes?: boolean;
  listTitle?: string;
  list?: IDataBusFeedbackPageList[];
}

interface IDataBusFeedbackPageList {
  label: string;
  value: any;
}

export interface IDataBusParcelamentoEfetivado {
  paramEfetivar: ParcelamentoEfetivarResponse;
  paramPageEfetivado: IDataBusParcelamentopParamPageEfetivado;
  paramMultasJuros?: IDataBusSimulacaoResultadoParamMultasJuros;
}

interface IDataBusParcelamentopParamPageEfetivado {
  valorEntrada: number;
  valorPrimeiraParcela: number;
  dataVencimentoEntrada: string;
  referenciaPrimeiraParcela: string;
  valorDemaisParcela: number;
  quantidadeParcela: number;
  valorTotalAtualizado: number;
  valorTotalPrazo: number;
}

export interface IDataBusParcelamentoAssinatura {
  paramPageEfetivado: IDataBusParcelamentopParamPageEfetivado;
  payloadEfetivar: {
    contasNaoParcelar: {
      anoLancto: number;
      numEmissao: number;
      numAviso: number;
      tributo: string;
    }[];
    idSimulacao: number;
    matricula: any;
  };
}

export interface IDataBusSimulacaoResultado {
  paramGetSimulation: IDataBusSimulacaoResultadoParamSimulacao;
  paramPageResultado: IDataBusSimulacaoResultadoParamPageResultado;
  paramMultasJuros: IDataBusSimulacaoResultadoParamMultasJuros;
  simulationStartPayload: any;
  fotoBlob?: any;
}

export interface IDataBusSimulacaoResultadoParamSimulacao {
  idSimulacao: number;
  jurosFinanciamento: number;
  valorPrimeiraParcela: number;
  valorDemaisParcela: number;
  valorTotalAtualizado: number;
}

export interface IDataBusSimulacaoResultadoParamPageResultado {
  listaContasNaoParcelaveis: ParcelamentoContasDetailViewModel[];
  quantidadeParcela: number;
  valorEntrada: number;
  dataVencimentoEntrada: string;
  referenciaPrimeiraParcela: string;
  valorTotalPrazo: number;
}

export interface IDataBusSimulacaoResultadoParamMultasJuros {
  desprezaMulta?: boolean;
  desprezaJuros?: boolean;
  desprezaCorrecao?: boolean;
  desprezaJurosFinanciamento?: boolean;
}

export interface IDataBusSimulacao {
  parametroGetLimites: IDataBusLimitesParametrosAgente | IDataBusLimitesParametros;
  parametroPageSimulacao: IDataBusSimulacaoParametros;
}

export interface IDataBusSimulacaoParametros {
  contasNaoParcelar: ParcelamentoContasDetailViewModel[];
  valorTotalDivida: number;
  temParcelamento: boolean;
}

export interface IDataBusLimitesParametrosAgente extends IDataBusLimitesParametros {
  exibeParaAgenteCheckBoxesDespreza: boolean;
}

export interface IDataBusLimitesParametros {
  referenciaPrimeiraParcela: string;
  dataVencimentoEntrada: string;
  valorMinimoParcela: number;
  quantidadeMinimaParcela: number;
  quantidadeParcela: number;
  quantidadeMaximaParcela: number;
  valorMinimoEntrada: number;
  valorEntrada: number;
  valorMaximoEntrada: number;
}

export interface IDataBusPagamento {
  tribute: string;
  launchBillMonth: number;
  launchBillYear: number;
  numAviso: number;
  numEmissao: number;
  matricula: string;
  zona: number;
  arrecadador?: string;
}

export interface IDataBusPagamentoGuia {
  nomeDoCliente: string;
  anoExtrato: number;
  codBarras: string;
  dataVencimento: string;
  linhaDigitavel: string;
  numExtrato: number;
  tributo: string;
  valorTotal: number;
  matricula: string;
  zona: number;
  arrecadador?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataBusService {
  private messageSource = new BehaviorSubject(<any>0);
  eventToSubscribe = this.messageSource.asObservable();

  private stringSource = new BehaviorSubject('');
  stringEventToSubscribe = this.stringSource.asObservable();

  sendMessage(payload: any) {
    this.messageSource.next(payload);
  }

  sendString(payload: string) {
    this.stringSource.next(payload);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataBusTypeSafeService<T> {
  private messageSource = new BehaviorSubject<T>(null);
  eventToSubscribe = this.messageSource.asObservable();

  private stringSource = new BehaviorSubject('');
  stringEventToSubscribe = this.stringSource.asObservable();

  sendMessage(payload: T) {
    this.messageSource.next(payload);
  }

  sendString(payload: string) {
    this.stringSource.next(payload);
  }
}

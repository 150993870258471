enum USER_TYPE {
  AGENTE,
  IMOBILIARIA,
  AUTOATENDIMENTO
}
export interface IAudiencia {
  profile: string;
  web: string;
  app: string;
}

const autoatendimento: IAudiencia = {
  profile: 'autoatendimento',
  web: 'AUTO_ATENDIMENTO_WEB',
  app: 'AUTO_ATENDIMENTO_APP'
};

const imobiliaria: IAudiencia = {
  profile: 'imobiliaria',
  web: 'IMOBILIARIA_WEB',
  app: 'IMOBILIARIA_APP'
};

const agente: IAudiencia = {
  profile: 'agente',
  web: 'AGENTE_WEB',
  app: 'AGENTE_APP'
};

function perfil(audiencia): USER_TYPE {
  switch (audiencia) {
    case autoatendimento.web:
    case autoatendimento.app:
      return USER_TYPE.AUTOATENDIMENTO;

    case imobiliaria.web:
    case imobiliaria.app:
      return USER_TYPE.IMOBILIARIA;

    case agente.web:
    case agente.app:
      return USER_TYPE.AGENTE;

    default:
      return null;
  }
}

export const audienciaConfig = {
  autoatendimento,
  imobiliaria,
  agente,
  perfil
};

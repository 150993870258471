<div #elem>
  <mat-form-field>
    <mat-label>
      {{ label }}
      <sup *ngIf="tip">{{ tip }}</sup>
    </mat-label>
    <input type="text"
      matInput
      [required]="control.errors?.required"
      [inputDisabled]="disabled"
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.data-layer]="datalayer"
      (change)="change()"
      (keydown.enter)="blockEnterSubmit($event)"
      [matAutocomplete]="auto"
    >
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of options | async" [value]="option.label">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="control?.value" matSuffix mat-icon-button (click)="clear($event)" aria-label="Clear" >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>{{ errorMessage }}</mat-error>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>

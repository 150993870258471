<mat-form-field>
  <mat-label>
    {{ label }}
    <sup *ngIf="tip">{{ tip }}</sup>
  </mat-label>
  <input type="text"
    #elem
    matInput
    noSpecialChars
    [required]="control.errors?.required"
    [inputDisabled]="disabled"
    [formControl]="control"
    [placeholder]="placeholder"
    [attr.data-layer]="'consulta-endereco-logradouro'"
    (keydown.enter)="blockEnterSubmit($event)"
    [matAutocomplete]="auto">

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of options | async, let i=index" [value]="i" class="row-disclaimer-striped">
      <dl class="mt-4 mb-4">
        <dd>
          <p>
            {{ option.logradouro | titlecase }}, <span>{{ option.bairro | titlecase }}</span><br />
            <small>{{ option.localidade | titlecase }}</small> - <small *ngIf="option.cep">CEP: {{ option.cep | mask: '00000-000' }}</small>
          </p>
        </dd>
      </dl>
    </mat-option>
  </mat-autocomplete>
  <button *ngIf="control?.value" matSuffix mat-icon-button class="close" (click)="onOptionClear($event)" aria-label="Clear">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error>{{ errorMessage }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

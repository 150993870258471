import { Component, Inject } from '@angular/core';

import { CepService } from '@service/cep.service';
import { CepResponse } from '@model/cep.resolver';
import { LoaderService } from '@service/loader.service';
import { CepResultViewModel, CepResultViewModelMaker } from '@model/cep.resolver';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { set as _set } from 'lodash';

@Component({
  selector: 'modal-consulta-endereco',
  templateUrl: './consulta-endereco.html',
  styleUrls: ['./consulta-endereco.scss']
})
export class ConsultaEnderecoModal {
  form: FormGroup;
  inputEndereco = new FormControl();

  addressViewModel: CepResultViewModel = {};
  page: number;
  count: number;
  possibleAddressCollection: CepResultViewModel[] = [];

  // Paginator
  actualPage: number = 0;
  pageSize: number = 6;

  get paginated_tabelaEndereco(): CepResultViewModel[] {
    const startIndex = this.pageSize * this.actualPage;
    const endIndex = startIndex + this.pageSize;
    return this.possibleAddressCollection.filter((_, idx) => startIndex <= idx && endIndex > idx);
  }

  onPageChange(event) {
    this.pageSize = event.pageSize;
    this.actualPage = event.pageIndex;
  }

  constructor(
    private cepService: CepService,
    public loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) data: { unit: string },
    private dialogRef: MatDialogRef<ConsultaEnderecoModal>,
    private fb: FormBuilder
  ) {
    this.addressViewModel.unit = data.unit;

    this.form = this.fb.group({
      address: this.inputEndereco
    });
  }

  getPossibleAddresses() {
    this.loaderService.setLocalLoader(true);

    this.cepService.getPossibleAddresses(
      this.addressViewModel,
      this.successCallbackSearchAddressAction.bind(this),
      this.errorCallbackSearchAddressAction.bind(this)
    );
  }

  updateModel = (kModel, kPath, v) => {
    _set(this[kModel], kPath, v);
  };

  trackByFunction(index: number) {
    return index;
  }

  closeWithData(item?: CepResponse) {
    item.cep = item.cep && item.cep.length >= 5 ? item.cep : '00000000';
    this.clean();
    this.dialogRef.close(item);
  }

  closeAndClean() {
    this.clean();
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }

  clean() {
    this.addressViewModel = {};
    this.possibleAddressCollection = undefined;
  }

  private successCallbackSearchAddressAction(succeedResponse: Array<CepResponse>) {
    if (succeedResponse) {
      this.possibleAddressCollection = succeedResponse.map((response) => CepResultViewModelMaker.create(response));

      if (this.addressViewModel.neighborhood && this.addressViewModel.neighborhood !== '') {
        this.possibleAddressCollection = this.possibleAddressCollection.filter((currentObject) => {
          if (currentObject.neighborhood.toLowerCase().includes(this.addressViewModel.neighborhood.toLowerCase())) {
            if (currentObject.address !== '') {
              return currentObject;
            }
          }
        });
      }

      this.count = this.possibleAddressCollection.length;
    }

    this.loaderService.setLocalLoader(false);
  }

  private errorCallbackSearchAddressAction() {
    this.addressViewModel = {};
    this.possibleAddressCollection = undefined;
    this.loaderService.setLocalLoader(false);
  }
}

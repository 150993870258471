<layout-base [pageTitle]="feedback.title" [forceNoLogged]="true">
  <mat-card class="section">
    <div
      class="row row-feedback"
      [class.row-feedback-success]="feedback.color === 'success'"
      [class.row-feedback-warning]="feedback.color === 'warning'"
      [class.row-feedback-danger]="feedback.color === 'danger'"
      [class.row-feedback-info]="feedback.color === 'info'"
      [class.row-feedback-muted]="feedback.color === 'muted'"
      [class.row-feedback-light]="feedback.color === 'light'"
    >
      <div class="col-sm-12">
        <img [src]="'/assets/feedbacks/' + feedback.icon + '.svg'" />
        <h4 id="feedback-titulo" data-layer="feedback-titulo">{{ feedback.title }}</h4>
        <p id="feedback-mensagem" data-layer="feedback-mensagem" [innerHTML]="feedback.message"></p>
      </div>
    </div>

    <div *ngIf="feedback.list" class="row row-disclaimer">
      <div class="col-sm-12">
        <dl>
          <dt id="protocolo-atendimento" data-layer="protocolo-atendimento">{{ feedback.listTitle }}</dt>
          <dd>
            <ul>
              <li *ngFor="let item of feedback.list">
                <p>{{ item.label }}: <strong>{{ item.value || 'Não informado' }}</strong></p>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>

    <ng-container *ngIf="feedback.includeAtendimento">
      <div class="row row-title">
        <div class="col-sm-12">
          <h4 class="mb-2">Precisa de ajuda?</h4>
          <p>Em caso de dúvidas, entre em contato com a unidade de atendimento:</p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <inc-atendimento></inc-atendimento>
        </div>
      </div>
    </ng-container>

    <div class="row row-button mt-4">
      <div class="col-sm-12">
        <button
          *ngIf="!(feedback.buttonLink && feedback.buttonLabel)"
          [routerLink]="isLogged ? '/home' : '/atendimento/entrar'"
          class="btn btn-outline-primary"
        >
          Página inicial
        </button>

        <button
          *ngIf="(feedback.buttonLink && feedback.buttonLabel)"
          [routerLink]="feedback.buttonLink"
          class="btn btn-outline-primary"
        >
          {{ feedback.buttonLabel }}
        </button>

        <button
          id="feedback-share"
          *ngIf="feedback.buttonShare && botaoCompartilhar"
          (click)="share(feedback.title, feedback.message, 'impresso', feedback.title)"
          class="btn btn-outline-success"
        >
          <ui-icon icon="share"></ui-icon>
          Compartilhar
        </button>

        <button
          id="feedback-download"
          *ngIf="feedback.buttonDownload && botaoDownload"
          (click)="download()"
          class="btn btn-outline-info"
          data-layer="download-feedback"
        >
          <ui-icon icon="download"></ui-icon>
          Download
        </button>
      </div>
    </div>
  </mat-card>

  <section *ngIf="feedback.includeServicos" class="section">
    <div class="row">
      <div class="col-sm-12">
        <inc-atalhos sectionTitle="Mais serviços"></inc-atalhos>
      </div>
    </div>
  </section>
</layout-base>

<ui-print
  [unidade]="unit"
  [titulo]="feedback.title"
  [texto]="feedback.message"
  [tituloLista]="feedback.listTitle"
  [lista]="feedback.list"
>
</ui-print>

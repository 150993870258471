<div class="layout" [class.menu]="menu" [class.is-logged]="isLogged" [class.no-logged]="noLogged || forceNoLogged">
  <div class="backdrop-menu" (click)="menu = false"></div>

  <div *ngIf="isDevelopment || isHomologation || !isProduction" class="environment-bar">
    <p>
      <a [href]="platformInfo.isMobile ? 'https://aeservicosonline.com.br/agencia/sobre' : '/agencia/sobre'">
        <strong *ngIf="!isDevelopment && !isHomologation && !isProduction">Localhost</strong>
        <strong *ngIf="isDevelopment">Desenvolvimento</strong>
        <strong *ngIf="isHomologation">Homologação</strong>
      </a>
      <strong> • </strong>
      <a [href]="platformInfo.isMobile ? 'https://aeservicosonline.com.br/agencia/sobre' : '/agencia/sobre'">
        <strong>{{ version }}</strong>
      </a>
      <strong> • </strong>
      <span>
        <ui-badge class="xs" label="XS"></ui-badge>
        <ui-badge class="sm" label="SM"></ui-badge>
        <ui-badge class="md" label="MD"></ui-badge>
        <ui-badge class="lg" label="LG"></ui-badge>
        <ui-badge class="xl" label="XL"></ui-badge>
      </span>
    </p>
  </div>

  <ng-template #box>
    <mat-card>
      <div class="box-title" [class.imobiliaria]="perfilNav.isImobiliaria" [class.agente]="perfilNav.isAgente">
        <dl>
          <dt *ngIf="avatar">
            <img [src]="avatar" [alt]="perfilNav.titular" />
          </dt>
          <dt *ngIf="!avatar">{{ perfilNav.titular | slice: 0:2 }}</dt>
          <dd>{{ perfilNav.titular }}</dd>
        </dl>
      </div>

      <div class="box-body">
        <dl>
          <dt>Matrícula</dt>
          <dd
            [class.success]="ligacaoStatusColor() === 'success'"
            [class.warning]="ligacaoStatusColor() === 'warning'"
            [class.danger]="ligacaoStatusColor() === 'danger'"
            [class.info]="ligacaoStatusColor() === 'info'"
            [class.muted]="ligacaoStatusColor() === 'muted'"
          >
            <strong [class.matricula]="true">{{ userViewModel.installation }}</strong>
            <ui-badge [color]="ligacaoStatusColor()" [label]="ligacaoStatus(getLigacaoStatus)"></ui-badge>
          </dd>
        </dl>

        <dl>
          <dt>Endereço</dt>
          <dd>
            {{ addressViewModel.nome | titlecase }}
            <span *ngIf="addressViewModel.numero">, {{ addressViewModel.numero }}</span>
            <span *ngIf="addressViewModel.complemento"> - {{ addressViewModel.complemento | titlecase }}</span><br />
            <span *ngIf="addressViewModel.cep">CEP: {{ addressViewModel.cep }}</span>
            <span *ngIf="addressViewModel.bairro"> - {{ addressViewModel.bairro | titlecase }}</span>
          </dd>
        </dl>

        <div>
          <button [class]="'btn btn-sm btn-outline-'+ligacaoStatusColor()" [routerLink]="perfilNav.home">
            Trocar Matrícula
          </button>
        </div>
      </div>
    </mat-card>
  </ng-template>

  <header>
    <div class="header-container">
      <div class="header-row">
        <section class="header-logo">
          <section
            class="menu-open"
            *ngIf="!(isPageUnidade || isPageLogin || isPageHomeAtendimento || isPageHomeAgente || isPageHomeImobiliaria)"
          >
            <button data-layer="menu-button" mat-button (click)="menu = true">
              <ui-icon icon="menu"></ui-icon>
            </button>
          </section>

          <section class="logo-header">
            <a
              data-layer="menu-link-logo"
              [class]="'logo-image logo-' + idUnidade"
              [routerLink]="forceNoLogged ? '/logout' : '/home'"
            >
              <img *ngIf="!idUnidade" src="/assets/unidades/branco/aegea.svg" />
              <img *ngIf="idUnidade" [src]="'/assets/unidades/branco/' + idUnidade + '.svg'" />
            </a>

            <a data-layer="menu-link-sair" *ngIf="forceNoLogged" class="logo-link" (click)="logout()">
              <strong>Sair</strong>
            </a>

            <a
              data-layer="menu-link-escolher-unidade"
              *ngIf="noLogged && !isPageUnidade && !forceNoLogged"
              class="logo-link"
              routerLink="/unidade"
            >
              Escolher outra <strong>unidade</strong>
            </a>

            <a
              data-layer="menu-link-ir-para-home"
              *ngIf="isLogged && !isPageHome"
              class="logo-link"
              [routerLink]="forceNoLogged ? '/logout' : '/home'"
            >
              Ir para <strong>página inicial</strong>
            </a>
          </section>
        </section>

        <section class="header-menu">
          <nav *ngFor="let panel of baseNav">
            <ng-container *ngIf="panel.menu">
              <button *ngIf="panel.show" class="menu-item" mat-button [matMenuTriggerFor]="headerMenu">
                <ui-icon [icon]="panel.icon"></ui-icon>
                <span class="menu-title">{{ panel.title }}</span>
                <ui-icon icon="arrow_drop_down"></ui-icon>
              </button>

              <mat-menu #headerMenu="matMenu" xPosition="before">
                <ng-container *ngFor="let item of panel.menu">
                  <button
                    [attr.data-layer]="item.datalayer"
                    *ngIf="item.show"
                    [routerLink]="item.url"
                    [disabled]="item.disabled"
                    mat-menu-item
                  >
                    {{ item.link }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>

            <ng-container *ngIf="!panel.menu && panel.show">
              <button
                [attr.data-layer]="panel.datalayer"
                *ngIf="panel.url"
                class="only-icon"
                mat-button
                [routerLink]="panel.url"
              >
                <ui-icon [icon]="panel.icon"></ui-icon>
                <span class="menu-title">{{ panel.title }}</span>
              </button>

              <button
                [attr.data-layer]="panel.datalayer"
                *ngIf="panel.action"
                class="only-icon"
                mat-button
                (click)="panel.action()"
              >
                <ui-icon [icon]="panel.icon"></ui-icon>
                <span class="menu-title">{{ panel.title }}</span>
              </button>
            </ng-container>
          </nav>
        </section>

        <section class="header-title">
          <h1 data-layer="page-title">{{ pageTitle }}</h1>
        </section>

        <section *ngIf="isLogged" class="box-perfil header-box">
          <ng-container *ngTemplateOutlet="box"></ng-container>
        </section>
      </div>
    </div>
  </header>

  <aside>
    <section class="menu-close">
      <h1>
        <span *ngIf="!isLogged">Menu principal</span>
      </h1>

      <button mat-button (click)="menu = false">
        <ui-icon icon="clear"></ui-icon>
      </button>
    </section>

    <section *ngIf="isLogged" class="box-perfil menu-box">
      <ng-container *ngTemplateOutlet="box"></ng-container>
    </section>

    <section class="menu-body">
      <mat-accordion>
        <ng-container *ngFor="let panel of baseNav">
          <div [attr.data-layer]="panel.datalayer">
            <mat-expansion-panel *ngIf="panel.show && !panel.url && !panel.action">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <ui-icon [icon]="panel.icon"></ui-icon>
                  {{ panel.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-list>
                <ng-container *ngFor="let item of panel.menu">
                  <mat-list-item
                    [attr.data-layer]="item.datalayer"
                    *ngIf="item.show"
                    [routerLink]="item.url"
                    [disabled]="item.disabled"
                  >
                    {{ item.link }}
                  </mat-list-item>
                </ng-container>
              </mat-list>
            </mat-expansion-panel>
          </div>
        </ng-container>
        <mat-expansion-panel *ngIf="isLogged" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title (click)="logout()">
              <ui-icon [icon]="'fas fa-sign-out'"></ui-icon>
              Sair
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
  </aside>

  <main>
    <ng-content></ng-content>
  </main>

  <footer>
    <div class="footer-container mt-3">
      <section class="footer-menu">
        <dl *ngFor="let item of footerNav">
          <dt>
            <ui-icon [icon]="item.icon"></ui-icon>
            <p>{{ item.title }}</p>
          </dt>

          <dd *ngIf="item.info">
            <p *ngFor="let info of item.info" [innerHTML]="info.text"></p>
          </dd>

          <dd *ngIf="item.menu">
            <ng-container *ngFor="let menu of item.menu">
              <div *ngIf="menu.show">
                <a [attr.data-layer]="menu.datalayer" *ngIf="menu.url" [routerLink]="menu.url">{{ menu.link }}</a>
                <a [attr.data-layer]="menu.datalayer" *ngIf="menu.external" [href]="menu.external" target="_blank"
                  >{{ menu.link }}</a
                >
              </div>
            </ng-container>
          </dd>
        </dl>

        <div>
          <div class="footer-logo">
            <a href="https://www.aegea.com.br/" target="_blank">
              <img src="/assets/logos/aegea-white.svg" />
            </a>
          </div>

          <div *ngIf="!isPageUnidade" class="footer-redes-sociais">
            <a [href]="setup?.linkFacebook" target="_blank">
              <ui-icon icon="fab fa-facebook-square"></ui-icon>
            </a>

            <a [href]="setup?.linkYoutube" target="_blank">
              <ui-icon icon="fab fa-youtube"></ui-icon>
            </a>

            <a [href]="setup?.linkLinkedin" target="_blank">
              <ui-icon icon="fab fa-linkedin"></ui-icon>
            </a>

            <a [href]="setup?.linkInstagram" target="_blank">
              <ui-icon icon="fab fa-instagram"></ui-icon>
            </a>
          </div>
        </div>
      </section>

      <section class="copyright">
        <p>&copy; 2010 ~ {{ ano }} AEGEA. Todos os direitos reservados.</p>
      </section>
    </div>

    <div *ngIf="setup?.linkChatOnline?.length > 0 && !service.chatZendesk" class="footer-chat">
      <ul>
        <li>
          <a href="javascript:;" (click)="abrirChat()">
            <ui-icon icon="chat" size="42px"></ui-icon>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[noLetters]'
})
export class NoLettersDirective {
  constructor(private element: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.element.nativeElement.value;
    this.element.nativeElement.value = initialValue.replace(/[^\d]+/g, '');

    if (initialValue !== this.element.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

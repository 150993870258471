<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">Recupere a senha</h2>
        <p>Preencha os campos abaixo e receba no e-mail o processo para recuperar a senha.</p>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div *ngIf="loaderService.getLoaderStatus()" class="row row-title">
      <div class="col-sm-12">
        <p class="text-center text-secondary">
          <img class="loading" src="/assets/loaders/loading-secondary.gif" /><br />
          <strong>Recuperando a senha</strong>
        </p>
      </div>
    </div>

    <form
      autocomplete="off"
      *ngIf="!loaderService.getLoaderStatus()"
      [formGroup]="formEsqueciSenha"
      #elemFormEsqueciSenha
    >
      <div class="row">
        <div *ngIf="perfilSelecionado !== 'AGENTE'" class="col-sm-12">
          <ui-input-email
            datalayer="esqueci-senha-email"
            formControlName="email"
            name="email"
            required="true"
            autoFocus="true"
            [control]="inputEmail"
            ngDefaultControl
          >
          </ui-input-email>
        </div>

        <div class="col-sm-12">
          <ui-input-alphanumeric
            datalayer="esqueci-senha-usuario"
            formControlName="usuario"
            name="usuario"
            [control]="inputUsuario"
            ngDefaultControl
            [label]="perfilSelecionado === 'AGENTE' ? 'Usuário do agente' : 'Usuário da imobiliária'"
            placeholder="Digite o usuário"
            invalidMessage="Usuário inválido"
            required="true"
          ></ui-input-alphanumeric>
        </div>
      </div>
    </form>

    <div class="row" *ngIf="perfilSelecionado !== 'AGENTE'">
      <div class="col-sm-12">
        <inc-atendimento></inc-atendimento>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row row-submit-button">
      <div class="col-sm-12">
        <button
          data-layer="esqueci-senha-enviado"
          class="btn btn-primary"
          [disabled]="!formEsqueciSenha.valid || loaderService.getLoaderStatus()"
          (click)="sendPassword()"
        >
          Enviar
        </button>
      </div>
    </div>
  </div>
</layout-modal>

<div #elem [class]="'orientation-' + orientation">
  <mat-label *ngIf="label">
    <strong>{{ label }}</strong>
    <span *ngIf="initialValue || required">*</span>
    <sup *ngIf="tip">{{ tip }}</sup>
  </mat-label>
  <mat-radio-group color="primary" [inputDisabled]="disabled" [formControl]="control">
    <mat-radio-button
      *ngFor="let item of options; trackBy: trackByFunction"
      [attr.data-layer]="item.datalayer"
      [value]="item.value"
      [required]="control.errors?.required"
      [checked]="item.value === initialValue"
      (change)="Change()"
      (keydown.enter)="BlockEnterSubmit($event)"
    >
      <mat-label>
        {{ item.label }}
        <sup *ngIf="item.tip">{{ item.tip }}</sup>
      </mat-label>
      <mat-hint *ngIf="item.hint">
        {{ item.hint }}
      </mat-hint>
    </mat-radio-button>
  </mat-radio-group>
  <mat-hint>{{ hint }}</mat-hint>
</div>

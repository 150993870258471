import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keyConfig } from '@config/keys.config';
import { Store } from '@ngrx/store';
import { GtmService } from '@service/gtm.service';
import { LoaderService } from '@service/loader.service';
import { LocalStorageService } from '@service/localstorage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AegeaStore } from 'store/aegea.store';
import { SetEnvironment } from 'store/environment/environment.actions';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {
  private backendEnv = '';
  private unidadeReferencial = '';
  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<AegeaStore>,
    private loaderService: LoaderService,
    private dataLayer: GtmService
  ) {
    this.store.select('environment').subscribe((d) => {
      this.backendEnv = d.env;
    });
    this.store.select('matriculas').subscribe((d) => {
      this.unidadeReferencial = d.unidade_referencia;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.dataLayer.timer('error', true);
    this.loaderService.setServiceLoader(true);

    const token = this.localStorageService.get('token');
    let headersApi = request.headers;

    headersApi = headersApi.set('Api-Token', keyConfig.apiToken);
    headersApi = headersApi.set('Version', '238');

    if (token) {
      if (!/\/unidades\/.*\/servicos/.test(request.url)) {
        headersApi = headersApi.set('Authorization', 'Bearer ' + token);
      }
    }

    const googleAuth = this.localStorageService.get('GoogleAuth');
    if (googleAuth) {
      if (!/\/unidades\/.*\/servicos/.test(request.url)) {
        headersApi = headersApi.set('Validation-Id', googleAuth);
      }
    }else{
      headersApi = headersApi.set('Validation-Id', '');
    }

    if (this.unidadeReferencial) {
      headersApi = headersApi.set('x-agvr-unidade', this.unidadeReferencial);
    }

    const clonedRequest = request.clone({
      headers: headersApi
    });

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const env = event.headers.get('environment');
          if (event.url.indexOf('login') === -1) {
            if (this.backendEnv !== env) {
              this.store.dispatch(SetEnvironment({ data: env ? env : '' }));
            }
          }
          this.loaderService.setServiceLoader(false);
          return event;
        }
      })
    );
  }
}

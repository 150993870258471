import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  @Input() unidade: string;
  @Input() titulo: string;
  @Input() texto: string;
  @Input() tituloLista: string;
  @Input() lista: [];
  @Input() tabela: [];

  constructor() {}

  ngOnInit() {}
}

import { Component, ViewChild, OnInit, Inject } from '@angular/core';

import { ModalLayout } from '@layout/modal/modal.layout';
import { EsqueciSenhaModalService } from '@modal/modal-esqueci-senha/esqueci-senha.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { FormGroupTypeSafe, FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { LoaderService } from '@service/loader.service';

interface IFormEsqueciSenha {
  email: string;
  usuario: string;
}

@Component({
  selector: 'modal-esqueci-senha',
  templateUrl: './esqueci-senha.html',
  styleUrls: ['./esqueci-senha.scss']
})
export class EsqueciSenhaModal implements OnInit {
  @ViewChild('specificContent', { static: true }) specificContent: ModalLayout;

  formEsqueciSenha: FormGroupTypeSafe<IFormEsqueciSenha>;
  inputPerfil: FormControl = new FormControl();
  inputEmail: FormControl = new FormControl();
  inputUsuario: FormControl = new FormControl();

  perfilSelecionado: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { profile; login },
    private modalService: EsqueciSenhaModalService,
    private toasterService: ToastrService,
    private dialogRef: MatDialogRef<EsqueciSenhaModal>,
    private fb: FormBuilderTypeSafe,
    public loaderService: LoaderService
  ) {
    this.formEsqueciSenha = this.fb.group<IFormEsqueciSenha>({
      email: this.inputEmail,
      usuario: this.inputUsuario
    });

    this.perfilSelecionado = data.profile;
    this.formEsqueciSenha.getSafe((x) => x.usuario).setValue(data.login);
  }

  ngOnInit() {}

  sendPassword() {
    this.loaderService.setLocalLoader(true);

    if (this.perfilSelecionado == 'IMOBILIARIA') {
      this.modalService.sendPasswordByEmail(
        {
          email: this.formEsqueciSenha.getSafe((x) => x.email).value,
          login: this.formEsqueciSenha.getSafe((x) => x.usuario).value
        },
        this.successCallbackActionSendPasswordByEmail.bind(this)
      );
    }

    if (this.perfilSelecionado == 'AGENTE') {
      this.modalService.sendPasswordForAgent(
        {
          login: this.formEsqueciSenha.getSafe((x) => x.usuario).value
        },
        this.successCallbackActionSendPasswordForAgent.bind(this)
      );
    }
  }

  close() {
    const login = this.formEsqueciSenha.getSafe((x) => x.usuario).value;
    this.dialogRef.close(login);
  }

  private successCallbackActionSendPasswordByEmail(succeedResponse) {
    this.toasterService.success(
      'As instruções para recuperar a senha foram enviadas para o e-mail cadastrado para este usuário!'
    );
    this.loaderService.setLocalLoader(false);
    this.close();
  }

  private successCallbackActionSendPasswordForAgent(succeedResponse) {
    this.toasterService.success(
      'As instruções para recuperar a senha foram enviadas por SMS para o telefone cadastrado para este usuário!'
    );
    this.loaderService.setLocalLoader(false);
    this.close();
  }
}

<div class="layout-notice">
  <div class="backdrop-menu"></div>

  <header>
    <div class="header-container">
      <section class="header-title">
        <h1 data-layer="page-title">{{ pageTitle }}</h1>
      </section>

      <section class="header-logo">
        <section class="logo-header">
          <a class="logo-image" data-layer="aviso-link-logo" routerLink="/atendimento/entrar">
            <img src="/assets/unidades/branco/aegea.svg" />
          </a>
        </section>
      </section>
    </div>
  </header>

  <main>
    <ng-content></ng-content>
  </main>

  <footer>
    <div class="header-container">
      <p>&copy; 2010 ~ {{ ano }} AEGEA. Todos os direitos reservados.</p>
    </div>
  </footer>
</div>

<div class="row row-info-atendimento">
  <dl *ngIf="linkPhone">
    <dt>
      <ui-icon icon="phone" size="42px"></ui-icon>
      <p>Fale conosco</p>
    </dt>
    <dd>
      <p [innerHTML]="linkPhone"></p>
    </dd>
  </dl>

  <dl *ngIf="linkChatOnline">
    <dt>
      <ui-icon icon="sms" size="42px"></ui-icon>
      <p>Chat online</p>
    </dt>
    <dd>
      <p>
        <a (click)="goToChat()">Enviar mensagem</a>
      </p>
    </dd>
  </dl>

  <dl *ngIf="linkWhatsapp">
    <dt>
      <ui-icon icon="fab fa-whatsapp" size="42px"></ui-icon>
      <p>Whatsapp</p>
    </dt>
    <dd>
      <p>
        <a (click)="goToWhatsapp()">Enviar mensagem</a>
      </p>
    </dd>
  </dl>

  <dl>
    <dt>
      <ui-icon icon="home" size="42px"></ui-icon>
      <p>Lojas conveniadas</p>
    </dt>
    <dd>
      <p>
        <a (click)="goToLojas()">Procure a loja mais próxima</a>
      </p>
    </dd>
  </dl>
</div>

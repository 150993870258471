<div class="ui-icon" [style]="'width: ' + size + '; height: ' + size + ';'">
  <i
    *ngIf="fontawesome"
    [class]="icon"
    [style]="'font-size: ' + size + '; width: ' + size + '; height: ' + size + ';'"
  ></i>

  <mat-icon *ngIf="material" [style]="'font-size: ' + size + '; width: ' + size + '; height: ' + size + ';'">
    {{ icon }}
  </mat-icon>
</div>

import { Injectable } from '@angular/core';
import { JwtService } from '@service/jwt.service';
import { UsuarioService } from '@service/usuario.service';

export enum CONTRATO_STATUS {
  I = 'Inativa',
  A = 'Ativa'
}

export enum LIGACAO_STATUS {
  O = 'Provisória',
  K = 'Corte Pedido',
  I = 'Inativa',
  D = 'Desativada',
  V = 'Virtual',
  A = 'Ativa',
  C = 'Cortada'
}

@Injectable({
  providedIn: 'root'
})
export class LigacaoService {
  constructor(private jwtService: JwtService, private usuarioService: UsuarioService) {}

  isContratoAtivo(): boolean {
    return this.jwtService.getContractActiveFromToken();
  }

  async isLigacaoAtiva(callback: any) {
    this.usuarioService.getUser((response) => {
      callback(response.statusLigacao === 'A');
    });
  }

  getContratoStatus(): CONTRATO_STATUS {
    if (this.isContratoAtivo()) {
      return CONTRATO_STATUS.A;
    } else {
      return CONTRATO_STATUS.I;
    }
  }

  async getLigacaoStatus(callback: any) {
    this.usuarioService.getUser((succeedResponse) => {
      const status = succeedResponse.statusLigacao;

      switch (status) {
        case 'O':
          callback(LIGACAO_STATUS.O);
          break;

        case 'K':
          callback(LIGACAO_STATUS.K);
          break;

        case 'I':
          callback(LIGACAO_STATUS.I);
          break;

        case 'D':
          callback(LIGACAO_STATUS.D);
          break;

        case 'V':
          callback(LIGACAO_STATUS.V);
          break;

        case 'A':
          callback(LIGACAO_STATUS.A);
          break;

        case 'C':
          callback(LIGACAO_STATUS.C);
          break;

        default:
          callback(LIGACAO_STATUS.A);
      }
    });
  }
}

<div *ngIf="bannerUrl?.length > 0" class="row">
  <a data-layer="home-banner" href="javascript:;" *ngIf="hasLink">
    <div class="row banner">
      <img src="{{ bannerUrl }}" alt="" (error)="bannerUrl = ''" />
    </div>
  </a>

  <div data-layer="home-banner" class="row banner" *ngIf="!hasLink">
    <img src="{{ bannerUrl }}" alt="" (error)="bannerUrl = ''" />
  </div>
</div>

<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">O que são 'economias'?</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h3>Entenda:</h3>

        <p>
          São subdivisões de um imóvel com ocupação independente entre si e uso de um único serviço de abastecimento de
          água e/ou de coleta de esgoto.
        </p>

        <p>Ex: um prédio com 10 apartamentos possui uma matrícula e 10 economias residenciais.</p>
      </div>
    </div>
  </div>
</layout-modal>

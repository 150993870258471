import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '@service/loader.service';
import { ModalLayout } from '@layout/modal/modal.layout';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { EnvironmentService } from './environment.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { GtmService } from './gtm.service';


@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private file: File,
    private fileOpener: FileOpener,
    private envService: EnvironmentService,
    private dataLayer: GtmService
  ) {}

  async openPDF(blob: Blob, filename: string, mensagens?: { erro: string }) {
    this.envService
      .platformInfo()
      .then((d) => {
        if (d.isMobile) {
          this.writeAndOpenPdf(filename, blob);
        }
        if (d.isDesktop) {
          this.blobToPDF(blob, filename, 'Erro ao baixar PDF');
        }
      })
      .catch(() => {
        if (mensagens.erro) {
          this.toasterService.error(mensagens.erro);
        }
      });
  }

  private openPDFOnWeb(blob: Blob, filename: string) {
    if (blob.size !== 0) {
      const url = window.URL.createObjectURL(blob);

      if (url && url !== '') {
        const link = document.createElement('a');

        if (link.download !== undefined) {
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.addEventListener('click', () => {
            document.body.removeChild(link);
          });
          link.click();
        }
      }
    }
  }

  private blobToPDF(blob: Blob, filename: string, errorMessage: string, specificContent?: ModalLayout) {
    if (blob) {
      if (blob.size !== 0) {
        const url = window.URL.createObjectURL(blob);
        if (url && url !== '') {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.addEventListener('click', () => {
              document.body.removeChild(link);
            });
            link.click();
          }
        }
        if (specificContent) {
          specificContent.close();
        }
      } else {
        this.toasterService.error(errorMessage);
      }
      this.loaderService.setPageLoader(false);
    }
  }

  private async writeAndOpenPdf(filename: string, blob: Blob) {

    let whereToWrite = Directory.Cache;
    const isAndroid = await this.envService.isAndroid();

    if (isAndroid && (await this.envService.osVersion()) < 10) {
      whereToWrite = Directory.Documents;
    }

    try {
      if (isAndroid) {
        const permission = await Filesystem.requestPermissions();
      }

      const base64 = await this.getBase64FromBlob(blob);

      const writeFileResult = await Filesystem.writeFile({
        path: `${filename}`,
        data: base64,
        directory: whereToWrite,
        recursive: true
      });

      let fileExtn = writeFileResult.uri.split('.').reverse()[0];
      let fileMIMEType = this.getMIMEtype(fileExtn);

      this.fileOpener
        .open(writeFileResult.uri, fileMIMEType)
        .then(() => this.notifyPdfDownload(filename))
        .catch((error) => {
          console.error('Error openening file', error);
          if (error && error.status == 9) {
            this.toasterService.warning(`Este dispositivo não tem leitor de PDF instalado`);
          }
        });
    } catch (error) {
      console.error('Unable to write file', error);
      this.dataLayer.set('Erro ao realizar o download de pdf', {
        error: JSON.stringify(error)
      });
    }
  }

  notifyPdfDownload(filename: string) {
    this.toasterService.success(`Download de ${filename ? filename : 'arquivo'} concluído!`);
  }

  private getFileNative() {
    const dire = this.file.applicationDirectory + 'www/assets/downloads/';
    this.file.readAsArrayBuffer(dire, 'imobiliarias-cadastro.pdf').then((fileData: ArrayBuffer) => {
      this.writeAndOpenPdf('imobiliarias-cadastro.pdf', new Blob([fileData], { type: 'application/pdf' }));
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }

  private async getBase64FromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = this.getFileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  private getMIMEtype(extn) {
    let ext = extn.toLowerCase();
    let MIMETypes = {
      pdf: 'application/pdf',
      png: 'image/png'
    };
    return MIMETypes[ext];
  }
}

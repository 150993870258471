import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { DateValidator } from '@validator/date.validator';

@Component({
  selector: 'ui-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateFormComponent implements OnInit {
  private validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Data';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Informe a data';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() maskFormat: string = '00/00/0000';
  @Input() validFormat: string = 'DD/MM/YYYY';
  @Input() invalidMessage: string = 'Data inválida';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    if (this.maskFormat.length > 0) {
      this.validators.push(Validators.minLength(this.maskFormat.length));
      this.validators.push(Validators.maxLength(this.maskFormat.length));
    }

    this.validators.push(DateValidator(this.validFormat));

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }

    if (this.control.hasError('minlength') || this.control.hasError('maxlength')) {
      return this.invalidMessage;
    }

    return this.control.hasError('validateDate') ? this.invalidMessage : '';
  }
}

export interface CepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

export interface CepResultViewModel {
  address?: string;
  neighborhood?: string;
  unit?: string;
  cep?: string;
  complement?: string;
  city?: string;
  uf?: string;
  ibge?: string;
  gia?: string;
}

export class CepResultViewModelMaker {
  static create(result: CepResponse) {
    return {
      address: result.logradouro,
      neighborhood: result.bairro,
      cep: result.cep,
      complement: result.complemento,
      city: result.localidade,
      uf: result.uf,
      ibge: result.ibge,
      gia: result.gia,
      unit: result.unidade
    };
  }
}

import { TitleCasePipe } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  Renderer2
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CepService } from '@service/cep.service';
import { LocalStorageService } from '@service/localstorage.service';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { tap, debounceTime, filter, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'ui-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss']
})
export class InputAddressAutocompleteFormComponent implements OnInit {

  protected validators = [];

  @Output() onChange: EventEmitter<Object> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('elem') elem: ElementRef;

  @Input() datalayer: string = '';
  @Input() label: string = 'Lista';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Selecione um item';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Nenhum item selecionado';
  @Input() initialValue: string = '';
  @Input() options: Observable<any[]>;
  @Input() autoFocus: boolean = false;
  control: FormControl = new FormControl();

  private unidade: string;

  constructor(
    private renderer: Renderer2,
    private cdref: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private cepService: CepService,
    private titlecasePipe: TitleCasePipe,
  ) {
    this.unidade = this.localStorageService.get('unit');
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.control.enable();

    if (this.initialValue) {
      this.control.setValue(this.initialValue);
    }

    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(
      debounceTime(1000),
      filter(value => !!value && value.length > 2 && this.control.enabled),
      map(value => this.replaceSpecialChars(value)),
      switchMap(value =>
        this.cepService.pesquisarLogradouro(this.unidade, value)
          .pipe(catchError(() => this.onError()))
      ),
      tap(({ body }) => this.setHint(body?.length))
    ).subscribe(({ body }) => this.options = of(body));
  }

  trackByFunction(index: number) {
    return index;
  }

  setHint(size: number) {
    this.hint = undefined;
    if (size === 0) {
      this.hint = `Nenhum endereço ${this.control.value} foi localizado.`;
    }
  }

  onError(): Observable<any> {
    this.hint = `Falha ao consultar o endereço informado. ${this.control.value}`;
    return EMPTY;
  }

  blockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  onOptionSelected(event) {
    this.control.disable();
    const selectedValue = event.option.value;
    this.options.subscribe(v => {
      const selected = v[selectedValue];
      const endereco = this.titlecasePipe.transform(selected?.logradouro);
      this.control.setValue(endereco, { onlySelf: true, emitEvent: false });
      selected.cep = selected.cep && selected.cep.length >= 5 ? selected.cep : '00000000';
      selected.endereco = selected?.logradouro;
      this.onChange.emit(selected);
    });
  }

  onOptionClear(event: InputEvent) {
    this.onClear.emit('onOptionClear');
    if (this.elem) {
      event.stopPropagation();
      event.preventDefault()
      this.control.enable();
      this.control.reset();
      this.control.setValue(undefined)
    }
    this.options = EMPTY;
    this.hint = undefined;
  }

  private replaceSpecialChars(value = ''): string {
    const newValue = value
      .replace(/[àáäâã]/g, 'a')
      .replace(/[ÀÁÄÂÃ]/g, 'A')
      .replace(/[èéëê]/g, 'e')
      .replace(/[ÈÉËÊ]/g, 'E')
      .replace(/[ìíïî]/g, 'i')
      .replace(/[ÌÍÏÎ]/g, 'I')
      .replace(/[òóöõô]/g, 'o')
      .replace(/[ÒÓÖÕÔ]/g, 'O')
      .replace(/[ùúüû]/g, 'u')
      .replace(/[ÙÚÜÛ]/g, 'U')
      .replace(/[ç]/g, 'c')
      .replace(/[Ç]/g, 'C')
      .replace(/[ñ]/g, 'n')
      .replace(/[Ñ]/g, 'N')
      .replace(/[^A-Za-z0-9\s]+/, '');
    return newValue;
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return "Informe parte do endereço que deseja consultar";
    }
  }
}

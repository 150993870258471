import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import Smooch from 'smooch';
import { AegeaStore } from 'store/aegea.store';
import { MatriculasAegeaStore } from 'store/matriculas/matriculas.reducer';
import { HttpService } from './http.service';
import { apiConfig } from '@config/api.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { keyConfig } from '@config/keys.config';
import { LocalStorageService } from './localstorage.service';
import { selecionaMatricula } from 'store/matriculas/matriculas.actions';
export interface ISmoochConnectRequest {
  integrationId: string
}


export function initializerProviderSmoochService(provider: SmoochService) {
  return () => {
    provider.init()
  };
}

@Injectable({
  providedIn: 'root'
})
export class SmoochService {
  private subscribers: Subscription[] = [];
  private matriculaBase$: Observable<MatriculasAegeaStore>;
  private chatSmooch: any | null = null;
  constructor(
    private store: Store<AegeaStore>,
    private httpService: HttpService,
    private localStorageService: LocalStorageService
  ) {
    this.matriculaBase$ = this.store.select('matriculas')
  }

  public init(): void { }

  destroy() {
    if (this.chatSmooch) {
      Smooch.destroy();
      this.chatSmooch = null;
    }
  }

  ngOnDestroy() {
    this.subscribers.forEach(d => d.unsubscribe())
  }

  private updateMatricula(matricula: string) {
    Smooch.updateUser({
      metadata: {
        selecionada_matricula: matricula,
      }
    });
  }

  private updateNameEmail(nome: string, email: string) {
    Smooch.updateUser({
      givenName: nome,
      email: email,
      metadata: {
        selecionada_matricula: '1111111',
        matriculas: ['1111111', '2222222']
      }
    });
  }

  private loginChat({ document, token }): Promise<any> {
    return Smooch.login(document, token)
  }

  public logoutChat() {
    try {
      if (!!Smooch && !!Smooch?.logout) {
        Smooch.logout().then(() => {
          this.store.dispatch(selecionaMatricula({ matricula_selecionada: '', unidade_referencia: undefined }))
        })
      }
    } catch (error) {
      this.store.dispatch(selecionaMatricula({ matricula_selecionada: '', unidade_referencia: undefined }))
      console.error('logoutChat', error);
    }
  }

  async connect(integrationId, successCallback: any, failureCallback: any) {
    if (!this.chatSmooch) {
      if (!integrationId) return;
      this.chatSmooch = Smooch.init({
        integrationId: integrationId,
        locale: 'pt-BR',
        customText: {
          actionPaymentCompleted: 'Pagamento completo',
          actionPaymentError: 'Ocorreu um erro ao processar o cartão. <br>Tente novamente ou use um cartão diferente.',
          actionPostbackError: 'Ocorreu um erro ao processar a ação. Por favor, tente novamente.',
          clickToRetry: 'Mensagem não enviada. Clique para tentar novamente.',
          clickToRetryForm: 'Formulário não enviado. Clique em qualquer lugar do formulário para tentar novamente.',
          connectNotificationText: 'Sincronize a conversa e continue nos enviando mensagens por meio do aplicativo favorito.',
          connectNotificationSingleText: 'Seja notificado quando você receber uma resposta.',
          conversationListHeaderText: 'Conversas',
          conversationListRelativeTimeJustNow: 'Agora mesmo',
          conversationListRelativeTimeMinute: '1 minuto atrás',
          conversationListRelativeTimeMinutes: '{value} minutos atrás',
          conversationListRelativeTimeHour: '1 hora atrás',
          conversationListRelativeTimeHours: '{value} horas atrás',
          conversationListRelativeTimeYesterday: 'Ontem',
          conversationListTimestampFormat: 'DD / MM / AA',
          conversationListPreviewAnonymousText: 'Alguém',
          conversationListPreviewCarouselText: '{user} enviou uma mensagem',
          conversationListPreviewFileText: '{user} enviou um arquivo',
          conversationListPreviewFormText: '{user} enviou um formulário',
          conversationListPreviewFormResponseText: '{user} preencheu um formulário',
          conversationListPreviewImageText: '{user} enviou uma imagem',
          conversationListPreviewLocationRequestText: '{user} enviou uma solicitação de localização',
          conversationListPreviewUserText: 'Vocês',
          conversationTimestampHeaderFormat: 'DD MMMM YYYY, HH:mm',
          couldNotConnect: 'Desligada. Você não receberá mensagens.',
          couldNotConnectRetry: 'Reconectando ...',
          couldNotConnectRetrySuccess: 'Você está online novamente!',
          couldNotLoadConversations: 'Não foi possível carregar as conversas.',
          emailChangeAddress: 'Mudar e-mail',
          emailDescription: 'Para ser notificado por e-mail quando você obtiver uma resposta, digite o endereço de e-mail.',
          emailFieldLabel: 'O email',
          emailFieldPlaceholder: 'Endereço de email',
          emailFormButton: 'Enviar',
          emailLinkingErrorMessage: 'Por favor, envie um endereço de e-mail válido.',
          fetchHistory: 'Carregar mais',
          fetchingHistory: 'Recuperando histórico ...',
          fileTooLargeError: 'Limite máximo de tamanho de arquivo excedido ({size})',
          fileTypeError: 'Tipo de arquivo não suportado.',
          formErrorInvalidEmail: 'Email inválido',
          formErrorNoLongerThan: 'Deve conter no máximo ({caracteres}) caracteres',
          formErrorNoShorterThan: 'Deve conter pelo menos ({characters}) caracteres',
          formErrorUnknown: 'Isso não parece muito certo',
          formFieldSelectPlaceholderFallback: 'Escolha um...',
          frontendEmailChannelDescription: 'Para falar conosco por e-mail, basta enviar uma mensagem para o endereço de e-mail e responderemos em breve',
          headerText: 'Como podemos ajudar?',
          imageClickToReload: 'Clique para recarregar a imagem.',
          imageClickToView: 'Clique para ver a imagem {size}.',
          imagePreviewNotAvailable: 'Pré-visualização não disponível.',
          inputPlaceholder: 'Digite uma mensagem...',
          inputPlaceholderBlocked: 'Preencha o formulário acima...',
          introAppText: 'Envie-nos uma mensagem abaixo ou a partir do aplicativo favorito.',
          lineChannelDescription: 'Para falar conosco usando o LINE, escaneie este código QR usando o aplicativo LINE e envie-nos uma mensagem.',
          linkError: 'Ocorreu um erro ao tentar gerar um link para este canal. Por favor, tente novamente.',
          linkChannelPageHeader: 'Sincronize a conversa',
          locationNotSupported: 'O navegador não oferece suporte a serviços de localização ou foi desativado. Em vez disso, digite a localidade.',
          locationSecurityRestriction: 'Este site não pode acessar a localização. Em vez disso, digite a localidade.',
          locationSendingFailed: 'Não foi possível enviar a localização',
          locationServicesDenied: 'Este site não pode acessar a localização. Permita o acesso em as configurações ou digite a localidade.',
          messageError: 'Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente.',
          messageIndicatorTitlePlural: '({count}) Novas mensagens',
          messageIndicatorTitleSingular: '({count}) Nova mensagem',
          messageRelativeTimeDay: '{value} dias atrás',
          messageRelativeTimeHour: '{value} horas atrás',
          messageRelativeTimeJustNow: 'Agora mesmo',
          messageRelativeTimeMinute: '{value} minutos atrás',
          messageTimestampFormat: 'HH:mm',
          messageDelivered: 'Entregue',
          messageSeen: 'Visto',
          messageSending: 'Enviando...',
          messageTooLongError: 'Limite máximo de tamanho de mensagem excedido ({size}).',
          messengerChannelDescription: 'Conecte a conta do Facebook Messenger para ser notificado quando você receber uma resposta e continue a conversa no Facebook Messenger.',
          newConversationButtonText: 'Nova Conversa',
          notificationSettingsChannelsDescription: 'Sincronize essa conversa conectando-se ao aplicativo de mensagens favorito para continuar a conversa como preferir.',
          notificationSettingsChannelsTitle: 'Outros Canais',
          notificationSettingsConnected: 'Conectado',
          notificationSettingsConnectedAs: 'Conectado como {username}',
          prechatCaptureGreetingText: 'Olá para começar, gostaríamos de saber um pouco mais sobre você:',
          prechatCaptureNameLabel: 'Nome',
          prechatCaptureNamePlaceholder: 'Digite o nome...',
          prechatCaptureEmailLabel: 'O email',
          prechatCaptureEmailPlaceholder: 'app.aegea@aegea.com.br',
          prechatCaptureConfirmationText: 'Obrigado por isso! Em que podemos ajudá-lo?',
          prechatCaptureMailgunLinkingConfirmation: 'Você será notificado aqui e por e-mail em {email} assim que respondermos.',
          sendButtonText: 'Enviar',
          settingsHeaderText: 'Definições',
          shareLocation: 'Localização',
          smsBadRequestError: 'Não foi possível nos comunicarmos com este número. Tente novamente ou um número diferente.',
          smsCancel: 'Cancelar',
          smsChangeNumber: 'Mudar número',
          smsChannelDescription: 'Ligue o número SMS para ser notificado quando obtiver uma resposta e continue a conversa por SMS.',
          smsChannelPendingDescription: 'Verifique as mensagens em {number} para confirmar o número de telefone.',
          smsContinue: 'Enviar',
          smsInvalidNumberError: 'Por favor, envie um número de telefone válido.',
          smsLinkCancelled: 'Link para {appUserNumber} foi cancelado.',
          smsLinkPending: 'Pendente',
          smsPingChannelError: 'Ocorreu um erro ao enviar uma mensagem para o número.',
          smsSendText: 'Me envie um texto',
          smsStartTexting: 'Comece a enviar mensagens de texto',
          smsTooManyRequestsError: 'Uma conexão para esse número foi solicitada recentemente. Tente novamente em {minutos} minutos.',
          smsTooManyRequestsOneMinuteError: 'Uma conexão para esse número foi solicitada recentemente. Por favor, tente novamente em 1 minuto.',
          smsUnhandledError: 'Algo deu errado. Por favor, tente novamente.',
          syncConversation: 'Sincronizar conversa',
          tapToRetry: 'Mensagem não enviada. Toque para tentar novamente.',
          tapToRetryForm: 'Formulário não enviado. Toque em qualquer lugar do formulário para tentar novamente.',
          telegramChannelDescription: 'Conecte a conta do Telegram para ser notificado quando receber uma resposta e continue a conversa no Telegram',
          unsupportedMessageType: 'Tipo de mensagem não compatível.',
          unsupportedActionType: 'Tipo de ação não compatível.',
          uploadDocument: 'Arquivo',
          uploadInvalidError: 'Arquivo inválido.',
          uploadPhoto: 'Imagem',
          uploadVirusError: 'Um vírus foi detectado no arquivo e ele foi rejeitado',
          viberChannelDescription: 'Conecte a conta do Viber para ser notificado quando você receber uma resposta e continue a conversa no Viber.Para começar, leia o código QR usando o aplicativo Viber.',
          viberChannelDescriptionMobile: 'Conecte a conta do Viber para ser notificado quando você receber uma resposta e continue a conversa no Viber.Para começar, instale o aplicativo Viber e toque em Conectar.',
          viberQRCodeError: 'Ocorreu um erro ao buscar o código QR do Viber. Por favor, tente novamente.',
          wechatChannelDescription: 'Conecte a conta do WeChat para ser notificado quando você receber uma resposta e continue a conversa no WeChat.Para começar, leia este código QR usando o aplicativo WeChat.',
          wechatChannelDescriptionMobile: 'Conecte a conta do WeChat para ser notificado quando você receber uma resposta e continue a conversa no WeChat.Para começar, salve esta imagem de código QR e faça o upload < a href = \'weixin:/dl/scan\'>do scanner de código QR </a>.',
          wechatQRCodeError: 'Ocorreu um erro ao buscar o código QR do WeChat. Por favor, tente novamente.',
          whatsappChannelDescriptionDesktop: 'Sincronize a conta com o WhatsApp lendo o código QR ou clicando no link abaixo. Em seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Código: {{code}})',
          whatsappChannelDescriptionMobile: ' Sincronize a conta com o WhatsApp clicando no link abaixo. Em seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Código: {{code}}).',
          whatsappLinkingError: 'Ocorreu um erro ao buscar as informações de vinculação do WhatsApp. Por favor, tente novamente.'
        }
      }).then(
        successResponse => successCallback(successResponse),
        failureResponse => failureCallback(failureResponse)
      );
    }
  }

  async getToken(): Promise<{ token: string }> {
    return this.httpService
      .sendGetRequest(apiConfig.chat)
      .toPromise()
      .then(({ body }) => body);
  }
}

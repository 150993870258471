import { Component, OnInit, ViewEncapsulation, Input, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'layout-notice',
  templateUrl: './notice.layout.html',
  styleUrls: ['./notice.layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoticeLayout implements OnInit {
  @Input() pageTitle: string;
  ano: number = new Date().getFullYear();

  constructor(private renderer: Renderer2, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(this.pageTitle);
    this.renderer.addClass(document.body, 'no-logged');
  }
}

<layout-modal #specificContent>
  <div class="modal-body">
    <ng-container *ngIf="possibleAddressCollection?.length === 0">
      <div class="row row-title">
        <div class="col-sm-12">
          <h2 class="title">Consultar endereço</h2>
          <button (click)="closeAndClean(); form.reset()" class="close">
            <ui-icon icon="close"></ui-icon>
          </button>
        </div>
        <div class="col-sm-12 mt-1">
          <p>Informe parte do endereço que deseja consultar:</p>
        </div>
      </div>

      <form autocomplete="off" *ngIf="!loaderService.getLoaderStatus()" [formGroup]="form" #elemForm>
        <div class="row">
          <div class="col-sm-12">
            <ui-input-alphanumeric
              formControlName="address"
              name="address"
              (onNewValue)="updateModel('addressViewModel', 'address', $event)"
              [control]="inputEndereco"
              datalayer="consulta-endereco-logradouro"
              required="true"
              autoFocus="true"
              ngDefaultControl
              label="Endereço"
              placeholder="Informe rua, avenida, bairro, cidade..."
            >
            </ui-input-alphanumeric>
          </div>
        </div>

        <div class="row row-button">
          <div class="col-sm-12">
            <button
              data-layer="pesquisar-endereco"
              [disabled]="!form.valid"
              (click)="getPossibleAddresses()"
              class="btn btn-md btn-primary"
            >
              Pesquisar
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <div *ngIf="loaderService.getLoaderStatus()" class="row row-title">
      <div class="col-sm-12">
        <p class="text-center text-secondary">
          <img class="loading" src="/assets/loaders/loader-secondary.gif" /><br />
          <strong>Procurando endereços</strong>
        </p>
      </div>
    </div>

    <ng-container *ngIf="possibleAddressCollection?.length > 0">
      <div class="row row-title">
        <div class="col-sm-12">
          <h2 class="title">Endereços encontrados</h2>
          <button (click)="closeAndClean(); form.reset()" class="close">
            <ui-icon icon="close"></ui-icon>
          </button>
        </div>
        <div class="col-sm-12 mt-1">
          <p *ngIf="possibleAddressCollection?.length === 1">Foi localizado <strong>um endereço</strong>, selecione:</p>
          <p *ngIf="possibleAddressCollection?.length > 1">
            Foram localizados <strong>{{possibleAddressCollection?.length}} endereços</strong>, selecione um:
          </p>
        </div>
      </div>

      <!-- Start: tableEndereco -->
      <ng-template #tableEndereco let-item="item">
        <dl [class.item-success]="item.situation === 'REALIZADO'">
          <dd>
            <a href="javascript:;" (click)="closeWithData(item); form.reset()">
              <ui-icon icon="place"></ui-icon><br />
              <small>Usar</small>
            </a>
          </dd>
        </dl>
        <dl>
          <dd>
            <p (click)="closeWithData(item); form.reset()">
              {{ item.address | titlecase }} {{ item.neighborhood | titlecase }}<br />
              <small *ngIf="item.cep">CEP: {{ item.cep | mask: '00000-000' }}</small>
            </p>
          </dd>
        </dl>
      </ng-template>

      <section class="modal-table">
        <div class="row row-title">
          <div class="col-sm-12">
            <div class="modal-table-item" *ngFor="let item of paginated_tabelaEndereco; trackBy: trackByFunction">
              <ng-container *ngTemplateOutlet="tableEndereco; context: { item: item }"></ng-container>
            </div>
          </div>
        </div>
      </section>

      <div *ngIf="possibleAddressCollection?.length > pageSize" class="row">
        <div class="col-sm-12">
          <mat-paginator
            [length]="possibleAddressCollection?.length"
            [pageSize]="pageSize"
            [pageIndex]="actualPage"
            hidePageSize="true"
            [pageSizeOptions]="[5]"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </div>
      </div>

      <div class="row row-button">
        <div class="col-sm-12">
          <button class="btn btn-success" (click)="possibleAddressCollection = []">Nova consulta</button>
        </div>
      </div>
      <!-- End: tableEndereco -->
    </ng-container>
  </div>
</layout-modal>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { JwtService } from '@service/jwt.service';
import { LocalStorageService } from '@service/localstorage.service';
import { NavigatorService } from '@service/navigator.service';
import { AegeaStore } from 'store/aegea.store';
import { logoutAction, selecionaMatricula } from 'store/matriculas/matriculas.actions';
import { SmoochService } from './smooch.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private localStorageService: LocalStorageService,
    private jwtService: JwtService,
    private navigatorService: NavigatorService,
    private smoochService: SmoochService,
    private store: Store<AegeaStore>,
  ) {}

  signInSystemWithToken(token: string) {
    this.localStorageService.set('token', token);
    const matricula = this.jwtService.getInstallationFromToken();
    const digito_verificador = this.jwtService.getInstallationDVFromToken()
    const installation = `${matricula}`
    this.localStorageService.set('installation', installation);
    this.store.dispatch(selecionaMatricula({matricula_selecionada: matricula, unidade_referencia: undefined}));
    this.updateAuthenticationStatus();
  }

  signOut() {
    const preservarCredencialArmazenada = this.localStorageService.get('rememberCredentials');
    const preservarImobiliariaArmazenada = this.localStorageService.get('rememberImobiliariaCredentials');
    const preservarAgenteArmazenado = this.localStorageService.get('rememberAgenteCredentials');

    this.store.dispatch(logoutAction());

    this.localStorageService.removeAll();

    if (preservarCredencialArmazenada && preservarCredencialArmazenada !== '[]') {
      this.localStorageService.set('rememberCredentials', preservarCredencialArmazenada);
      this.navigatorService.goTo('atendimento/entrar');
    } else if (preservarImobiliariaArmazenada && preservarImobiliariaArmazenada !== '[]') {
      this.localStorageService.set('rememberImobiliariaCredentials', preservarImobiliariaArmazenada);
      this.navigatorService.goTo('imobiliaria/entrar');
    } else if (preservarAgenteArmazenado && preservarAgenteArmazenado !== '[]') {
      this.localStorageService.set('rememberAgenteCredentials', preservarAgenteArmazenado);
      this.navigatorService.goTo('agente/entrar');
    } else {
      this.navigatorService.goTo('/atendimento/entrar');
    }
    this.smoochService.logoutChat()
  }

  signOutChangeUnitOnRoute() {
    this.store.dispatch(logoutAction());
    this.localStorageService.removeAll();
    this.localStorageService.removeItem('lastRoute');
    this.smoochService.logoutChat()
  }

  isAuthenticated(): boolean {
    const keyValue = this.localStorageService.get('isCorporate');
    const decodedToken = this.jwtService.decodeToken();
    if (typeof decodedToken !== 'undefined') {
      const expirationTimestamp = decodedToken.exp;
      const expirationDateTime = new Date(expirationTimestamp * 1000);
      const now = new Date();
      return typeof keyValue !== 'undefined' && expirationDateTime >= now;
    }
    return false;
  }

  isCorporateUser(): boolean {
    return this.localStorageService.get('isCorporate') === 'true';
  }

  containsRole(expectedRole: string): boolean {
    const encodedToken = this.localStorageService.get('token');
    const decodedToken = this.jwtService.decodeToken(encodedToken);
    if (decodedToken.roles) {
      return decodedToken.roles.indexOf(expectedRole) > -1;
    }
    return false;
  }

  updateAuthenticationStatus(isCorporateUser: boolean = false) {
    this.localStorageService.set('isCorporate', isCorporateUser.valueOf().toString());
  }
}


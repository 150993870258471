import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';

export function DateValidator(validFormat: string) {
  return (control: AbstractControl) => {
    if (control.value) {
      let date = moment(control.value, validFormat);
      if (!date.isValid()) {
        return { validateDate: true };
      }
    }

    return null;
  };
}

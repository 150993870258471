<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">Autodenúncia</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>Há uma irregularidade em sua casa, <strong>quais os benefícios em fazer uma autodenúncia?</strong></p>
        <p>
          Ao se autodenunciar você estará sendo um cidadão consciente, e para incentivá-lo, oferecemos os seguintes
          benefícios:
        </p>
        <ul>
          <li>Isenção do valor da multa.</li>
          <li>Não instauração de processo legal de fiscalização.</li>
          <li>Não instauração do processo de irregularidade (há apenas o registro da Ordem de Serviço).</li>
          <li>Manutenção de outros benefícios, como Tarifa Social</li>
          <li>Isenção do serviço de Padronização da Ligação de água.</li>
        </ul>
      </div>
    </div>
  </div>
</layout-modal>

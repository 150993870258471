import { Injectable } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { EnvironmentService, PlatformInfo } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  platformInfo: PlatformInfo = {
    isAndroid: false,
    isDesktop: false,
    isIos: false,
    isMobile: false,
    osVersion: 0
  };

  constructor(
    private envService: EnvironmentService
  ) {
    this.envService.platformInfo().then((d) => {
      this.platformInfo = d;
    });
  }

  init() {
    try {
      if (this.platformInfo.isDesktop) {
        GoogleAuth.initialize();
      }
    } catch (error) {
      console.error('Não foi possível inicializar o GoogleAuth', error);
    }
  }

  async connect(successCallback: any, failureCallback: any) {
    try {
      if (this.platformInfo.isDesktop) {
        GoogleAuth.initialize();
      }
      let googleUser = await GoogleAuth.signIn();
      successCallback(googleUser);
    }
    catch (e) {
      console.error('Não foi possível inicializar o GoogleAuth', e);
      failureCallback(e);
    }
  }

  async disconnect() {
    await GoogleAuth.signOut();
  }
}

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { PhoneValidator } from '@validator/phone.validator';
@Component({
  selector: 'ui-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneFormComponent implements OnInit {
  protected validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Telefone';
  @Input() mask: string = '(00) 00000-0000||(00) 0000-0000';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = 'Informe o telefone';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Telefone inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();

  CustomMask() {
    const val = this.control.value || '';

    if (val.length > 0) {
      const doc = val.replace(/[^\d]+/g, '');
      this.mask = doc.length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }
  }

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.validators.push(PhoneValidator);

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
      this.onNewValue.emit(newValue);
      this.CustomMask();
    });

    this.CustomMask();
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return this.placeholder;
    }

    return this.control.hasError('validatePhone') ? this.invalidMessage : '';
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AVLocalStorageService } from '@service/av-localstorage.service';
import { shortcutsConfig } from '@config/atalhos.config';
import { JwtService, USER_TYPE } from '@service/jwt.service';

export interface IShortcuts {
  icon: string;
  label: string;
  link?: string;
  datalayer?: string;
  color?: string;
  show?: boolean;
}

@Component({
  selector: 'inc-atalhos',
  templateUrl: './atalhos.html',
  styleUrls: ['./atalhos.scss']
})
export class ShortcutsInclude implements OnInit {
  @Input() sectionTitle: string;

  userType: USER_TYPE;

  isAgente: boolean = false;
  isImobiliaria: boolean = false;
  isLogged: boolean = false;

  shortcuts: IShortcuts[] = shortcutsConfig;
  services: any = {};

  constructor(private avLocalStorageService: AVLocalStorageService, private jwtService: JwtService) {}

  ngOnInit() {
    this.avLocalStorageService.getUnidadeServicos().then((services) => {
      this.isLogged = this.jwtService.isLoggedFromToken();

      this.userType = this.jwtService.getUserType();
      this.isAgente = this.userType === USER_TYPE.AGENTE;
      this.isImobiliaria = this.userType === USER_TYPE.IMOBILIARIA;

      this.services = services;

      const items = {
        '2-via': this.isLogged && this.services?.historicoContaConsumo,
        'debito-automatico': this.isLogged && this.services?.debitoAutomatico,
        'fatura-por-email': this.isLogged && this.services?.contasPorEmail && !this.isImobiliaria && !this.isAgente,
        'certidao-negativa': this.isLogged && this.services?.certidaoNegativa,

        'qualidade-agua': this.isLogged && this.services?.qualidadeAgua,
        'ligacao-esgoto': this.isLogged && this.services?.novaLigacaoEsgoto,
        'ligacao-agua': !this.isLogged && this.services?.novaLigacaoAgua,
        'trocar-titularidade': !this.isLogged && this.services?.trocaTitularidade,
        'pedido-corte': this.isLogged && this.services?.cortePedido,
        'mudanca-local': this.isLogged && this.services?.mudancaLocal,
        religacao: this.isLogged && (this.services?.religacao || this.services?.novaReligacao),
        'troca-registro': this.isLogged && this.services?.trocaHidrometro,
        'conserto-cavalete': this.isLogged && this.services?.consertoCavalete,
        'tarifa-social': this.isLogged && this.services?.tarifaSocial,
        'encerrar-contrato': this.isLogged && this.services?.encerrarContrato,
        'falta-agua': this.isLogged && this.services?.faltaAgua,
        'alterar-cadastro': this.isLogged && this.services?.alterarCadastro,
        denuncia: this.services?.denuncia,
        'esgoto-entupido': this.services?.esgotoEntupido,
        'esgoto': this.services?.servicoEsgoto,

        vazamento: this.services?.vazamentoAgua,
        'agendamento-presencial': this.services?.agendamentoPresencial,
        'ouvidoria': this.services?.ouvidoria
      };

      this.shortcuts.map((d) => {
        for (let item in items) {
          if (d.link?.indexOf(item) >= 0 || d.datalayer?.indexOf(item) >= 0) {
            return (d.show = items[item]);
          }
        }
      });

      this.shortcuts
        .filter((d) => d.show)
        .map((d, i) => {
          if (i % 2 === 0) {
            d.color = 'primary';
          } else {
            d.color = 'secondary';
          }
        });
    });
  }

  get quantidade(): number {
    return this.shortcuts.filter((d) => d.show).length;
  }
}

import { audienciaConfig } from '@config/audiencia.config';

export interface PersonResponse {
  numDoc?: string;
  email?: string;
  nome?: string;
  endereco?: string;
  documentoCpfOuCnpj?: string;
  statusRecebNoticia?: string;
  listaTelefone?: TelephoneResponse[];
}

export interface TelephoneResponse {
  ddd?: string;
  numero?: string;
  tipo?: string;
}

export interface UserResponse {
  pessoa?: PersonResponse;
  nome?: string;
  login?: string;
  senha?: string;
  unidade?: string;
  cpfOuCnpj?: string;
  numLigacao?: string;
  numDigV?: string;
  audience?: string;
  statusLigacao?: string;
  statusRecebNoticia?: string;
  hasPassword?: boolean;
  hasFacebook?: boolean;
  hasApple?: boolean;
  hasGoogle?: boolean;
}

export interface PersonRequest {
  email?: string;
  nome?: string;
  endereco?: string;
  documentoCpfOuCnpj?: string;
  listaTelefone?: TelephoneRequest[];
}

export interface TelephoneRequest {
  ddd?: string;
  numero?: string;
}

interface LoginImobiliariaRequestOld {
  login?: string;
  senha?: string;
}

export interface LoginImobiliariaRequest extends LoginImobiliariaRequestOld {
  audience: 'IMOBILIARIA_WEB' | 'IMOBILIARIA_APP';
  tipoAcesso?: 'NORMAL';
  unidade: string;
  codAcesso?: string;
  senhaAcesso?: string;
}

export interface AgenteNovoRequest {
  audience: 'AGENTE_WEB' | 'AGENTE_APP';
  tipoAcesso: 'NORMAL';
  unidade: string;
  codAcesso: string;
  senhaAcesso: string;
}

export interface UserRequest {
  nome?: string;
  login?: string;
  senha?: string;
  pessoa?: PersonRequest;
  unidade?: string;
  cpfOuCnpj?: string;
  numLigacao?: string;
  numDigV?: string;
  audience?: string;
  statusRecebNoticia?: string;
}

export interface UserRequestSignUp {
  documento?: string;
  email?: string;
  matricula?: string;
  nome?: string;
  receberNoticias?: string;
  telefoneCelular?: string;
  telefoneComercial?: string;
  telefoneFixo?: string;
  tipoDocumento?: string;
  audience?: string;
  unidade?: string;
}

export interface UserViewModel {
  name?: string;
  profile?: string;
  login?: string;
  password?: string;
  email?: string;
  address?: string;
  telephone?: string;
  cpfOrCnpj?: string;
  installation?: string;
  cellphone?: string;
  commercialPhone?: string;
  status?: string;
  receiveNotices?: boolean;
  pessoa?: PersonRequest;
}

export interface LembrarAcessoCredencial {
  unit: string;
  id: string;
  password: string;
  name?: string;
  iconURL?: string;
  rememberAccess: boolean;
}

export interface LembrarAgenteCredencial {
  unit: string;
  agente: string;
  password: string;
  rememberAccess: boolean;
}

export interface LembrarImobiliariaCredencial {
  unit: string;
  imobiliaria: string;
  password: string;
  rememberAccess: boolean;
}

export class PersonResponseMaker {
  static create(person: PersonResponse) {
    return {
      email: person.email,
      documentoCpfOuCnpj: person.numDoc,
      listaTelefone: person.listaTelefone,
      endereco: person.endereco,
      nome: person.nome,
      statusRecebNoticia: person.statusRecebNoticia
    };
  }
}

export class UserResponseMaker {
  static create(user: UserResponse) {
    return {
      pessoa: PersonResponseMaker.create(user.pessoa),
      cpfOuCnpj: user.cpfOuCnpj,
      numDigV: user.numDigV,
      numLigacao: user.numLigacao,
      statusLigacao: user.statusLigacao,
      hasFacebook: user.hasFacebook,
      hasGoogle: user.hasGoogle,
      hasApple: user.hasApple,
      hasPassword: user.hasPassword
    };
  }
}

export class PersonRequestMaker {
  static create(person: PersonRequest) {
    return {
      email: person.email,
      documentoCpfOuCnpj: person.documentoCpfOuCnpj,
      listaTelefone: person.listaTelefone
    };
  }
}

export class UserRequestMaker {
  static create(user: UserRequest) {
    return {
      nome: user.nome,
      login: user.login,
      senha: user.senha,
      pessoa: user.pessoa,
      unidade: user.unidade,
      cpfOuCnpj: user.cpfOuCnpj,
      statusRecebNoticia: user.statusRecebNoticia,
      audience: user.audience ? user.audience : audienciaConfig.autoatendimento.web,
      numLigacao: user.numLigacao
    };
  }
}

export class UserRequestSignUpMaker {
  static create(user: UserRequestSignUp) {
    return {
      nome: user.nome,
      documento: user.documento,
      email: user.email,
      matricula: user.matricula,
      unidade: user.unidade,
      receberNoticias: user.receberNoticias,
      telefoneCelular: user.telefoneCelular,
      telefoneComercial: user.telefoneComercial,
      telefoneFixo: user.telefoneFixo,
      tipoDocuemto: user.tipoDocumento,
      audience: user.audience ? user.audience : audienciaConfig.autoatendimento.web
    };
  }
}

export class UserViewModelMaker {
  static create(user: UserResponse) {
    const homeTelephone = user.pessoa.listaTelefone.filter((tel) => tel.tipo === 'RESIDENCIAL');
    const cellphone = user.pessoa.listaTelefone.filter((tel) => tel.tipo === 'CELULAR');
    const commercialPhone = user.pessoa.listaTelefone.filter((tel) => tel.tipo === 'COMERCIAL');
    return {
      cpfOrCnpj: user.pessoa.documentoCpfOuCnpj,
      email: user.pessoa.email,
      name: user.pessoa.nome,
      address: user.pessoa.endereco,
      pessoa: user.pessoa,

      telephone: homeTelephone
        ? homeTelephone.length > 0
          ? (homeTelephone[0].ddd ? homeTelephone[0].ddd.toString() : '') +
            (homeTelephone[0].numero ? homeTelephone[0].numero.toString() : '')
          : ''
        : '',

      cellphone: cellphone
        ? cellphone.length > 0
          ? (cellphone[0].ddd ? cellphone[0].ddd.toString() : '') +
            (cellphone[0].numero ? cellphone[0].numero.toString() : '')
          : ''
        : '',

      commercialPhone: commercialPhone
        ? commercialPhone.length > 0
          ? (commercialPhone[0].ddd ? commercialPhone[0].ddd.toString() : '') +
            (commercialPhone[0].numero ? commercialPhone[0].numero.toString() : '')
          : ''
        : '',

      receiveNotices: user.pessoa.statusRecebNoticia === 'SIM' ? true : false,

      installation: user.numDigV
        ? user.numLigacao + '-' + user.numDigV
        : user.numLigacao.substring(0, user.numLigacao.length - 1) +
          '-' +
          user.numLigacao.substring(user.numLigacao.length - 1),

      status: user.statusLigacao
        ? user.statusLigacao === 'A'
          ? 'Ativo'
          : user.statusLigacao === 'C'
          ? 'Cortado'
          : user.statusLigacao
        : null
    };
  }
}

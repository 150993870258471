import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, EMPTY } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '@service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '@service/loader.service';
import { GtmService } from '@service/gtm.service';
import { DataBusTypeSafeService, ICONS_FEEDBACK, COLORS_FEEDBACK, IDataBusFeedbackPage } from '@service/databus.service';
import { NavigatorService } from '@service/navigator.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private navigatorService: NavigatorService,
    private feedbackPage: DataBusTypeSafeService<IDataBusFeedbackPage>,
    private dataLayer: GtmService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse) => {
        const timer = this.dataLayer.timer('error', false);
        this.loaderService.setServiceLoader(false);

        console.groupCollapsed('%c 🚨 ERRO NA REQUISIÇÃO', 'color: #ff7675');
        console.error(
          `${errorResponse.status} ${errorResponse.url} ${timer}ms`,
          '\n',
          `${errorResponse.headers.get('Error')}`
        );
        console.groupEnd();

        this.dataLayer.set('Erro na requisição', {
          error_status: errorResponse.status,
          error_name: errorResponse.name,
          error_url: errorResponse.url,
          error_line: '0',
          error_column: '0',
          error_message: errorResponse.headers.get('Error'),
          error_timer: timer
        });

        if (errorResponse.status === 412) {
          return throwError(errorResponse);
        }

        if (errorResponse.headers) {
          const errorMessage = errorResponse.headers.get('Error');

          if (errorMessage) {
            const showLoader = errorResponse.headers.get('Error-Hide-Toast') != 'True';

            if (showLoader) {
              this.toasterService.error(errorMessage);
            }

            return throwError(errorResponse);
          } else {
            const errorMessageDescription = errorResponse.headers.get('Error-Description');

            if (errorMessageDescription) {
              const showLoader = errorResponse.headers.get('Error-Hide-Toast') != 'True';

              if (showLoader) {
                this.toasterService.error(errorMessageDescription);
              }

              return throwError(errorResponse);
            }
          }
        }

        if (errorResponse.status === 401 || errorResponse.status === 403) {
          if (typeof window !== undefined) {
            this.authService.signOut();
            return throwError(errorResponse);
          }
        }

        if (!!errorResponse.error && !!errorResponse.error.message) {
          if (!errorResponse.error.hideToast) {
            this.toasterService.error(errorResponse.error.message);
          }
          return throwError(errorResponse);
        }

        if (errorResponse.body?.exception === 'Para continuar é necessário atualizar o aplicativo.') {
          this.navigatorService.goTo('outdated');
        }

        if (!errorResponse.status) {
          this.feedbackPage.sendMessage({
            title: 'Serviço indisponível',
            message: 'Estamos passando por instabilidades, tente novamente em alguns instantes',
            buttonLink: '/',
            buttonLabel: 'Tentar novamente',
            icon: ICONS_FEEDBACK.CONSERTO_DANGER,
            color: COLORS_FEEDBACK.DANGER
          });

          this.navigatorService.goTo('feedback');
        }

        return EMPTY;
      })
    );
  }
}

import { envConfig } from '@config/environment.config';
import { gtmConfig } from '@config/gtm.config';
import { recaptchaConfig } from '@config/recaptcha.config';

let isProduction: boolean = false;
let baseUrl: string = envConfig.production.api;

const gtmId: string = gtmConfig.id;
let gtmAuth: string = gtmConfig.production.auth;
let gtmPreview: string = gtmConfig.production.preview;

if (!(location as Location).hostname || (location as Location).protocol === 'ionic:') {
  baseUrl = envConfig.mobile.api;
} else {
  if ((location as any).hostname === envConfig.production.hostname) {
    isProduction = true;
    baseUrl = envConfig.production.api;
  }

  if ('hml' === envConfig.homologation.hostname) {
    baseUrl = envConfig.homologation.api;
    gtmAuth = gtmConfig.homologation.auth;
    gtmPreview = gtmConfig.homologation.preview;
  }

  if ('dev' === envConfig.development.hostname) {
    baseUrl = envConfig.development.api;
    gtmAuth = gtmConfig.development.auth;
    gtmPreview = gtmConfig.development.preview;
  }

  if ((location as any).hostname === envConfig.localhost.hostname) {
    baseUrl = envConfig.localhost.api;
    gtmAuth = gtmConfig.localhost.auth;
    gtmPreview = gtmConfig.localhost.preview;
  }
}

export const environment = {
  production: isProduction,
  baseUrl,
  gtmId,
  gtmAuth,
  gtmPreview,
  recaptchaSettings: recaptchaConfig,
};

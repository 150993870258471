<div
  #elem
  [class.primary]="color === 'primary'"
  [class.secondary]="color === 'secondary'"
  [class.success]="color === 'success'"
  [class.danger]="color === 'danger'"
  [class.warning]="color === 'warning'"
  [class.info]="color === 'info'"
  [class.disabled]="disabled"
  [class.show]="show"
>
  <a
    *ngIf="link && isExternal"
    [attr.data-layer]="datalayer"
    [class.active]="active"
    [href]="link"
    target="_blank"
    [style]="'height: ' + height + ';'"
  >
    <ui-icon [icon]="icon" size="45px"></ui-icon>
    <h3>{{ label }}</h3>
  </a>

  <a
    *ngIf="link && !isExternal"
    [attr.data-layer]="datalayer"
    [class.active]="active"
    [routerLink]="link"
    [style]="'height: ' + height + ';'"
  >
    <ui-icon [icon]="icon" size="45px"></ui-icon>
    <h3>{{ label }}</h3>
  </a>

  <a
    *ngIf="!link"
    [attr.data-layer]="datalayer"
    [class.active]="active"
    (click)="clicked()"
    [style]="'height: ' + height + ';'"
  >
    <ui-icon [icon]="icon" size="45px"></ui-icon>
    <h3>{{ label }}</h3>
  </a>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { UserResponse, UserResponseMaker } from '@model/usuario.resolver';
import { HomeResponseMaker, HomeResponse, IGuiaGerada } from '@model/home.resolver';
import { HomeGerarGuiaRequest } from '@model/home.resolver';
import { Store } from '@ngrx/store';
import { AegeaStore } from 'store/aegea.store';
import { MatriculasAegeaStore } from 'store/matriculas/matriculas.reducer';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private dadosReferenciais: MatriculasAegeaStore;
  constructor(
    store: Store<AegeaStore>,
    private httpService: HttpService,
  ) {
    store.select('matriculas').subscribe((result) => {
      this.dadosReferenciais = result;
    })
  }

  gerarGuiaPagamento(input: HomeGerarGuiaRequest): IGuiaGerada {
    return this.httpService
      .sendPostRequest(apiConfig.home.geraGuia, input)
      .toPromise()
      .then((r) => <IGuiaGerada>r.body);
  }

  getUser(successCallbackActionGetUser: any): UserResponse {
    let installation = this.dadosReferenciais.matricula_selecionada;
    if (installation) {
      return this.httpService
        .sendGetRequest(apiConfig.usuario.atual(installation))
        .pipe(
          map((response) => {
            const responseBody = (<any>response).body;
            const userResponse = responseBody;
            return UserResponseMaker.create(userResponse);
          })
        )
        .subscribe((succeedResponse) => successCallbackActionGetUser(succeedResponse));
    }
  }

  getUserHomeInformation(successCallbackAction): HomeResponse {
    let installation = this.dadosReferenciais.matricula_selecionada;
    if (installation) {
      return this.httpService
        .sendGetRequest(apiConfig.home.matricula(installation))
        .pipe(
          map((response) => {
            const responseBody = (<any>response).body;
            const homeResponse = <HomeResponse>responseBody;
            return HomeResponseMaker.create(homeResponse);
          })
        )
        .subscribe((succeedResponse) => successCallbackAction(succeedResponse));
    }
  }
}

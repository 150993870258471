import { Injectable, OnInit } from '@angular/core';
import { Storage as CapacitorStorage } from '@capacitor/storage';
import * as CryptoTS from 'crypto-ts';
import { keyConfig } from '../config/keys.config';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage;
  private uniqueKey = keyConfig.uniqueKey;
  public _cacheLocalStorage: {};

  constructor() {
    this.storage = window.localStorage;
  }

  getInstalation() {
    const instalation = this.get('installation');
    return instalation;
  }

  get(key: string) {
    if (typeof window !== undefined) {
      const encriptedJsonObject = this._cacheLocalStorage[this.uniqueKey]; // window.localStorage.getItem(this.uniqueKey);
      if (encriptedJsonObject) {
        const decriptedObject = CryptoTS.AES.decrypt(encriptedJsonObject, keyConfig.U2FsdGVkX19S).toString(
          CryptoTS.enc.Utf8
        );
        const decriptedJsonObject = JSON.parse(decriptedObject);
        return decriptedJsonObject[key];
      }
    }
  }

  set(key: string, value: string) {
    if (typeof window !== undefined) {
      let encriptedJsonObject = this._cacheLocalStorage[this.uniqueKey]; //window.localStorage.getItem(this.uniqueKey);
      if (!encriptedJsonObject) {
        const newJson = {};
        newJson[key] = value;
        encriptedJsonObject = CryptoTS.AES.encrypt(JSON.stringify(newJson), keyConfig.U2FsdGVkX19S).toString();
      }
      if (encriptedJsonObject) {
        this.remove(key);
        const decriptedObject = CryptoTS.AES.decrypt(encriptedJsonObject, keyConfig.U2FsdGVkX19S).toString(
          CryptoTS.enc.Utf8
        );
        const decriptedJsonObject = JSON.parse(decriptedObject);
        decriptedJsonObject[key] = value;
        const newEncriptedJsonObject = CryptoTS.AES.encrypt(
          JSON.stringify(decriptedJsonObject),
          keyConfig.U2FsdGVkX19S
        ).toString();
        this._cacheLocalStorage[this.uniqueKey] = newEncriptedJsonObject;
        CapacitorStorage.set({ key: this.uniqueKey, value: newEncriptedJsonObject });
      }
    }
  }

  remove(key: string) {
    if (typeof window !== undefined) {
      const encriptedJsonObject = this._cacheLocalStorage[this.uniqueKey]; // window.localStorage.getItem(this.uniqueKey);
      if (encriptedJsonObject) {
        const decriptedObject = CryptoTS.AES.decrypt(encriptedJsonObject, keyConfig.U2FsdGVkX19S).toString(
          CryptoTS.enc.Utf8
        );
        const decriptedJsonObject = JSON.parse(decriptedObject);
        delete decriptedJsonObject[key];
        const newEncriptedJsonObject = CryptoTS.AES.encrypt(
          JSON.stringify(decriptedJsonObject),
          keyConfig.U2FsdGVkX19S
        ).toString();
        this._cacheLocalStorage[this.uniqueKey] = newEncriptedJsonObject;
        CapacitorStorage.set({ key: this.uniqueKey, value: newEncriptedJsonObject });
      }
    }
  }

  removeAll() {
    if (typeof window !== undefined) {
      const currentUnit = this.get('unit');
      delete this._cacheLocalStorage[this.uniqueKey];
      CapacitorStorage.remove({ key: this.uniqueKey });
      this.set('unit', currentUnit);
    }
  }

  setItem(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  getItem(key: string): any {
    if (this.storage) {
      const item = this.storage.getItem(key);
      return item !== 'undefined' ? JSON.parse(item) : undefined;
    }
    return null;
  }

  removeItem(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  clear(): boolean {
    if (this.storage) {
      this.storage.clear();
      return true;
    }
    return false;
  }
}

export function initializerProvider(provider: LocalStorageService) {
  return () => {
    return CapacitorStorage.keys().then((result) => {
      const listOfPromises = result.keys.map((d) => {
        return new Promise((resolve, refuse) => {
          CapacitorStorage.get({ key: d }).then((data) => {
            resolve({ [d]: data.value });
          });
        });
      });
      return Promise.all(listOfPromises).then((d) => {
        const reduced = d.reduce((a: any, b: any) => {
          return { ...a, ...b };
        }, {});
        provider._cacheLocalStorage = reduced;
      });
    });
  };
}

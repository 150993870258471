import { Component, ViewChild, Inject } from '@angular/core';
import { ModalLayout } from '@layout/modal/modal.layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IListaAnexos } from '@service/upload.service';

@Component({
  selector: 'modal-documento-duvida',
  templateUrl: './documento-duvida.html',
  styleUrls: ['./documento-duvida.scss']
})
export class DocumentoDuvidaModal {
  @ViewChild('specificContent', { static: true }) documentoDuvida: ModalLayout;

  documento: IListaAnexos;

  constructor(@Inject(MAT_DIALOG_DATA) data: { item }, private dialogRef: MatDialogRef<DocumentoDuvidaModal>) {
    this.documento = data.item;
  }

  close() {
    setTimeout(() => { this.dialogRef.close(); }, 0);
  }
}

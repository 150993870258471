import { Injectable } from '@angular/core';

import { apiConfig } from '@config/api.config';
import { HttpService } from '@service/http.service';
import { LocalStorageService } from '@service/localstorage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService
  ) { }

  getPossibleAddresses(payload: any, successCallbackAction, errorCallbackAction) {
    payload.unit = this.localStorageService.get('unit');
    if (payload && payload.unit && payload.address) {
      this.httpService.sendGetRequest(apiConfig.imovel.endereco(payload.unit, payload.address)).subscribe(
        (succeedResponse) => successCallbackAction(succeedResponse.body),
        (errorResponse) => errorCallbackAction(errorResponse)
      );
    }
  }

  pesquisarLogradouro(unidade: string, logradouro: string): Observable<any> {
    return this.httpService.sendGetRequest(apiConfig.imovel.pesquisar(unidade, encodeURIComponent(logradouro)));
  }

}

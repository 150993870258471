import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { AegeaStore } from 'store/aegea.store';
import * as MatriculasActions from './matriculas.actions';
import { set } from 'lodash';

export interface MatriculasAegeaStore {
  matricula_selecionada: string | undefined;
  unidade_referencia: string | undefined;
}
const initialState =   {
  matricula_selecionada: undefined,
  unidade_referencia: undefined
};
const _baseReducer = createReducer<MatriculasAegeaStore>(
  initialState,
  on(MatriculasActions.selecionaMatricula, (state, payload) => {
    return {
      ...state,
      matricula_selecionada: payload.matricula_selecionada,
      unidade_referencia: payload.unidade_referencia
    };
  }),
  on(MatriculasActions.logoutAction, (state, payload) => {
    return {
      ...initialState
    };
  }),
);


function isHydrateSuccess(
  action: Action
): action is ReturnType<typeof MatriculasActions.hydrateSuccess> {
  return action.type === MatriculasActions.hydrateSuccess.type;
}

export const matriculaMetaReducer = (
  reducer: ActionReducer<AegeaStore>
): ActionReducer<AegeaStore> => {
  return (state, action) => {
    if (isHydrateSuccess(action)) {
      return {...state, ...action.state}
    } else {
      return reducer(state, action);
    }
  };
}

export function matriculasReducer(state: MatriculasAegeaStore | undefined, action: Action) {
  return _baseReducer(state, action);
}

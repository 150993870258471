import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Optional,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { CardNumberValidator } from '@validator/card-number.validator';

@Component({
  selector: 'ui-input-card-number',
  templateUrl: './input-card-number.component.html',
  styleUrls: ['./input-card-number.component.scss']
})
export class InputCardNumberFormComponent implements OnInit {
  private validators = [];

  @ViewChild('elem') elem: ElementRef;

  @Input() control: FormControl;
  @Input() datalayer: string = '';
  @Input() label: string = 'Número do cartão';
  @Input() name: string = '';
  @Input() tip: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = '0000 0000 0000 0000';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidMessage: string = 'Número do cartão inválido';
  @Input() autoFocus: boolean = false;

  @Optional()
  @Output()
  onNewValue = new EventEmitter<string>();
  @Output() onBlur = new EventEmitter();

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.elem) {
      this.elem.nativeElement.focus();
    }

    this.cdref.detectChanges();
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }

    this.validators.push(Validators.minLength(19));
    this.validators.push(Validators.maxLength(19));
    this.validators.push(CardNumberValidator);

    this.control.setValidators(this.validators);

    this.control.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => this.onNewValue.emit(newValue));
  }

  BlockEnterSubmit(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  Blur() {
    this.onBlur.emit();
  }

  get errorMessage() {
    if (this.control.hasError('required')) {
      return 'Informe o número do cartão';
    }

    if (this.control.hasError('minlength') || this.control.hasError('maxlength')) {
      return this.invalidMessage;
    }
  }
}

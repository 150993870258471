<mat-accordion class="lista-anexos">
  <ng-container *ngFor="let item of listaAnexos; let i = index">
    <mat-expansion-panel
      [id]="'item-anexo-'+i"
      [class.anexo-valido]="item.done"
      [class.anexo-erro]="item.error"
      [class.anexo-optional]="!item.required && !item.done && !item.error"
      [class.anexo-invalido]="item.required && !item.done && !item.error"
      [expanded]="step === i"
      (opened)="setStep(i)"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title> {{ item.title }} </mat-panel-title>

        <mat-panel-description>
          {{ item.description }}
          <ui-icon *ngIf="item.done" size="32px" icon="check_circle_outline"></ui-icon>
          <ui-icon *ngIf="item.error" size="32px" icon="error_outline"></ui-icon>
          <ui-icon
            *ngIf="!item.required && !item.done && !item.error"
            size="32px"
            icon="remove_circle_outline"
          ></ui-icon>
          <ui-icon *ngIf="item.required && !item.done && !item.error" size="32px" icon="help_outline"></ui-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div *ngIf="!item.done" class="row row-box-button">
        <div class="col-sm-12">
          <dl>
            <dt>Dicas:</dt>
            <dd>
              <a href="javascript:void(0);" (click)="duvidas(i)">Como tirar uma boa foto?</a>
            </dd>
          </dl>

          <input class="hide" type="file" accept="image/jpg, image/jpeg, image/png"  (change)="imagem($event, i)" #file />

          <button
            class="btn btn-md"
            [class.btn-outline-danger]="item.error"
            [class.btn-outline-primary]="!item.error"
            (click)="file.click()"
          >
            <ui-icon [icon]="platformInfo.isDesktop ? 'cloud_upload' : 'filter'"></ui-icon>
            <strong>{{ platformInfo.isDesktop ? 'Enviar foto' : 'Galeria' }}</strong>
          </button>

          <button
            *ngIf="cameras.indexOf(true) >= 0"
            class="btn btn-md"
            [class.btn-danger]="item.error"
            [class.btn-primary]="!item.error"
            (click)="foto(i)"
            [attr.data-layer]="slugify(dataLayer + '-' + item.filename)"
          >
            <ui-icon [icon]="platformInfo.isDesktop ? 'camera_alt' : 'camera'"></ui-icon>
            <strong>{{ platformInfo.isDesktop ? 'Abrir webcam' : 'Câmera' }}</strong>
          </button>
        </div>
      </div>

      <ng-container *ngIf="item.source && item.done && item.filetype">
        <div class="row row-title">
          <div class="col-sm-12">
            <p class="text-center">
              <img *ngIf="typeImage(item.filetype)" [src]="item.source" />
              <iframe *ngIf="typePdf(item.filetype)" [src]="item.source"></iframe>
            </p>
          </div>
        </div>

        <div class="row row-button">
          <div class="col-sm-12">
            <button
              matTooltip="Trocar imagem"
              matTooltipPosition="above"
              [attr.data-layer]="dataLayer + '-upload-reset'"
              class="btn btn-reset btn-danger"
              (click)="resetFoto(i)"
            >
              <ui-icon icon="close"></ui-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>

import { Directive, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: 'input[replaceSpecialChars]',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '(input)': 'onInputChange($event)'
  }
})
export class ReplaceSpecialCharsDirective {
  constructor(private element: ElementRef) {}

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.element.nativeElement.value;

    this.element.nativeElement.value = initialValue
      .replace(/[àáäâã]/g, 'a')
      .replace(/[ÀÁÄÂÃ]/g, 'A')
      .replace(/[èéëê]/g, 'e')
      .replace(/[ÈÉËÊ]/g, 'E')
      .replace(/[ìíïî]/g, 'i')
      .replace(/[ÌÍÏÎ]/g, 'I')
      .replace(/[òóöõô]/g, 'o')
      .replace(/[ÒÓÖÕÔ]/g, 'O')
      .replace(/[ùúüû]/g, 'u')
      .replace(/[ÙÚÜÛ]/g, 'U')
      .replace(/[ç]/g, 'c')
      .replace(/[Ç]/g, 'C')
      .replace(/[ñ]/g, 'n')
      .replace(/[Ñ]/g, 'N')
      .replace(/[^A-Za-z0-9\s]+/, '');

    if (initialValue !== this.element.nativeElement.value) {
      event.stopPropagation();
    }

    this.ngModelChange.emit(this.element.nativeElement.value);
  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  sendGetRequest(apiUrl: string, headers?: HttpHeaders, parameters?: HttpParams): any {
    return this.http.get<any>(this.baseUrl + apiUrl, { headers: headers, params: parameters, observe: 'response' });
  }

  sendGetRequestWithOptions(apiUrl: string, options: any) {
    return this.http.get(this.baseUrl + apiUrl, options);
  }

  sendGetRequestArrayBuffer(apiUrl: string) {
    return this.http.get(this.baseUrl + apiUrl, { responseType: 'arraybuffer' });
  }

  sendPostRequest(apiUrl: string, payload: any, headers?: HttpHeaders, parameters?: HttpParams): any {
    return this.http.post(this.baseUrl + apiUrl, payload, {
      headers: headers,
      params: parameters,
      observe: 'response'
    });
  }

  sendPostRequestArrayBuffer(apiUrl: string, payload: any): any {
    return this.http.post(this.baseUrl + apiUrl, payload, { responseType: 'arraybuffer' });
  }

  sendUpdateRequest(apiUrl: string, payload: any, headers?: HttpHeaders, parameters?: HttpParams): any {
    return this.http.put(this.baseUrl + apiUrl, payload, { headers: headers, params: parameters, observe: 'response' });
  }

  sendDeleteRequest(apiUrl: string, headers?: HttpHeaders, parameters?: HttpParams): any {
    return this.http.delete<any>(this.baseUrl + apiUrl, { headers: headers, params: parameters, observe: 'response' });
  }

  private genericErrorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServiceGuard } from '@guard/service.guard';
import { AccessGuard } from '@guard/access.guard';
import { FeedbackComponent } from '@feedback-route/feedback.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/atendimento/entrar', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('@unidade-route/unidade.module').then((m) => m.UnidadeModule)
  },
  {
    path: '',
    loadChildren: () => import('@agente-route/agente.module').then((m) => m.AgenteModule)
  },
  {
    path: '',
    loadChildren: () => import('@imobiliaria-route/imobiliaria.module').then((m) => m.ImobiliariaModule)
  },
  {
    path: '',
    loadChildren: () => import('@atendimento-route/atendimento.module').then((m) => m.AtendimentoModule)
  },
  {
    path: '',
    loadChildren: () => import('@login-route/login.module').then((m) => m.LoginModule)
  },
  {
    path: '',
    loadChildren: () => import('@logout-route/logout.module').then((m) => m.LogoutModule)
  },
  {
    path: '',
    loadChildren: () => import('@ordem-servico-route/ordem-servico.module').then((m) => m.OrdemServicoModule)
  },
  {
    canActivate: [AccessGuard],
    path: '',
    loadChildren: () => import('@home-route/home.module').then((m) => m.HomeModule)
  },
  {
    canActivate: [ServiceGuard, AccessGuard],
    path: '',
    loadChildren: () => import('@pagamento-route/pagamento.module').then((m) => m.PagamentoModule)
  },
  {
    canActivate: [ServiceGuard, AccessGuard],
    path: '',
    loadChildren: () => import('@parcelamento-route/parcelamento.module').then((m) => m.ParcelamentoModule)
  },
  {
    canActivate: [ServiceGuard],
    path: '',
    loadChildren: () => import('@fatura-route/fatura.module').then((m) => m.FaturaModule)
  },
  {
    canActivate: [ServiceGuard],
    path: '',
    loadChildren: () => import('@servico-route/servico.module').then((m) => m.ServicoModule)
  },
  {
    canActivate: [ServiceGuard],
    path: '',
    loadChildren: () => import('@agencia-route/agencia.module').then((m) => m.AgenciaModule)
  },
  {
    canActivate: [ServiceGuard, AccessGuard],
    path: '',
    loadChildren: () => import('@perfil-route/perfil.module').then((m) => m.PerfilModule)
  },
  {
    path: '',
    loadChildren: () => import('@feedback-route/feedback.module').then((m) => m.FeedbackModule)
  },
  {
    path: '',
    loadChildren: () => import('@outdated-route/outdated.module').then((m) => m.OutdatedModule)
  },
  {
    path: '',
    loadChildren: () => import('@token-route/token.module').then((m) => m.TokenModule)
  },
  { path: '**', data: { error: 404 }, component: FeedbackComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

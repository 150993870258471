<layout-modal #specificContent>
  <div class="modal-body">
    <div class="row row-title">
      <div class="col-sm-12">
        <h2 class="title">Aceitar a política</h2>
        <button (click)="close()" class="close">
          <ui-icon icon="close"></ui-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          Prezado(a) cliente, para saber como a Aegea realiza o tratamento de seus dados pessoais, acesse a nossa
          <a href="https://www.aegea.com.br/politica-de-privacidade-e-protecao-de-dados-da-aegea/" target="_blank">
            Política de Privacidade e Proteção de Dados Pessoais</a
          >.
        </p>
        <p>
          Esteja ciente de que, poderemos solicitar seus dados pessoais e que o tratamento desses dados se dará de
          acordo com a Política de Privacidade da Aegea e das políticas de privacidade do WhatsApp.
        </p>
      </div>
    </div>

    <div class="row row-button">
      <div class="col-sm-12">
        <button data-layer="cadastro-criar-senha" class="btn btn-success" (click)="confirmar()">Aceitar</button>
      </div>
    </div>
  </div>
</layout-modal>

<ng-container *ngIf="quantidade > 0">
  <div *ngIf="sectionTitle" class="row row-title">
    <div class="col-sm-12">
      <h2>{{ sectionTitle }}</h2>
    </div>
  </div>

  <div class="row row-shortcut-servicos">
    <ng-container *ngFor="let item of shortcuts">
      <div
        [class.show]="item.show"
        [class.col-sm-12]="quantidade >= 4"
        [class.col-md-6]="quantidade >= 4"
        [class.col-lg-4]="quantidade >= 4"
        [class.col-xl-3]="quantidade >= 4"
        [class.col-sm-6]="quantidade === 3"
        [class.col-md-4]="quantidade === 3"
        [class.col-sm-6]="quantidade === 2"
        [class.col-sm-12]="quantidade === 1"
      >
        <ui-shortcut
          [datalayer]="item.datalayer"
          [icon]="item.icon"
          [label]="item.label"
          [color]="item.color"
          [show]="item.show"
          [link]="item.link"
        ></ui-shortcut>
      </div>
    </ng-container>
  </div>
</ng-container>

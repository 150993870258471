import { Injectable } from '@angular/core';

import { HttpService } from '@service/http.service';
import { apiConfig } from '@config/api.config';
import { LocalStorageService } from '@service/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class EsqueciSenhaModalService {
  private unit = this.localStorageService.get('unit') ? this.localStorageService.get('unit').trim() : '';

  constructor(private httpService: HttpService, private localStorageService: LocalStorageService) {}

  sendPasswordByEmail(payload: any, successCallback: any) {
    return this.httpService
      .sendPostRequest(apiConfig.usuario.reenviaSenha(this.unit, payload.login), { email: payload.email })
      .subscribe((succeedResponse) => successCallback(succeedResponse));
  }

  sendPasswordForAgent(payload: any, successCallback: any) {
    return this.httpService
      .sendGetRequest(apiConfig.agente.reenviaSenha(this.unit, payload.login))
      .subscribe((succeedResponse) => successCallback(succeedResponse));
  }
}
